<template>
  <div class="login">
    <ValidationObserver ref="form" v-slot="{ valid, pending, errors }" tag="form" @submit.prevent="submitForm">
      <p class="subtitle">Sign in</p>

      <app-input v-model="form.email" rules="email|required" name="Email" type="text">Email</app-input>
      <app-input v-model="form.password" rules="min:6|required" name="Password" type="password">Password</app-input>
      <div class="error form-error">{{ errorMessage }}</div>

      <button v-if="!loading" class="btn-pink" type="submit">Log In</button>
    </ValidationObserver>

    <button v-if="loading" class="btn-white">
      <app-loader />
    </button>

    <div class="hint" @click="forgotPassword">
      <p>Forgot password?</p>
    </div>
  </div>
</template>

<script lang="ts">
import { FirebaseError } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { AuthService } from './auth-service';

@Component({})
export default class Login extends Vue {
  public $refs!: {
    form: HTMLFormElement;
  };
  public $router: any;
  public $route: any;
  public form: any = {
    email: '',
    password: '',
  };
  public errorMessage = '';
  public loading = false;

  private auth = getAuth();

  public forgotPassword() {
    ModalHub.$emit('open', 'modal-password-request');
  }

  public async submitForm() {
    if (!(await this.$refs.form.validate())) return;

    const { email, password } = this.form;
    this.errorMessage = '';
    this.loading = true;

    try {
      // Try to sign in:
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);

      // Save user data in local storage for current and further sessions:
      this.$store.commit('user', { firstName: userCredential.user.displayName, email: userCredential.user.email });
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      if (err instanceof FirebaseError && err.code === 'auth/user-not-found') {
        try {
          // Try to migrate user:
          await http.post('/v2/accounts/migrate', { email, password });

          // Try to sign in once again:
          const userCredential = await signInWithEmailAndPassword(this.auth, email, password);

          // Save user data in local storage for current and further sessions:
          this.$store.commit('user', { firstName: userCredential.user.displayName, email: userCredential.user.email });
        } catch (err: any) {
          // eslint-disable-next-line
          console.log(err);
          if (err === 'Invalid credentials.') {
            this.errorMessage = 'Invalid credentials.';
          } else if (err.errorDetails) {
            this.errorMessage = err.errorDetails;
          } else {
            this.errorMessage = 'Something went wrong. Please try again later.';
          }
        }
      } else if (err instanceof FirebaseError && err.code === 'auth/wrong-password') {
        this.errorMessage = 'Invalid credentials.';
      } else {
        this.errorMessage = 'Something went wrong.';
      }
    }

    this.loading = false;
    if (this.errorMessage !== '') return;

    const isSuperAdmin = await AuthService.isSuperAdmin();
    this.$store.commit('isSuperAdmin', isSuperAdmin);
    this.$router.push('/vendors');
  }

  private async migrateUser(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (err) {
      console.error(err);
      this.$store.commit('yup/clear');
      this.$store.commit('yup/errors', { path: 'email', msg: 'Something went wrong.' });
      this.$store.commit('yup/toggleShowError', true);
    }

    this.$store.commit('modals/close');
  }
}

declare global {
  interface Window {
    fcWidget: any;
  }
}
</script>

<style lang="scss" scoped>
.login {
  max-width: 400px;
  margin: 60px auto;
}
.subtitle {
  width: 100px;
  margin: 0 auto;
}
.hint {
  line-height: 150%;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 40px;
  p {
    font-weight: 600;
    cursor: pointer;
  }
}
.recover-password {
  font-size: 14px;
  font-weight: 600;
  color: $pink;
  text-align: right;
  margin-bottom: 40px;
}
.btn-pink {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.btn-white {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: 0.5px solid black;
}
.btn-white:hover {
  background-color: $white;
}
.form-error {
  margin-bottom: 20px;
}
</style>
