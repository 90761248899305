<template>
  <div class="text-page">
    <div class="container">
      <h3 style="margin-bottom: 50px">TERMS AND CONDITIONS FOR THE SALE AND DELIVERY OF FOOD AND BEVERAGES</h3>
      <h3 style="margin-bottom: 25px">Start date: From your Foodstuff launch date</h3>
      <h3 style="margin: 0">Foodstufff Ltd (trading as Foodstuff)</h3>
      <p>2 Trust Court,</p>
      <p>Chiver Way,</p>
      <p>Histon,</p>
      <p>Cambridge,</p>
      <p>United Kingdom,</p>
      <p>CB24 9PW</p>
      <h3 style="margin-top: 20px; text-align: center">INTRODUCTION</h3>
      <p>
        These terms and conditions (the <strong>Terms</strong>) are issued by <strong>Foodstufff Ltd</strong>, a private
        company registered in England under company registration number 12083748 and whose registered address is at 2
        Trust Court, Chivers Way Trust Court, Histon, Cambridge, England, CB24 9PW (<strong>Foodstuff</strong>).
      </p>
      <p>
        <strong>You</strong> are the restaurant identified in the account (the <strong>Restaurant Account</strong>) that
        you create using Foodstuff's account administration software (the <strong>Admin Software</strong>).
      </p>
      <p>
        These Terms apply to your agreement with Foodstuff to engage Foodstuff as your agent to conclude sales of, and
        deliver, your restaurant products to customers as further described in these Terms.
      </p>
      <p>These Terms were last updated on 7th August 2023.</p>
      <p>
        Foodstuff reserves the right to amend these Terms at any time. Foodstuff will notify you in writing (whether by
        e-mail, through its management software or otherwise) of any changes to these Terms in advance of such changes
        taking effect.
        <strong
          >If you continue to use the Foodstuff services then you will be deemed to have accepted the revised
          Terms.</strong
        >
      </p>
      <p>In these Terms a <strong>Business Day</strong> is a day other than a Saturday, Sunday or a UK bank holiday.</p>
      <p>You should save a copy of these Terms for your records.</p>
      <p>
        In the event that you have agreed to participate in our Long Term Partner program then you will be asked to sign
        an addendum to these Terms, containing additional terms and outlining additional benefits that relate to that
        program (a <strong>Long Term Partner Addendum</strong>). In such case, a Long Term Partner Addendum will form an
        additional part of these Terms, and references to these Terms shall include any applicable Long Term Partner
        Addendum.
      </p>
      <h3 style="margin-top: 20px; text-align: center">TERMS</h3>
      <h3 style="margin-top: 20px">1. Application</h3>
      <p>
        1.1 These Terms cover the sale and delivery by Foodstuff (as agent, on your behalf) of food and beverages
        (<strong>Menu Items</strong>) supplied by you to users (<strong>Users</strong>) of the Foodstuff mobile
        application and website (the <strong>Platform</strong>).
      </p>
      <p>
        1.2 In the event of conflict between these Terms and any other terms and conditions, these Terms shall prevail
        unless expressly agreed otherwise in writing by Foodstuff.
      </p>
      <h3 style="margin-top: 20px">2. Services</h3>
      <p>
        2.1 Foodstuff is authorised to act as your agent to conclude sales of Menu Items on your behalf. Foodstuff shall
        collect Menu Items from you and deliver these to the User.
      </p>
      <p>2.2 Foodstuff will not own any of the Menu Items supplied by you and will not alter their nature or value.</p>
      <p>
        2.3 Foodstuff will, as agent on your behalf, process payments made by Users (the <strong>Takings</strong>) and
        subject to the deduction of any appropriate fees under clause 4 will remit the balance to you as described in
        clause 4. Certain of these Takings will relate to orders placed for delivery to the User (<strong
          >Delivery Orders</strong
        >), and certain of these Takings will relate to orders placed for collection from you (<strong
          >Click and Collect Orders</strong
        >).
      </p>
      <p>2.4 When you create a Restaurant Account Foodstuff will provide you with access to:</p>
      <div style="margin-left: 20px">
        <p>
          2.4.1 its Admin Software, through which you can administer your Restaurant Account, including setting your
          opening times and setting your food preparation times (the <strong>Prep Time</strong>); and
        </p>
        <p>
          2.4.2 its order management software (the <strong>OM Software</strong>) through which orders placed by Users
          will be monitored.
        </p>
      </div>
      <p>
        2.5 You must keep all account access information confidential, and immediately notify Foodstuff if you believe a
        third party may have obtained your access details.
      </p>
      <h3 style="margin-top: 20px">3. Menu items</h3>
      <p>
        3.1 You are solely responsible, through the Admin Software, for determining the Menu Items displayed on the
        Platform. However, Foodstuff reserves the right to remove any Menu Items from display on the Platform at any
        time and for any reason, without needing to give a reason for taking such action.
      </p>
      <p>
        3.2 You shall provide Foodstuff, through the Admin Software, with accurate descriptions and photographs of Menu
        Items for display on the Platform. Foodstuff may edit photographs or descriptions of Menu Items as it deems
        reasonably fit.
      </p>
      <p>
        3.3 It is explicitly understood that you shall assume full responsibility for the accurate inclusion and
        continual upkeep of the Value Added Tax (VAT) rates for each item listed on your menu. Consequently, Foodstuff
        shall not be held accountable, nor shall it incur any liability, for any discrepancies or inaccuracies in the
        VAT rates applied to items within your menu, and for any resulting consequences arising therefrom. In the event
        of any incorrect VAT rate attributed to an item, you shall bear the sole and exclusive responsibility for
        rectifying the matter and any penalties that may be imposed.
      </p>
      <p>
        3.4 Foodstuff will take reasonable steps to ensure that the Platform is updated as soon as possible with any
        changes (whether to Menu Items or otherwise) made through the Admin Software, but Foodstuff cannot guarantee
        that changes will immediately show on the Platform.
      </p>
      <p>
        3.5 You shall be solely responsible for identifying, and providing information to Foodstuff through the Admin
        Software for inclusion on the Platform, any allergens that may be contained within Menu Items in accordance with
        all applicable UK law, including but not limited to Regulation (EU) No 1169/2011 of the European Parliament on
        the provision of food information to consumers, as adopted in English law.
      </p>
      <h3 style="margin-top: 20px">4. Pricing and payment</h3>
      <p>
        4.1 Subject to any alternative rates set out in any Long Term Partner Addendum that you have signed, Foodstuff
        will charge you commission on such amount of the Takings that it processes for you that relate to Delivery
        Orders at the following rates (the <strong>Commission Fee</strong>):
      </p>
      <div style="margin-left: 20px">
        <p>4.1.1 25% plus VAT, where you have agreed to sell your Menu items through the Platform.</p>
      </div>
      <p>
        4.2 Subject to any alternative rates set out in any Long Term Partner Addendum that you have signed, Foodstuff
        will charge you commission on such amount of the Takings that it processes for you that relate to Click and
        Collect Orders at the following rates (the <strong>Click and Collect Fee</strong>):
      </p>
      <div style="margin-left: 20px">
        <p>4.2.1 7.5% plus VAT, where you have agreed to sell your Menu items through the Platform.</p>
      </div>
      <p>4.3 Foodstuff may deduct from any Takings that may be due to you from time to time:</p>
      <div style="margin-left: 20px">
        <p>4.3.1 any due Commission Fees;</p>
        <p>4.3.2 any amounts refunded by Foodstuff to Users under clause 13.2;</p>
        <p>
          4.3.3 any orders that are canceled will incur a charge of £5 (£2.50 to the customer, £2.50 to the rider,
          Foodstuff <strong>does not</strong> receive any of this payment)
        </p>
      </div>
      <p>
        4.4 Foodstuff will make payments to you of the Takings, less any fees due to Foodstuff, on Wednesdays (or the
        next Business Day):
      </p>
      <div style="margin-left: 20px">
        <p>
          4.4.1 the first payment will be made on the first Wednesday that is at least two weeks after the date on which
          you launch on the Platform (the <strong>Launch Date</strong>); and
        </p>
        <p>
          4.4.2 subsequent payments will be made on the Wednesday that immediately follows the fortnight for which the
          Takings relate.
        </p>
      </div>
      <p>
        4.5 All fees charged by Foodstuff, whether under these Terms or otherwise, are exclusive of VAT unless stated
        otherwise.
      </p>
      <h3 style="margin-top: 20px">5. Restaurant discounts and late penalties</h3>
      <p>5.1 Restaurant menu discounts</p>
      <div style="margin-left: 20px">
        <p>
          5.1.1 In compliance with the Foodstuff menu policies, it is stipulated that any orders exceeding a cart price
          stipulated by the restaurant shall be eligible for an automatic discount (agreed at restaurant onboarding)
          which shall be paid by the restaurant.
        </p>
        <p>5.1.2 This amount will be deducted from the restaurants payouts, on each pay run.</p>
      </div>
      <p>5.2 Late Charge Terms and Conditions</p>
      <div style="margin-left: 20px">
        <p>
          5.2.1 Late Charge Calculation: For late orders, the restaurant and Foodstuff will agree a mutual and fair
          reimbursement to the customer (usually 10% discount).
        </p>
        <p>
          5.2.2 Grace Period: There is a grace period of 5 minutes. This grace period allows additional time for the
          rider to pack the order.
        </p>
        <p>
          5.2.3 By agreeing to this contract, both the Company and the restaurant agree to abide by these terms and
          conditions regarding the <strong>late charge</strong> policy for delayed orders.
        </p>
      </div>
      <h3 style="margin-top: 20px">6. Ordering Process</h3>
      <p>
        6.1 Foodstuff will provide you with the Admin Software and the OM Software (together the
        <strong>Foodstuff Software</strong>).
      </p>
      <p>
        6.2 You must provide your own tablet device to run the Foodstuff Software. You must check in advance with
        Foodstuff that a proposed device will work with the Foodstuff Software.
      </p>
      <p>
        6.3 In the event that the Foodstuff Software becomes unavailable for a period of over 24 hours, affecting your
        ability to receive orders from Users, then Foodstuff will at its sole discretion consider whether a refund of
        part or all of the Monthly Fee will be made to you. This will not apply if any third party software used by
        Foodstuff (such as the rider management software used by Foodstuff to source delivery riders) becomes
        unavailable.
      </p>
      <p>
        6.4 Foodstuff will provide Users with an e-mail confirmation of any orders placed on the Platform. You shall be
        solely responsible for including an invoice or receipt for each User with their order.
      </p>
      <p>
        6.5 You are responsible for accounting for any output VAT on your gross Takings and for determining the VAT
        treatment of your Menu Items and sales.
      </p>
      <h3 style="margin-top: 20px">7. Delivery</h3>
      <p>
        7.1 You must ensure that the Prep Time for your menu is kept up-to-date in your Restaurant Account. Foodstuff
        will use the Prep Times to calculate the collection time for each order (the
        <strong>Pick-Up Time</strong>). You must ensure that each order is available for collection at your premises at
        the Pick-Up Time for that order as specified in the relevant Order note.
      </p>
      <p>
        7.2 Foodstuff will procure that a delivery rider will attend your premises at the Pick-Up Time in order to
        collect the relevant Menu Items for delivery to the relevant User.
      </p>
      <p>7.3 The User will be notified, through the Platform, when an Order has been collected for delivery.</p>
      <h3 style="margin-top: 20px">8. Permits</h3>
      <p>
        8.1 You shall procure and maintain in force such permits, certificates, registrations, licences and approvals
        (<strong>Permits</strong>) and comply with all laws, regulations and industry best practices
        (<strong>Laws</strong>) as are applicable to you in carrying out your activities in connection with these Terms,
        including compliance with all health and safety and food safety laws.
      </p>
      <p>
        8.2 In the event that you cease to hold any necessary Permits or to be in compliance with any necessary Laws
        then Foodstuff may remove you from the Platform and suspend your Restaurant Account.
      </p>
      <h3 style="margin-top: 20px">9. Confidentiality</h3>
      <p>
        9.1 Subject to clause 9.2, you undertake that you shall, and shall use all reasonable endeavours to procure that
        your staff shall, at all times while you are using the Foodstuff services and for a period of two years after
        their termination:
      </p>
      <div style="margin-left: 20px">
        <p>
          9.1.1 keep confidential all information of a confidential or proprietary nature in relation to Foodstuff,
          including without limitation, details of its business methods, the identity of any of its Users or suppliers,
          its pricing structure or margins, order volumes and any financial information, these Terms, any other terms of
          business between Foodstuff and its restaurants, Users or drivers and any details of the Foodstuff Software
          (<strong>Confidential Information</strong>);
        </p>
        <p>9.1.2 not disclose any Confidential Information to any other party; and</p>
        <p>9.1.3 not use any Confidential Information for any purpose other than as contemplated by these Terms.</p>
      </div>
      <p>
        9.2 Disclosure of Confidential Information may be made only to the extent that is necessary as required by law,
        or where that Confidential Information in question is or becomes public knowledge other than by virtue of a
        breach of any applicable confidentiality obligation by the disclosing party.
      </p>
      <h3 style="margin-top: 20px">10. Marketing</h3>
      <div>
        <p>
          10.1 Foodstuff shall be entitled to advertise its services in such manner, and using such media, as it thinks
          fit.
        </p>
        <p>
          10.2 You acknowledge and agree that Foodstuff may use your name and/or logo and photos provided under clause
          3.2 in any of its marketing materials (including, without limitation, in print or broadcast media, via the
          internet or social media networks) and may expressly indicate in any such marketing that your Menu Items are
          available to Users on the Platform and that you use Foodstuff for delivery.
        </p>
      </div>
      <h3 style="margin-top: 20px">11. Intellectual Property</h3>
      <p>
        11.1 All rights, title and interest in and to the Platform and the Foodstuff Software and any other software or
        materials provided to you by Foodstuff from time to time shall remain the property of Foodstuff.
      </p>
      <p>
        11.2 You hereby grant to Foodstuff a non-exclusive royalty-free licence for such time as you are using the
        Foodstuff services to use your logo and/or name and photos for the purposes of clause 10 and as otherwise
        reasonably necessary or desirable in connection with these Terms. You shall on request supply to Foodstuff such
        images or other artwork so as to enable Foodstuff to accurately reproduce your logo on advertising materials.
      </p>
      <p>
        11.3 Foodstuff hereby grants to you a non-exclusive royalty free licence for such time as you are using the
        Foodstuff services to use its logo and/or name and/or website address for the purposes of advertising the fact
        that Foodstuff makes deliveries for you. Foodstuff shall on request supply to you such images or other artwork
        so as to enable you to accurately reproduce Foodstuff's logo on advertising materials. Foodstuff reserves the
        right to revoke this licence at any time by written notice to you.
      </p>
      <p>
        11.4 Except as provided in this clause 10, nothing in these Terms shall cause any party to acquire any rights
        in, or entitle any party to use, any intellectual property rights of the other party.
      </p>
      <h3 style="margin-top: 20px">12. Data Protection</h3>
      <p>
        12.1 Foodstuff will gather personal data from Users for the purposes of arranging the payment and delivery of
        orders placed through the Platform. Foodstuff will handle this personal data in accordance with its obligations
        under the relevant data protection legislation in force in the UK from time to time (<strong
          >Data Protection Laws</strong
        >).
      </p>
      <p>
        12.2 Foodstuff will not share any personal data gathered from Users with you. You will only receive an order
        number identifying each order.
      </p>
      <p>
        12.3 It is expected that you may provide Foodstuff with limited personal data on your representatives (such as
        their name and work contact details). Any such personal data will be handled by Foodstuff in accordance with its
        obligations under the Data Protection Laws and its privacy policy available on its website.
      </p>
      <h3 style="margin-top: 20px">13. Termination</h3>
      <p>
        13.1 Either party may terminate the use and provision of the Foodstuff services outlined in these Terms by
        giving written notice to the other party:
      </p>
      <div style="margin-left: 20px">
        <p>
          13.1.1 if any undisputed sum owing to that party under any of the provisions of these Terms is not paid within
          10 days of the due date for payment;
        </p>
        <p>13.1.2 if the other party commits any material breach of any of the provisions of these Terms; or</p>
        <p>13.1.3 on giving the other party not less than 3 days notice of the intention to terminate.</p>
      </div>
      <p style="margin-top: 20px">
        13.2 Foodstuff may terminate your use of the Foodstuff services outlined in these Terms at any time if:
      </p>
      <div style="margin-left: 20px">
        <p>13.2.1 you cease to hold any necessary Permits or to be in compliance with any necessary Laws;</p>
        <p>13.2.2 you repeatedly fails to prepare Menu Items correctly or on time;</p>
        <p>
          13.2.3 any other circumstance arises in connection with you which in the reasonable opinion of Foodstuff could
          bring the reputation of Foodstuff into disrepute if you remained on the Platform; or
        </p>
        <p>
          13.2.4 you become insolvent, become subject to any form of insolvency procedure or have a receiver appointed
          over all or part of your assets.
        </p>
      </div>
      <h3 style="margin-top: 20px">14. Liability and indemnity</h3>
      <p>
        14.1 You shall be solely responsible and liable for, and agree to fully and effectively indemnify and keep
        Foodstuff indemnified against, any claims, losses, costs, expenses against Foodstuff from Users or any other
        person relating to, or arising (directly or indirectly) from the description, quality, content or packaging of
        the Menu Items, including without limitation, allegations or claims:
      </p>
      <div style="margin-left: 20px">
        <p>
          14.1.1 that the Menu Items do not correspond with the descriptions set out on the Platform (including, without
          limitation, incorrect indications that particular Menu Items are free from allergens or are suitable for
          vegetarians and/or vegans);
        </p>
        <p>
          14.1.2 that the Menu Items are improperly prepared, at the incorrect temperature or otherwise not fit for
          consumption or palatable;
        </p>
        <p>14.1.3 that incorrect allergen information has been provided by you in connection with any Menu Items;</p>
        <p>14.1.4 that the Menu Items have not been appropriately packaged; or</p>
        <p>
          14.1.5 that the relevant Menu Items are cold on delivery to the User (save where, and to the extent that, this
          is as a result of Foodstuff's failure to deliver Menu Items within a reasonable period after the Pick-Up Time.
        </p>
      </div>
      <p>
        14.2 Foodstuff reserves the right to offer Users a full or partial refund as compensation for any complaints
        made by Users that relate to the matters set out in clause 14.1, and to deduct the amount of any such refunds
        from any future amount of Takings due to be paid by Foodstuff to you under clause 4.
      </p>
      <p>
        14.3 Except in respect of death or personal injury caused by Foodstuff's negligence, Foodstuff will not by
        reason of any representation, implied warranty, condition or other term, or any duty at common law or under the
        express terms of these Terms, be liable for any loss of profit or any indirect, special or consequential loss,
        damage, costs, expenses or other claims (whether caused by delivery riders, agents, third party software or
        otherwise) in connection with the performance of its obligations under these Terms.
      </p>
      <h3 style="margin-top: 20px">15. Force Majeure</h3>
      <p>
        15.1 Neither party shall be liable for any failure or delay in performing their obligations under these Terms
        where such failure or delay results from any cause that is beyond the reasonable control of that party. Such
        causes include, but are not limited to: power failure, Internet Service Provider failure, strike or other labour
        disputes, industrial action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war,
        governmental action or any other similar event.
      </p>
      <p>
        15.2 On the occurrence of an event described in clause 15.1 the affected party shall give written notice to the
        other party containing reasonable details of the event and its impact on the affected party.
      </p>
      <h3 style="margin-top: 20px">16. Notices</h3>
      <p>
        16.1 Any notice given to a party under or in connection with these Terms shall be in writing and shall be sent
        by signed for delivery service or by e-mail:
      </p>
      <div style="margin-left: 20px">
        <p>
          16.1.1 in the case of notices to Foodstuff, to the Foodstuff address stated at the start of these Terms or to:
          <a href="mailto:hello@wearefoodstuff.co.uk">hello@wearefoodstuff.co.uk</a>
        </p>
        <p>
          16.1.2 in the case of notices to you, to your address or e-mail address as set out in your Restaurant Account.
        </p>
      </div>
      <p>16.2 Any notice shall be deemed to have been received:</p>
      <div style="margin-left: 20px">
        <p>
          16.2.1 when sent by signed for delivery service, at the delivery date recorded by the relevant service; or
        </p>
        <p>
          16.2.2 when sent by e-mail, at the time of transmission, or, if this time falls outside Business Hours in the
          place of receipt, when Business Hours resume.
        </p>
      </div>
      <p>
        16.3 This clause does not apply to the service of any proceedings or any documents in any legal action or, where
        applicable, any arbitration or other method of dispute resolution.
      </p>
      <h3 style="margin-top: 20px">17. Your relationship with Foodstuff</h3>
      <p>17.1 You acknowledge that:</p>
      <div style="margin-left: 20px">
        <p>
          17.1.1 Users will be made aware that Foodstuff is acting in the capacity of a disclosed agent for you, with
          authority to conclude sales of Menu Items on your behalf, and that accordingly you will be classified as a
          disclosed principal; and
        </p>
        <p>
          17.1.2 when placing an order Users will accordingly enter into contractual relationships directly with you.
        </p>
      </div>
      <p>
        17.2 By using Foodstuff's products and services, you <strong>authorize</strong> the collection and sharing of
        your data and contact information with affiliated restaurants. This sharing is intended to enhance your
        experience, facilitate orders, and provide you with relevant promotions. Your consent is granted upon your use
        of our services, and you acknowledge that your information may be used in accordance with our privacy policy.
      </p>
      <h3 style="margin-top: 20px">18. General</h3>
      <p>
        18.1 No waiver by Foodstuff of any breach by you of these Terms shall be considered as a waiver of any
        subsequent breach. A waiver of any of these Terms shall be effective only if given in writing and signed by
        Foodstuff.
      </p>
      <p>
        18.2 No failure or delay on the part of any party in exercising any right under these Terms shall operate as a
        waiver of such right.
      </p>
      <p>
        18.3 If any provision or part-provision of these Terms is or becomes invalid, illegal or unenforceable, it shall
        be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification
        is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to, or
        deletion of a provision under this clause shall not affect the validity and enforceability of the rest of these
        Terms. No third party shall have any rights under or in connection with these Terms by virtue of the Contracts
        (Rights of Third parties) Act 1999.
      </p>
      <p>
        18.4 These Terms shall constitute the entire agreement between you and Foodstuff in respect of their subject
        matter and shall supersede all previous negotiations, agreements and commitments with respect thereto.
      </p>
      <p>
        18.5 These Terms will apply automatically to your use of the Foodstuff services. You will be asked to confirm
        your consent to these Terms when creating a Restaurant Account.
      </p>
      <p>
        18.6 These Terms shall be governed by, and construed in accordance with, English law and any dispute,
        proceedings or claim between the parties shall fall within the exclusive jurisdiction of the English courts.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Tos extends Vue {
  public showButton: any = false;

  public mounted() {
    this.scrollHandler();
  }

  public scrollHandler() {
    window.onscroll = () => {
      let height = document.documentElement.scrollTop + window.innerHeight;
      let scroll = document.documentElement.offsetHeight - 500;
      let bottomOfWindow = height >= scroll;

      if (bottomOfWindow) {
        this.showButton = true;
      }
    };
  }
}
</script>

<style lang="scss" scoped>
table {
  margin: 15px 0;
  tr td {
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
  }
}
.title {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: $dark;
  line-height: 120%;
  @include bp-768 {
    font-size: 30px;
  }
}
.text {
  color: $dark;
  font-family: $Montserrat;
  font-size: 16px;
  line-height: 180%;
  font-weight: 300;
}
.text-page {
  padding-bottom: 40px;
  @extend .text;
  .title {
    padding: 40px 0;
  }
  h2 {
    @extend .title;
    padding: 40px 0;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    margin: 15px 0 20px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 15px;
  }
  a {
    color: $pink;
    text-decoration: underline;
  }
  ul {
    padding-left: 40px;
    list-style: disc;
  }
  ol {
    padding-left: 40px;
    list-style: upper-alpha;
    ol {
      padding-left: 40px;
      list-style: upper-roman;
    }
  }
  strong {
    font-weight: bold;
  }
}
.btn-mentol {
  display: block;
  margin: 25px auto;
  width: 15vw;
}
</style>
