<template>
  <label class="c-checkbox" :class="{ disabled, big }">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" :bails="false">
      <span v-if="textOnTheLeft" class="text-left"><slot /></span>
      <input ref="field" class="input" type="checkbox" :checked="value" :disabled="disabled" @input="update" />
      <span>
        <span class="square">
          <icon-radio />
        </span>
        <slot v-if="!textOnTheLeft" />
      </span>
      <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
    </ValidationProvider>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import IconCheckbox from '@/_assets/icons/icon-checkbox.svg';
import IconRadio from '@/_assets/icons/icon-radio.svg';

@Component({
  components: {
    IconRadio,
    IconCheckbox,
  },
})
export default class AppCheckbox extends Vue {
  @Prop() value: any;
  @Prop() name: any;
  @Prop() rules: any;
  @Prop() textOnTheLeft: any;
  @Prop() disabled: any;
  @Prop() big: any;

  public update(e: any) {
    this.$emit('input', e.target.checked);
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  cursor: default !important;
  user-select: none;
}

.c-checkbox {
  margin-bottom: 20px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 300;
  &.is-marginless {
    margin: 0;
  }
  span {
    display: inline;
    align-items: center;
    .square {
      margin-right: 10px;
      height: 24px;
      width: 24px;
      border: 1px solid #7f7e81;
      justify-content: center;
      transition: 0.5s all;
      svg {
        opacity: 0;
      }
    }
  }
  input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    &:disabled {
      background-color: red;
    }
  }
  input:checked + span {
    .square {
      background-color: $pink;
      border-color: $pink;
      svg {
        transition: 0.5s all;
        opacity: 1;
      }
    }
    .square-empty {
      background-color: white;
      border-color: black;
      svg {
        transition: 0.5s all;
        opacity: 1;
      }
    }
  }
  &.is-rounded {
    span {
      .square {
        border-radius: 50%;
      }
    }
  }
  &.is-half-rounded {
    span {
      .square {
        border-radius: 7px;
      }
    }
  }
  .error {
    display: block;
    font-size: 12px;
    margin-top: 15px;
    color: $red;
  }
  .text-left {
    margin-right: 0.5em;
    font-weight: 600;
  }
}
.big > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
}
</style>
