<template>
  <div class="dish">
    <div class="dish-head">
      <div class="close" @click="$router.go(-1)">
        <app-icon name="close" />
      </div>
      Dish
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmit">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <div class="dish-block">
              <div class="category">
                <div class="label">Category*</div>
                <app-select v-model="categoryId" :options="categories" select-title="Dish Category" />
              </div>
              <app-input v-model="dish.name" rules="required" name="name" type="text"> Dish Name* </app-input>
              <app-input v-model="dish.price" rules="required|decimal" name="price" type="text">
                Dish Price*
              </app-input>
              <app-input v-model="dish.taxRate" rules="required|tax_rate" name="taxRate" type="text">
                Tax rate (percentage)*
              </app-input>
              <app-input v-model="dish.minHoursInAdvance" rules="numeric" name="minHoursInAdvance" type="number">
                Additional X hours in advance (optional)
              </app-input>
            </div>
          </div>

          <div class="column is-5">
            <div class="dish-block">
              <label class="label">Add photo</label>
              <app-file v-model="dish.photo" rules="required" name="Photo" @input="previewPhoto($event)">
                <div v-if="preview" class="dish-image">
                  <span @click.stop.prevent="removePreview">
                    <app-icon name="remove-red-circle" />
                  </span>
                  <img :src="preview" alt="" />
                </div>
                <div v-else class="picture-holder">
                  <span class="picture-holder-add">
                    <app-icon name="add-small" />
                  </span>
                  <div class="picture-holder-parameters">
                    <p>Recommended</p>
                    <p>980x980 pix</p>
                  </div>
                </div>
              </app-file>
            </div>
          </div>

          <div class="column is-4">
            <div class="dish-block">
              <div class="columns">
                <div class="column is-7">
                  <app-input v-model="dish.weight" rules="numeric" name="weight"> Weight, grams (optional) </app-input>
                </div>
                <div class="column is-5 is-alcohol">
                  <app-checkbox v-model="dish.isAlcohol" class="is-rounded" name="isAlcohol">Alcohol</app-checkbox>
                </div>
              </div>
              <label>
                <app-textarea v-model="dish.description" name="description" />
              </label>
            </div>
            <div class="dish-block">
              <div class="modificators-head">
                <div class="subtitle">+ Add modificators</div>
                <span @click="openModalAddDishModificators">
                  <app-icon name="add-small"></app-icon>
                </span>
              </div>
              <div class="modificators-body">
                <div v-for="(group, index) of dish.modificatorGroups" :key="'group-' + index" class="category">
                  <app-accordion>
                    <template #head>
                      <div class="category-name">{{ group.name }}</div>
                      <app-popover>
                        <div @click="deleteModificatorGroup(group)"><app-icon name="delete" /> Delete</div>
                        <div @click="openModalEditDishModificators(group)"><app-icon name="pencil" /> Edit</div>
                      </app-popover>
                    </template>
                    <template #body>
                      <div v-for="m of group.modificators" :key="m.id" class="modificator">
                        <p class="modificator-name">{{ m.name }}</p>
                        <p v-if="m.price" class="modificator-price">+ £{{ m.price.toFixed(2) }}</p>
                      </div>
                    </template>
                  </app-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="error form-error">{{ errorMessage }}</div>
        <button :disabled="invalid" :class="{ 'btn-pink': true, 'btn-disabled': invalid }" type="submit">Save</button>
      </div>
    </ValidationObserver>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';
import { toFormData } from '@/_shared/utils/to-form-data';

@Component({})
export default class AddDish extends Vue {
  public categories: ICategory[] = [];
  public categoryId: any = 0;
  public preview = '';
  public errorMessge = '';
  public loading = false;

  public dish: IDish = {
    name: '',
    price: null,
    taxRate: null,
    minHoursInAdvance: null,
    isAlcohol: false,
    description: '',
    photo: '',
    dishSlug: '',
    modificatorGroups: [],
  };

  mounted() {
    this.categoryId = this.$route.params['categoryId'];
    this.getCategories();
    this.subscribeForModificatorsGroup();
    this.getDish();
  }

  public errorMessage = '';

  public previewPhoto(file: any) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  public onSubmit() {
    this.loading = true;

    this.dish.modificatorGroups.map((group: IModificatorGroup) => {
      group.modificators = group.modificators.map((m: any) => m.id || m);
    });

    // Prevent sending minHoursInAdvance as null:
    if (this.dish.minHoursInAdvance === null) this.dish.minHoursInAdvance = 0;

    if (this.dishSlug) {
      this.dish.dishSlug = this.categoryId;
      http
        .put(`/admin/menu/${this.vendorSlug}/dishes/${this.dishSlug}`, toFormData(this.dish))
        .then(() => {
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err: any) => {
          this.loading = false;
          this.errorMessge = err.message;
        });
    } else {
      http
        .post(`/admin/menu/${this.vendorSlug}/dish-categories/${this.categoryId}`, toFormData(this.dish))
        .then(() => {
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err: any) => {
          this.loading = false;
          this.errorMessge = err.message;
        });
    }
  }

  public subscribeForModificatorsGroup() {
    ModalHub.$on('close', (data: { modificatorGroup: IModificatorGroup }) => {
      if (data && data.modificatorGroup) {
        this.dish.modificatorGroups.push(data.modificatorGroup);
      }
    });
  }

  public openModalAddDishModificators() {
    ModalHub.$emit('open', 'modal-add-dish-modificators', {
      animation: 'slide-left',
    });
  }

  public openModalEditDishModificators(modificatorGroup: IModificatorGroup) {
    ModalHub.$emit('open', 'modal-add-dish-modificators', {
      animation: 'slide-left',
      data: { modificatorGroup },
    });
  }

  public deleteModificatorGroup(group: any) {
    this.dish.modificatorGroups.splice(this.dish.modificatorGroups.indexOf(group), 1);
  }

  public removePreview() {
    this.preview = '';
    this.dish.photo = '';
  }

  public getDish() {
    if (!this.dishSlug) return;
    http.get(`/admin/menu/${this.vendorSlug}/dishes/${this.dishSlug}`).then((res: { dish: IDish }) => {
      this.dish = res.dish;
      this.dish.price = res.dish.price + '';
      (this.dish.taxRate = res.dish.taxRate !== null ? res.dish.taxRate + '' : null), (this.preview = res.dish.photo);
    });
  }

  private getCategories() {
    http.get(`/admin/menu/${this.vendorSlug}/dish-categories/short`).then((res: { dishCategories: ICategory[] }) => {
      this.categories = res.dishCategories;
    });
  }

  get vendorSlug(): string {
    return this.$route.params['slug'];
  }

  get dishSlug(): string {
    return this.$route.params['dishSlug'];
  }
}

interface IDish {
  name: string;
  price: string | null; // 'string' type is workaround for setting 0 on init
  taxRate?: string | null; // 'string' type is workaround for setting 0 on init
  weight?: number | null;
  minHoursInAdvance: number | null;
  isAlcohol: boolean;
  description: string;
  photo: any; // file
  modificatorGroups: IModificatorGroup[];
  dishCategoryId?: number;
  dishSlug: any;
}

export interface IModificatorGroup {
  name: string;
  required: boolean;
  limitMin: number;
  limitMax: number;
  modificators: number[] | any;
}

interface ICategory {
  name: string;
  id: number;
  slug: string;
}
</script>

<style lang="scss" scoped>
.dish {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.dish-head {
  height: 100px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid $gray;
}
.close {
  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;
}
.dish-info .app-input {
  height: 92px;
  .error {
    margin-top: 1px;
  }
}
.dish-subtitle {
  font-size: 14px;
  font-weight: 300;
  margin: 30px 0;
}
.dish-block {
  background-color: $lightgray;
  padding: 20px;
  line-height: 140%;
  & + .dish-block {
    margin-top: 20px;
  }
}
.btn {
  min-width: 256px;
}
.is-alcohol {
  display: flex;
  align-items: flex-end;
  padding-bottom: 28px;
}
.dish-image {
  width: 256px;
  height: 256px;
  margin: auto;
  position: relative;
  span {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    user-select: none;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.modificators-head {
  padding: 10px 0 10px;
  display: flex;
  align-items: center;
  .subtitle {
    margin-right: auto;
  }
  span {
    cursor: pointer;
    user-select: none;
    svg rect {
      transition: 0.5s all;
    }
    &:hover {
      color: darken($pink, 10);
      svg rect {
        fill: darken($pink, 10);
      }
    }
  }
}
.modificators-body {
  padding-top: 20px;
}
.category {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
  }
  .popover {
    margin-left: auto;
    color: $dark;
  }
}
.modificator {
  background-color: $lightgray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
  .icon {
    margin-left: auto;
    cursor: pointer;
  }
}
.form-error {
  margin-bottom: 20px;
}
.category {
  margin-bottom: 20px;
  .label {
    margin-bottom: 10px;
  }
}
.btn-disabled {
  opacity: 0.2;
}
</style>
