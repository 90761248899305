<template>
  <div class="onboarding-signup">
    <header>
      <h1 class="fontfamily-recoleta fontsize-30">Create your account below</h1>
      <p class="color-coal60 fontsize-20">Don’t worry, you can add more users to the restaurant later.</p>
    </header>
    <ValidationObserver v-slot="{}" ref="form" tag="form" @submit.prevent="submitForm">
      <div class="onboarding-signup-form">
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column">
            <app-input v-model="form.firstName" class="is-marginless" rules="required" name="First Name">
              First Name
            </app-input>
          </div>
          <div class="column">
            <app-input v-model="form.lastName" class="is-marginless" rules="required" name="Last Name">
              Last Name
            </app-input>
          </div>
        </div>
        <app-input v-model="form.email" :readonly="true" rules="email|required" name="Email"> Email address </app-input>
        <template v-if="isFetching">
          <app-modal-loader />
        </template>

        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column">
            <app-input
              ref="password"
              v-model="form.password"
              class="is-marginless"
              rules="required|password"
              type="password"
              name="Password"
            >
              Password
            </app-input>
          </div>
          <div class="column">
            <app-input
              v-model="password2"
              class="is-marginless"
              rules="confirmed:Password"
              data-vv-as="password"
              type="password"
              name="Password confirmation"
            >
              Repeat password
            </app-input>
          </div>
        </div>

        <div class="error">{{ errorMessage }}</div>
        <div class="text-center">
          <button class="btn-ham">
            Next
            <Icon icon="arrow" direction="right" class="m-l-10" />
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import Icon from '@/_shared/icons/icon.vue';
import http from '@/_shared/utils/http';

import Environment from '@/environment';

@Component({
  components: {
    Icon,
  },
})
export default class OnboardingSignUp extends Vue {
  public $refs!: {
    form: HTMLFormElement;
  };
  public password2 = '';
  public $router: any;
  public $route: any;
  public form: any = {
    firstName: '',
    lastName: '',
    email: 'test@email.com',
    password: '',
  };
  public tos = false; // term of service
  public errorMessage = '';
  public termsOfServiceUrl = `${Environment.clientUrl}/terms-of-service`;
  private isFetching: boolean = false;

  public async submitForm() {
    let isValid = await this.$refs.form.validate();
    this.isFetching = true;
    if (isValid) {
      http
        .post('/accounts/vendors/sign-up', {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
          code: this.code,
        })
        .then((res: any) => {
          this.isFetching = false;
          if (res.isFirstAdmin) {
            this.$emit('isSignup', res.vendor);
          } else {
            this.$router.push('/sign-in');
          }
        })
        .catch((err: any) => {
          this.errorMessage = err.message;
        });
    }
  }

  get code() {
    return this.$route.query['code'];
  }

  mounted() {
    http.get(`/accounts/vendors/account-setup/${this.code}`).then((res: any) => {
      this.form.email = res.email;
    });
  }
}
</script>

<style lang="scss" scoped>
.onboarding-signup {
  &-form {
    width: 440px;
    max-width: 100%;
    margin: 0 auto;

    button {
      width: 310px;
      max-width: 100%;
      margin-top: 30px;
      @include bp-480 {
        width: 100%;
      }
      @include bp-380 {
        margin-top: 15px;
        font-weight: 400;
      }
    }
    .input {
      height: 46px;
    }
  }
}
</style>
