<template>
  <ValidationObserver id="form-example" ref="form" tag="form" class="modal-wrapper" @submit.prevent="addRestaurant">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      <p v-if="index !== -1">Edit restaurant</p>
      <p v-else>Add restaurant</p>
    </div>
    <div class="columns is-multiline">
      <app-input v-model="form.name" class="column is-12" rules="required" name="Name">Restaurant Name*</app-input>
      <app-input v-model="form.street" class="column is-10" rules="required" name="Street">Street*</app-input>
      <app-input v-model="form.number" class="column is-2" rules="required" name="Number">Number*</app-input>
      <div class="column is-6">
        <ValidationProvider v-slot="{ errors }" rules="required" name="Post code">
          <label class="label">Postcode*</label>
          <input
            v-model="form.postCode"
            class="input-postcode"
            type="text"
            placeholder="eg SW1P 1FZ"
            @keydown.tab="setFirstSuggestion"
            @input="onKeywordChange"
          />
          <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
        </ValidationProvider>
        <div class="dropdown" :class="{ open: suggestions.length }">
          <div v-for="s in suggestions" :key="s.id" class="suggestion" @click="setKeyword(s)">
            {{ s.postcode }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <ValidationProvider v-slot="{ errors }" rules="required" name="City">
          <label class="label">City*</label>
          <app-select
            v-model="form.city"
            :capitalize="true"
            :options="zones"
            :optionValue="'name'"
            select-title="Choose City"
          />
          <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
        </ValidationProvider>
      </div>
    </div>

    <p class="title">Dietaries*</p>
    <div class="modal-select">
      <div class="check-select-wrapper">
        <app-pill
          v-for="dietary in dietaries"
          :key="dietary.id"
          v-model="dietary.chosen"
          class="check-select is-marginless"
        >
          {{ dietary.name }}
        </app-pill>
      </div>
    </div>
    <p class="title">Categories*</p>
    <div class="modal-select">
      <div class="check-select-wrapper">
        <app-pill
          v-for="category in categories"
          :key="category.id"
          v-model="category.chosen"
          class="check-select is-marginless"
        >
          {{ category.name }}
        </app-pill>
      </div>
    </div>
    <p class="title">Budget*</p>
    <div class="modal-select">
      <div class="check-select-wrapper">
        <app-pill v-for="budget in budgets" :key="budget.id" v-model="budget.chosen" class="check-select is-marginless">
          {{ budget.name }}
        </app-pill>
      </div>
    </div>
    <p class="title">Perfect for*</p>
    <div class="modal-select">
      <div class="check-select-wrapper">
        <app-pill
          v-for="perfectFor in perfectFors"
          :key="perfectFor.id"
          v-model="perfectFor.chosen"
          class="check-select is-marginless"
        >
          {{ perfectFor.name }}
        </app-pill>
      </div>
    </div>
    <p class="title">Opening times*</p>
    <div v-for="(value, name, index) in days" :key="index" class="time">
      <p class="time-title">
        {{ name }}
      </p>
      <div class="time-inputs">
        <app-switcher ref="field" v-model="days[name][2]" class="closed-switch" :checked="!value">
          Closed
        </app-switcher>
        <ValidationProvider
          v-slot="{ errors }"
          class="input-container"
          rules="required|length:5"
          :name="`hour-input-${index}-from`"
        >
          <p class="label">From</p>
          <div>
            <input
              v-model="days[name][0]"
              v-mask="'##:##'"
              type="text"
              class="input"
              :class="timeFailure"
              placeholder="12:00"
            />
            <span v-for="(error, i) in errors" :key="i" class="error error-span">Invalid time</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          class="input-container"
          rules="required|length:5"
          :name="`hour-input-${index}-to`"
        >
          <p class="label">To</p>
          <div>
            <input
              v-model="days[name][1]"
              v-mask="'##:##'"
              type="text"
              class="input"
              :class="timeFailure"
              placeholder="12:00"
            />
            <span v-for="(error, i) in errors" :key="i" class="error error-span">Invalid time</span>
          </div>
        </ValidationProvider>
      </div>
    </div>

    <input v-if="index !== -1" type="submit" class="btn-pink modal-submit" value="Edit" />
    <input v-else type="submit" class="btn-pink modal-submit" value="Add" />
  </ValidationObserver>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Prop, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';
import throttle from '@/_shared/utils/throttle';

@Component({
  components: {
    AppLogo,
  },
  directives: {
    mask,
  },
})
export default class ModalAddRestaurant extends Vue {
  public modalHub = ModalHub;
  public searchField = '';
  public index = -1;
  public errors = '';

  @Prop() data!: IRestaurant;
  suggestions: any[] = [];
  postCode: IPostCode = {
    id: -1,
    nearestStation: '',
    postcode: '',
  };

  public form = {
    name: '',
    street: '',
    number: '',
    postCode: '',
    city: '',
  };

  public $refs!: {
    form: HTMLFormElement;
  };

  days = {
    Monday: ['', '', false],
    Tuesday: ['', '', false],
    Wednesday: ['', '', false],
    Thursday: ['', '', false],
    Friday: ['', '', false],
    Saturday: ['', '', false],
    Sunday: ['', '', false],
  };

  public dietaries: IFilter[] = [];
  public categories: IFilter[] = [];
  public budgets: IFilter[] = [];
  public perfectFors: IFilter[] = [];
  public zones: Zones[] = [];

  public close() {
    ModalHub.$emit('close');
  }

  get timeFailure() {
    let failure = false;
    Object.values(this.days).forEach((elem: any[]) => {
      const [firstHours, firstMinutes] = elem[0].split(':').map((time: string) => parseInt(time));
      const [secondHours, secondMinutes] = elem[1].split(':').map((time: string) => parseInt(time));
      if (firstHours > secondHours) {
        failure = true;
      }
      if (firstHours === secondHours && firstMinutes > secondMinutes) {
        failure = true;
      }
      if (firstHours >= 24 || secondHours >= 24 || firstMinutes >= 60 || secondMinutes >= 60) {
        failure = true;
      }
    });
    return failure;
  }

  private async addRestaurant() {
    let isValid = await this.$refs.form.validate();
    if (!isValid) return;

    const chosenDietaries = this.dietaries.filter((e) => e.chosen);
    const chosenDietariesForTitle = chosenDietaries.map((e) => e.name);

    const chosenCategories = this.categories.filter((e) => e.chosen);
    const chosenCategoriesForTitle = chosenCategories.map((e) => e.name);

    const chosenBudgets = this.budgets.filter((e) => e.chosen);
    const chosenBudgetsForTitle = chosenBudgets.map((e) => e.name);

    const chosenPerfectFors = this.perfectFors.filter((e) => e.chosen);
    const chosenPerfectForsTitle = chosenPerfectFors.map((e) => e.name);

    if (!chosenDietaries.length || !chosenCategories.length || !chosenBudgets.length || !chosenPerfectFors.length) {
      return;
    }

    if (this.index === -1) {
      ModalHub.$emit('addRestaurant', {
        name: this.form.name,
        number: this.form.number,
        street: this.form.street,
        postCode: this.form.postCode,
        city: this.form.city,
        postCodeObject: this.postCode,
        locationName: `${this.form.number} ${this.form.street}, ${this.form.name}, ${this.form.postCode}, ${this.form.city}`,
        dietariesTitle: chosenDietariesForTitle.join(', '),
        categoriesTitle: chosenCategoriesForTitle.join(', '),
        budgetsTitle: chosenBudgetsForTitle.join(', '),
        perfectForsTitle: chosenPerfectForsTitle.join(', '),
        chosenDietaries,
        chosenCategories,
        chosenBudgets,
        chosenPerfectFors,
        dietaries: this.dietaries,
        categories: this.categories,
        budgets: this.budgets,
        perfectFors: this.perfectFors,
        days: this.days,
      });
    } else {
      ModalHub.$emit('editRestaurant', {
        name: this.form.name,
        number: this.form.number,
        street: this.form.street,
        postCode: this.form.postCode,
        city: this.form.city,
        postCodeObject: this.postCode,
        locationName: `${this.form.number} ${this.form.street}, ${this.form.name}, ${this.form.postCode}, ${this.form.city}`,
        dietariesTitle: chosenDietariesForTitle.join(', '),
        categoriesTitle: chosenCategoriesForTitle.join(', '),
        budgetsTitle: chosenBudgetsForTitle.join(', '),
        perfectForsTitle: chosenPerfectForsTitle.join(', '),
        chosenDietaries,
        chosenCategories,
        chosenBudgets,
        chosenPerfectFors,
        dietaries: this.dietaries,
        categories: this.categories,
        budgets: this.budgets,
        perfectFors: this.perfectFors,
        days: this.days,
        index: this.index,
      });
    }

    ModalHub.$emit('close');
  }

  get onKeywordChange() {
    const delay = 300;
    return throttle(this.getSuggestions, delay);
  }

  public getSuggestions() {
    http.get(`/restaurants/postcodes?postcode=${this.form.postCode || 'null'}`).then((res: { postcodes: any[] }) => {
      this.suggestions = res.postcodes;
    });
  }

  public getZoneSuggestions() {
    http.get(`/restaurants/zones`).then((res: { zones: Zones[] }) => {
      this.zones = res.zones;
    });
  }

  public setKeyword(value: any) {
    this.form.postCode = value.postcode;
    this.postCode = value;
    this.suggestions = [];
  }

  public setFirstSuggestion() {
    this.form.postCode = this.suggestions[0].postcode;
    this.postCode = this.suggestions[0];
  }

  mounted() {
    this.getZoneSuggestions();
    if (this.data) {
      this.form.name = this.data.name;
      this.form.number = this.data.number;
      this.form.street = this.data.street;
      this.form.city = this.data.city.toLowerCase();
      this.form.postCode = this.data.postCode;
      this.postCode = this.data.postCodeObject;
      this.dietaries = this.data.dietaries;
      this.categories = this.data.categories;
      this.budgets = this.data.budgets;
      this.perfectFors = this.data.perfectFors;
      this.index = this.data.index;
      this.days = this.data.days;
    } else {
      http.get('/restaurants/filter-options').then((response: any) => {
        this.dietaries = response.dietaries;
        this.categories = response.categories;
        this.budgets = response.budgets;
        this.perfectFors = response.perfectFors;
      });
    }
  }
}

interface IFilter {
  id: number;
  name: string;
  chosen: boolean;
}

interface Zones {
  name: string;
}

interface IPostCode {
  id: number;
  postcode: string;
  nearestStation: string;
}

interface IRestaurant {
  name: string;
  street: string;
  number: string;
  postCode: string;
  city: string;
  postCodeObject: any;
  dietaries: any;
  categories: any;
  budgets: any;
  perfectFors: any;
  index: number;
  days: any;
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  &.modal-inner {
    width: 550px;
    overflow-x: hidden;
  }
  .modal-head {
    margin: 0 0 40px;
    font-size: 14px;
    text-align: center;
    position: relative;
    .close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
  .label {
    margin-bottom: 10px;
  }
  .input-postcode {
    border: $controlBorder;
    height: $controlHeight;
    border-radius: $radius;
    padding-left: 20px;
    display: block;
    width: 100%;
    color: $dark;
    font-family: $Montserrat, sans-serif;
    font-size: 14px;
    outline: none;
    &::placeholder {
      font-family: $Montserrat;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
    }
  }
  .dropdown {
    background-color: $white;
    position: absolute;
    width: calc(55% - 80px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s all;
    opacity: 0;
    z-index: 1;
    &.open {
      max-height: 1000px;
      opacity: 1;
    }
    .suggestion {
      padding: 10px 20px;
      font-weight: 300;
      cursor: pointer;
      user-select: none;
      transition:
        0.5s easy,
        color,
        background-color;
      color: $darkgray;
      font-size: 14px;
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      &:hover {
        background-color: $lightgray;
        color: $pink;
      }
    }
  }
  .title {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: $dark;
    margin-bottom: 40px;
  }
  .modal-select {
    width: calc(100% + 80px);
    margin-top: 40px;
    margin-left: -40px;
    margin-bottom: 40px;
    min-height: 120px;
    background-color: $lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    &-input {
      width: 100%;
    }
    .check-select-wrapper {
      width: 100%;
      height: 120px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      .check-select {
        margin-left: 20px;
      }
    }
  }
  .time {
    width: calc(100% + 40px);
    min-height: 80px;
    background-color: $lightgray;
    margin-top: 10px;
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include bp-500 {
      flex-direction: column;
    }
    &-title {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: $dark;
      margin-left: 20px;
      @include bp-500 {
        font-size: 14px;
        flex-basis: 30%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .input-container {
      display: flex;
      align-items: center;
      @include bp-500 {
        flex-direction: column;
      }
      .error-span {
        display: flex;
        justify-content: center;
        margin-top: 3px;
      }
    }
    &-inputs {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        margin-bottom: 0;
      }
      @include bp-500 {
        text-align: center;
      }
      .input {
        width: 80px;
        margin: 0 10px;
        border: 1px solid #c4c4c4;
        @include bp-500 {
          font-size: 13px;
          height: 36px;
          margin: 0 4px;
          padding-left: 2px;
        }
      }
    }
  }
  .closed-switch {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .modal-submit {
    width: 100%;
    height: 60px;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
.timeFailure {
  background-color: red;
}
.capitalize select {
  text-transform: capitalize !important;
}
</style>
