<template>
  <div>
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      {{ isEditing ? 'Edit finance details' : 'Add finance details' }}
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmit">
      <app-input v-model="xeroName" rules="required" name="Vendor name"> Vendor name (for Xero)* </app-input>
      <app-input v-model="starlingName" rules="required" name="Business name">
        Business name (for Starling)*
      </app-input>
      <app-input v-model="emails" rules="required" name="List of emails">
        List of emails (separated by semicolons)*
      </app-input>
      <app-input v-model="vatNumber" name="VAT number">VAT number</app-input>
      <div style="display: flex; flex-direction: row; gap: 10px">
        <app-input v-model="deliveryPricingModel" name="Delivery pricing model"> Delivery pricing model* </app-input>
        <app-input v-model="collectionPricingModel" name="Collection pricing model">
          Collection pricing model*
        </app-input>
      </div>
      <div style="display: flex; flex-direction: column; border: 2px solid red; padding: 10px; margin-bottom: 20px">
        <div style="display: flex; flex-direction: row; gap: 10px">
          <app-input v-model="bankAccountNumber" name="Bank account number">Bank account number</app-input>
          <app-input v-model="sortCode" name="Sort code">Sort code</app-input>
        </div>
        <app-input v-model="encryptionKey" name="Password" type="password" autocomplete="new-password">
          Password
        </app-input>
      </div>

      <button v-if="!loading" type="submit" class="btn-pink" :class="{ 'btn-disabled': invalid }">
        {{ isEditing ? 'Edit' : 'Add' }}
      </button>
    </ValidationObserver>

    <button v-if="loading" class="btn-white">
      <app-loader />
    </button>
  </div>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import CryptoJS from 'crypto-js';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({ directives: { mask } })
export default class ModalFinanceDetails extends Vue {
  @Prop() data: any;

  public xeroName: string = '';
  public starlingName: string = '';
  public emails: string = '';
  public vatNumber: string = '';
  public bankAccountNumber: string = '';
  public sortCode = '';
  public deliveryPricingModel: string = (0.0).toString();
  public collectionPricingModel: string = (0.0).toString();
  public encryptionKey: string = '';

  public modalHub = ModalHub;
  public isEditing = false;
  public loading = false;

  public mounted() {
    const { vendor } = this.data;
    if (vendor?.financeDetails) {
      this.xeroName = vendor.financeDetails.xeroName;
      this.starlingName = vendor.financeDetails.starlingName;
      this.emails = vendor.financeDetails.emails;
      this.vatNumber = vendor.financeDetails.vatNumber || '';
      this.deliveryPricingModel = (vendor.financeDetails.deliveryPricingModel || 0.0).toString();
      this.collectionPricingModel = (vendor.financeDetails.collectionPricingModel || 0.0).toString();
      this.isEditing = true;
    }
  }

  public onSubmit() {
    const coreData = {
      xeroName: this.xeroName,
      starlingName: this.starlingName,
      emails: this.emails,
      vatNumber: this.vatNumber || null,
      deliveryPricingModel: parseFloat(this.deliveryPricingModel),
      collectionPricingModel: parseFloat(this.collectionPricingModel),
    };

    this.loading = true;
    http
      .post(`/admin/payouts`, {
        vendorId: this.data.vendor.id,
        bankAccountNumber:
          this.bankAccountNumber && this.sortCode && this.encryptionKey
            ? CryptoJS.AES.encrypt(this.bankAccountNumber, this.encryptionKey).toString()
            : null,
        sortCode:
          this.bankAccountNumber && this.sortCode && this.encryptionKey
            ? CryptoJS.AES.encrypt(this.sortCode, this.encryptionKey).toString()
            : null,
        ...coreData,
      })
      .then(() => {
        this.data.vendor.financeDetails = { ...coreData };
        this.modalHub.$emit('close');
      })
      // eslint-disable-next-line
      .catch((e) => console.log(e))
      .finally(() => (this.loading = false));
  }
}
</script>

<style lang="scss" scoped>
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.btn-pink {
  width: 100%;
}
.btn-disabled {
  opacity: 0.2;
}
.btn-white {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: 0.5px solid black;
}
.btn-white:hover {
  background-color: $white;
}
</style>
