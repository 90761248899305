<template>
  <div tabindex="0" class="popover" @focusout="open = false">
    <span class="popover-icon" @click.stop="open = !open">
      <app-icon name="actions" />
    </span>
    <div :class="{ open }" class="popover-dropdown" @click.stop="">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AppPopover extends Vue {
  public open = false;
}
</script>

<style lang="scss" scoped>
.popover {
  &-icon {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
  }
  &-dropdown {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
    position: absolute;
    right: 20px;
    top: 25px;
    min-width: 120px;
    padding: 10px 0;
    background-color: $white;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    div {
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
      transition: 0.5s all;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $lightgray;
        color: $pink;
        .icon svg * {
          fill: $pink;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        width: 20px;
        margin-right: 5px;
        svg * {
          transition: 0.5s all;
        }
      }
    }
    &.open {
      opacity: 1;
      pointer-events: initial;
    }
  }
}
</style>
