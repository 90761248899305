<template>
  <div class="stripe-card">
    <div id="stripe-card-element"></div>
    <div v-if="error" class="stripe-card-error">{{ error }}</div>
  </div>
</template>

<script lang="ts">
import { StripeCardElement } from '@stripe/stripe-js';
import { Component, Vue } from 'vue-property-decorator';

import stripeInstance from '@/_shared/lib/stripe-custom-elements';

@Component({})
export default class Stripe extends Vue {
  public error = '';

  mounted() {
    stripeInstance.create().then(() => {
      if (stripeInstance.elements) {
        const cardelement: StripeCardElement = stripeInstance.elements.getElement('card') as StripeCardElement;
        cardelement.on('change', (event: any) => {
          if (event.error) {
            this.error = event.error.message;
          } else {
            this.error = '';
          }
        });
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.stripe-card {
  margin-top: 5px;
  margin-bottom: 30px;
}
.StripeElement {
  padding: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background-color: white;

  &--focus {
    border: 1px solid $ham;
    outline: 2px solid $ham;
  }
  &--invalid {
    border: 1px solid #f00;
    outline: 2px solid #f00;
  }
}
.stripe-card-error {
  color: #f00;
  font-size: 13px;
  margin-top: 5px;
}
</style>
