var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'accordion',
    { 'accordion-nested': _vm.nested },
    { open: _vm.open && _vm.$slots.body },
    { empty: !_vm.$slots.body },
    { logisticStyles: _vm.isLogistic },
  ]},[_c('div',{staticClass:"accordion-head",class:[{ logisticHeadStyles: _vm.isLogistic }, { 'slim-head': _vm.slim }],on:{"click":_vm.toggleOpen}},[(_vm.$slots.body)?_c('app-icon',{staticClass:"accordion-icon",attrs:{"name":"dropdown"}}):_vm._e(),_vm._t("head")],2),(_vm.$slots.body && _vm.open)?_c('div',{staticClass:"accordion-body",class:{ slim: _vm.slim }},[_vm._t("body")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }