<template>
  <div class="modal-republish-success-wrapper">
    <h1>Success!</h1>
    <div class="cloud"><app-icon name="ok" class="check-cloud"></app-icon></div>
    <h4>You're all good to go!</h4>
    <h4>We've re-published your account and you are ready to start taking orders again.</h4>
    <input type="submit" class="btn-pink success-btn" value="Let's go!" @click="close" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({
  components: {},
})
export default class RepublishSuccess extends Vue {
  public modalHub = ModalHub;

  public close() {
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
  }
}
</script>

<style lang="scss" scoped>
.modal-republish-success-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 345px !important;
  height: 470px !important;

  .cloud {
    position: relative;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    line-height: normal;
    background-color: transparent;
    background-image: url('../../_assets/decor/green-cloud.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .check-cloud {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
  }
  h1 {
    font-family: Montserrat;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    padding-top: 20px;
  }
  h4 {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    line-height: 21px;
  }

  .success-btn {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}
</style>
