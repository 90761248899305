<template>
  <ValidationObserver
    id="add-vendor-form"
    v-slot="{ invalid }"
    ref="form"
    class="modal-add-vendor"
    tag="form"
    @submit.prevent="addVendor"
  >
    <div class="columns is-gapless modal-header">
      <div class="column is-3 close-modal-wrapper">
        <router-link to="/vendors" class="close-modal">
          <app-icon name="close"></app-icon>
        </router-link>
      </div>
      <div class="column is-6 modal-header-title">
        <p>Create vendor</p>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-3">
            <div class="modal-input">
              <app-input v-model="form.vendor.name" rules="required" name="Name">Vendor name*</app-input>
            </div>
            <div class="modal-input">
              <app-input v-model="form.vendor.companyName" name="CompanyName">Vendor company name</app-input>
            </div>
            <div v-if="isSuperAdmin" class="modal-input">
              <app-input v-model="form.vendor.shortDescription" name="ShortDescription"
                >Vendor short description</app-input
              >
            </div>
            <div class="modal-input">
              <app-input v-model="form.vendor.contactEmail" rules="required|email" name="Email"
                >Vendor contact email*</app-input
              >
            </div>
            <div class="modal-input">
              <app-input v-model="form.vendor.phone" name="Phone">Vendor phone number</app-input>
            </div>
            <div class="pictures">
              <p class="picture-title">Vendor logo*</p>
              <app-file v-model="form.vendor.logo" rules="required" name="Logo" @input="addLogo($event)">
                <div v-if="previewLogo" class="vendor-logo">
                  <span @click.stop.prevent="removeLogo">
                    <app-icon name="remove-red-circle" />
                  </span>
                  <img :src="previewLogo" alt="" />
                </div>
                <div v-else class="picture-holder">
                  <span class="picture-holder-add">
                    <app-icon name="add-small"></app-icon>
                  </span>
                  <div class="picture-holder-parameters">
                    <p>Recommended</p>
                    <p>980x980 px</p>
                  </div>
                </div>
              </app-file>
            </div>
            <div class="modal-switcher">
              <p>Is published?</p>
              <app-switcher v-model="form.vendor.isPublished" :flash="true" prefix="No" postfix="Yes"></app-switcher>
            </div>
            <div class="modal-switcher">
              <p>Remind about new order?</p>
              <app-switcher
                v-model="form.vendor.isFlashReminder"
                :flash="true"
                prefix="No"
                postfix="Yes"
              ></app-switcher>
            </div>
            <div class="modal-switcher">
              <p>Registered for VAT?</p>
              <app-switcher v-model="form.vendor.isVatRegistered" :flash="true" prefix="No" postfix="Yes">
              </app-switcher>
            </div>
          </div>
          <div class="column is-9">
            <div class="pictures">
              <p class="picture-title">Vendor cover picture*</p>
              <app-file v-model="form.vendor.cover" rules="required" name="Cover" @input="addCover($event)">
                <div v-if="previewCover" class="vendor-cover">
                  <span @click.stop.prevent="removeCover">
                    <app-icon name="remove-red-circle" />
                  </span>
                  <img :src="previewCover" alt="" />
                </div>
                <div v-else class="picture-holder">
                  <span class="picture-holder-add">
                    <app-icon name="add-small"></app-icon>
                  </span>
                  <div class="picture-holder-parameters">
                    <p>Recommended</p>
                    <p>1920x680 px</p>
                  </div>
                </div>
              </app-file>
            </div>
            <div class="modal-textarea">
              <app-textarea v-model="form.vendor.description" rules="required" name="Information"
                >Vendor information*</app-textarea
              >
            </div>

            <app-accordion>
              <template #head>
                <div class="accordion-title">Restaurants</div>
                <span class="add-icon" @click.stop="openAddRestaurantModal">
                  <app-icon name="add-small" />
                </span>
              </template>
              <template #body>
                <div
                  v-for="(restaurant, index) in restaurants"
                  :key="restaurant.name + index"
                  class="restaurant-example"
                >
                  <div class="restaurant-left">
                    <p class="restaurant-text title">{{ restaurant.name }}</p>
                    <p class="restaurant-text bold location">{{ restaurant.locationName }}</p>
                    <p v-for="(value, name, dIndex) in restaurant.days" :key="name + dIndex" class="restaurant-text">
                      {{ name }} - {{ restaurant.days[name][0] }}-{{ restaurant.days[name][1] }}
                    </p>
                    <div class="categories">
                      <p class="restaurant-text bold">Dietary:</p>
                      <p class="restaurant-text">
                        {{ restaurant.dietariesTitle }}
                      </p>
                    </div>
                    <div class="categories">
                      <p class="restaurant-text bold">Categories:</p>
                      <p class="restaurant-text">
                        {{ restaurant.categoriesTitle }}
                      </p>
                    </div>
                  </div>
                  <div class="restaurant-right">
                    <span @click="deleteRestaurant(index)">
                      <app-icon name="delete" class="trash"></app-icon>
                    </span>
                    <span @click="editRestaurant(index)">
                      <app-icon name="pencil" class="trash"></app-icon>
                    </span>
                  </div>
                </div>
              </template>
            </app-accordion>

            <app-accordion>
              <template #head>
                <div class="accordion-title">Users</div>

                <span class="add-icon" @click.stop="openAddUserModal">
                  <app-icon name="add-small" />
                </span>
              </template>
              <template #body>
                <div v-for="(user, index) in users" :key="user.email + index" class="user-example">
                  <p>{{ user.email }}</p>
                  <div class="user-right-buttons">
                    <span @click="deleteUser(index)">
                      <app-icon name="delete" class="trash"></app-icon>
                    </span>
                  </div>
                </div>
              </template>
            </app-accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <input
        class="btn-pink modal-submit"
        :class="{ 'btn-disabled': invalid }"
        type="submit"
        value="Create"
        form="add-vendor-form"
        :disabled="invalid"
      />
    </div>
    <app-modal-loader v-if="loading" />
  </ValidationObserver>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';
import { toFormData } from '@/_shared/utils/to-form-data';

@Component({
  components: {
    AppLogo,
  },
  directives: {
    mask,
  },
})
export default class AddVendor extends Vue {
  public previewCover = '';
  public previewLogo = '';

  public loading = false;
  public errors = '';
  public $refs!: {
    form: HTMLFormElement;
  };
  public isSuperAdmin = this.$store.state.isSuperAdmin;

  public form: VendorForm = {
    vendor: {
      name: '',
      companyName: null,
      shortDescription: null,
      contactEmail: '',
      phone: null,
      isPublished: false,
      description: '',
      cover: null,
      logo: null,
      isFlashReminder: false,
      isVatRegistered: false,
    },
    users: [],
  };

  public users: any[] = [];
  public restaurants: any[] = [];

  public dayNumbers: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  public addVendor() {
    if (!this.form.vendor.logo || !this.form.vendor.cover) {
      ModalHub.$emit('open', 'modal-error', {
        data: { message: 'Logo and cover picture are required!', missingData: true },
      });
      return;
    }

    this.loading = true;
    const restaurants: any = this.restaurants.map((restaurant: any) => {
      const days = Object.entries(restaurant.days).map((elem: any) => {
        const day = this.dayNumbers[elem[0]];
        return {
          day,
          startTime: elem[1][0],
          endTime: elem[1][1],
          isClosed: elem[1][2],
        };
      });

      return {
        name: restaurant.name,
        number: restaurant.number,
        street: restaurant.street,
        city: restaurant.city,
        postCode: restaurant.postCode,
        categories: restaurant.chosenCategories.map((elem: any) => elem.id),
        dietaries: restaurant.chosenDietaries.map((elem: any) => elem.id),
        budgets: restaurant.chosenBudgets.map((elem: any) => elem.id),
        perfectFors: restaurant.chosenPerfectFors.map((elem: any) => elem.id),
        workingHours: days,
      };
    });
    http
      .post(
        '/admin/vendors',
        toFormData({
          ...this.form.vendor,
          users: this.users,
          restaurants,
        }),
      )
      .then((res: any) => {
        this.loading = false;
        this.$router.push('/vendors');
      })
      .catch((err: any) => {
        this.loading = false;
        const missingData = err.toString().includes('invalid');
        ModalHub.$emit('open', 'modal-error', { data: { message: err, missingData } });
      });
  }

  public addCover(file: any) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewCover = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  get userEmails() {
    return this.users.map((user: any) => user.email);
  }

  public removeCover() {
    this.previewCover = '';
    this.form.vendor.cover = null;
  }

  public addLogo(file: any) {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewLogo = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  public removeLogo() {
    this.previewLogo = '';
    this.form.vendor.logo = null;
  }

  created() {
    ModalHub.$on('addUser', (e: any) => {
      if (!this.userEmails.includes(e.email)) {
        this.users.push(e);
        this.form.users.push(e.email);
      } else {
        ModalHub.$emit('open', 'modal-error', { data: { message: 'User with such email is already in your list' } });
      }
    });

    ModalHub.$on('addRestaurant', (e: any) => {
      this.restaurants.push(e);
    });

    ModalHub.$on('editRestaurant', (e: any) => {
      let restaurant = this.restaurants[e.index];

      restaurant.name = e.name;
      restaurant.street = e.street;
      restaurant.city = e.city;
      restaurant.number = e.number;
      restaurant.postCode = e.postCode;
      restaurant.locationName = e.locationName;
      restaurant.chosenDietaries = e.chosenDietaries;
      restaurant.chosenCategories = e.chosenCategories;
      restaurant.dietaries = e.dietaries;
      restaurant.categories = e.categories;
      restaurant.dietariesTitle = e.dietariesTitle;
      restaurant.categoriesTitle = e.categoriesTitle;
      restaurant.days = e.days;

      this.restaurants[e.index] = restaurant;
    });
  }

  public deleteUser(index: number) {
    this.users.splice(index, 1);
  }

  public deleteRestaurant(index: number) {
    this.restaurants.splice(index, 1);
  }

  public editRestaurant(index: number) {
    const restaurant = this.restaurants[index];
    ModalHub.$emit('open', 'modal-add-restaurant', { animation: 'slide-left', data: { ...restaurant, index } });
  }

  public openAddRestaurantModal() {
    ModalHub.$emit('open', 'modal-add-restaurant', { animation: 'slide-left' });
  }

  public openAddUserModal() {
    ModalHub.$emit('open', 'modal-add-user', { animation: 'slide-left' });
  }
}

interface VendorForm {
  vendor: {
    name: string;
    companyName?: string | null;
    shortDescription?: string | null;
    contactEmail: string;
    phone?: string | null;
    isPublished: boolean;
    description: string;
    cover: any;
    logo: any;
    isFlashReminder: boolean;
    isVatRegistered: boolean;
  };
  users: any;
}
</script>

<style lang="scss" scoped>
.modal-add-vendor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $lightgray;
  overflow-y: auto;
  padding: 0;
  &::-webkit-scrollbar {
    width: 0;
  }
  .modal-header {
    height: 100px;
    width: 100%;
    border-bottom: 1px solid $gray;
    background-color: $white;

    .close-modal-wrapper {
      display: flex;
      align-items: center;

      .close-modal {
        height: 20px;
        width: 20px;
        margin-left: 40px;
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .container-wrapper {
    border-bottom: 1px solid $gray;
  }
}
.pictures {
  height: 340px;
  background-color: $white;
  margin-bottom: 20px;
  padding: 20px 20px;

  .picture-title {
    margin-bottom: 20px;
  }
  .vendor-logo {
    width: 256px;
    height: 256px;
    margin: auto;
    position: relative;
    span {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      user-select: none;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
  }
  .vendor-cover {
    width: 890px;
    height: 256px;
    margin: auto;
    position: relative;
    span {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      user-select: none;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
    }
  }
}
.accordion-title {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: $dark;
  margin-left: 20px;
}
.add-icon {
  margin-left: auto;
  margin-right: 20px;
}
.user-example {
  width: 100%;
  height: 80px;
  background-color: $lightgray;
  margin-top: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: $dark;
  }
  .trash {
    margin-right: 20px;
  }
}
.restaurant-example {
  width: 100%;
  min-height: 190px;
  position: relative;
  background-color: $lightgray;
  margin-top: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .restaurant-left {
    margin-left: 10px;
    .restaurant-text {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $dark;

      &.bold {
        font-weight: 600;
      }
      &.location {
        margin-bottom: 10px;
      }
      &.title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .categories {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      .restaurant-text {
        &.bold {
          margin-right: 5px;
        }
      }
    }
  }
  .restaurant-right {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 30px;
    .trash {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
.modal-input {
  height: 126px;
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  margin-bottom: 20px;
}
.modal-input > * {
  width: 100%;
}
.modal-switcher {
  height: 105px;
  margin-bottom: 40px;
  padding: 20px 20px;
  background-color: $white;
  p {
    margin-bottom: 20px;
  }
}
.modal-textarea {
  background-color: $white;
  padding: 20px 20px;
  margin-bottom: 20px;
}
.modal-submit {
  width: 256px;
  margin-top: 40px;
  margin-left: 20px;
  margin-bottom: 60px;
}
.prep-submit {
  display: block;
  width: 80%;
  margin: 40px auto;
}
.btn-disabled {
  opacity: 0.2;
}
.time {
  height: 80px;
  background-color: $lightgray;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &-title {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: $dark;
    margin-left: 20px;
    @include bp-500 {
      font-size: 14px;
    }
  }
  &-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    .label {
      margin-bottom: 0;
    }
    .input {
      max-width: 72px;
      margin: 0 10px;
      border: 1px solid #c4c4c4;
      padding-left: 9px;

      @include bp-500 {
        font-size: 13px;
        max-width: 36px;
        height: 36px;
        margin: 0 4px;
      }
    }
  }
}
.prep-definition {
  font-size: 12px;
}
</style>
