<template>
  <div class="time-inputs">
    <input
      v-model="hours.start"
      v-mask="'##:##'"
      type="text"
      class="input"
      :class="{ 'input-error': startHoursError }"
      placeholder="10:00"
      @input="onChange('start')"
    />

    <span style="margin: 0 0.5rem"> &#8212; </span>

    <input
      v-model="hours.end"
      v-mask="'##:##'"
      type="text"
      class="input"
      :class="{ 'input-error': endHoursError }"
      placeholder="18:00"
      @input="onChange('end')"
    />

    <app-select v-model="hours.day" :options="dayOptions" select-title="Choose day" @input="onDayInputChange" />

    <button
      class="btn-pink"
      :class="[
        { 'btn-disabled': loading || applied || startHoursError || endHoursError || !hours.day },
        { 'btn-applied': applied },
      ]"
      :disabled="loading || applied || startHoursError || endHoursError || !hours.day"
      type="submit"
      @click.prevent="applyDeliveryHours(zone, hours)"
    >
      {{ applied ? 'Applied' : 'Apply' }}
    </button>

    <button
      class="btn-delete"
      :class="{ 'btn-disabled': loading }"
      :disabled="loading"
      type="submit"
      @click.prevent="removeDeliveryHours(zone, hours)"
    >
      Remove
    </button>

    <span v-if="endHoursError || startHoursError" class="error-message">Invalid time format.</span>
    <span v-if="error" class="error-message">{{ error }}</span>
    <div v-if="loading" class="loader"></div>
  </div>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { DeliveryHours, Zone } from '@/_types/shared.types';

@Component({
  props: {
    zone: {
      type: Object,
      required: true,
    },
    hours: {
      type: Object,
      required: true,
    },
  },
  directives: {
    mask,
  },
})
export default class FoodstuffDeliveryHours extends Vue {
  dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  private zone = this.$props.zone;
  private hours = this.$props.hours;

  private loading = false;
  private applied = !!this.$props.hours.id;

  private endHoursError = false;
  private startHoursError = false;
  private error = '';

  private async applyDeliveryHours(zone: Zone, hours: DeliveryHours) {
    this.loading = true;

    const day = this.days.indexOf(hours.day as string);

    if (day === -1) {
      this.loading = false;
      this.error = 'Please select a day.';
      return;
    }

    await http
      .post(`/admin/logistics/zone/${zone.id}/delivery-hours`, {
        id: hours.id,
        day: day,
        start: hours.start,
        end: hours.end,
      })
      .then((result: DeliveryHours) => {
        // For newly created hours, assign an id returned from backend
        if (!hours.id) this.$props.hours.id = result.id;

        this.applied = true;
      })
      .catch((err: unknown) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async removeDeliveryHours(zone: Zone, hours: DeliveryHours) {
    // if not applied - just splice from delivery hours array
    if (!hours.id) {
      this.zone.deliveryHours.splice(this.zone.deliveryHours.indexOf(hours), 1);
    } else {
      this.loading = true;

      http
        .delete(`/admin/logistics/zone/${zone.id}/delivery-hours/${hours.id}`)
        .then(() => {
          this.zone.deliveryHours.splice(this.zone.deliveryHours.indexOf(hours), 1);
        })
        .catch((err: unknown) => {
          ModalHub.$emit('open', 'modal-error', { data: { message: err } });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private validateTimeInterval(interval: string) {
    if (interval.length < 5) return false;

    const [hh, mm] = interval.split(':');

    const hours = parseInt(hh);
    const minutes = parseInt(mm);

    if (isNaN(hours) || isNaN(minutes)) return false;

    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
  }

  private onChange(interval: 'start' | 'end') {
    this.applied = false;

    if (interval === 'start') {
      if (!this.validateTimeInterval(this.hours.start)) this.startHoursError = true;
      else this.startHoursError = false;
    } else if (interval === 'end') {
      if (!this.validateTimeInterval(this.hours.end)) this.endHoursError = true;
      else this.endHoursError = false;
    }
  }

  private onDayInputChange(value: string) {
    this.error = '';
    this.applied = false;
  }

  mounted() {
    this.applied = !!this.$props.hours.id;
  }
}
</script>

<style scoped lang="scss">
.time-inputs {
  margin: 1em 0;
  display: flex;
  align-items: center;

  .input-container {
    display: flex;
    flex-direction: column;
  }
  .input {
    padding: 5px;
    text-align: center;
    width: 70px;

    &:focus:not([disabled]):not([readonly]):not(.is-error) {
      padding: 5px;
    }
  }
  .select {
    width: 150px;
    margin-left: 1rem;
  }
  .btn-pink {
    margin-left: 1rem;
    width: 100px;
    font-weight: normal;
  }

  .btn-disabled {
    cursor: default;
    opacity: 0.5;
  }
  .btn-applied {
    cursor: default;
    background-color: $lightgreen;
  }
  .btn-delete {
    border: none;
    margin-left: 0.5rem;
    height: 50px;
    padding: 0 15px;
    font-family: Montserrat, sans-serif;
    border-radius: 15px;
    color: white;
    background-color: #a4a4a4;
    transition: all 0.5s ease;

    &:not(.btn-disabled) {
      cursor: pointer;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.loader {
  margin-left: 0.5rem;
  width: 25px;
  height: 25px;
  border-top: solid 1.5px $pink;
  border-left: solid 1.5px $pink;
  border-radius: 50%;
  animation: rotate 0.3s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.input-error {
  border: solid 2px red;
  &:focus {
    border: solid 2px red !important;
  }
  &:active {
    border: solid 2px red !important;
  }
}
.error-message {
  color: red;
  margin-left: 0.75rem;
  font-weight: 500;
  font-size: 0.9em;
}
</style>
