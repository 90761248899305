<template>
  <div class="dishes">
    <div v-if="categories.length">
      <div v-for="c of categories" :key="c.slug" class="category">
        <app-accordion>
          <template #head>
            <div class="category-name">{{ c.name }}</div>
            <router-link :to="`dishes/${c.id}/add`" tag="span" class="add-icon">
              <app-icon name="add-small" @click.stop="" />
            </router-link>
            <app-popover>
              <div @click="deleteDishCategory(c)"><app-icon name="delete" /> Delete</div>
              <div @click="openModalEditCategory(c)"><app-icon name="pencil" /> Edit</div>
            </app-popover>
          </template>
          <template #body>
            <router-link
              v-for="d of c.dishes"
              :key="d.slug"
              :to="`dishes/${c.id}/edit/${d.slug}`"
              tag="div"
              class="dish"
            >
              <div class="dish-photo">
                <img
                  v-if="d.photo && d.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg'"
                  :src="d.photo"
                  :alt="d.name"
                />
              </div>
              <p class="dish-name">{{ d.name }}</p>
              <p class="dish-price">£{{ d.price.toFixed(2) }}</p>
              <app-popover @click.prevent="">
                <div @click="deleteDish(d)"><app-icon name="delete" /> Delete</div>
                <router-link :to="`dishes/${c.id}/edit/${d.slug}`" tag="div">
                  <div><app-icon name="pencil" /> Edit</div>
                </router-link>
              </app-popover>
            </router-link>
          </template>
        </app-accordion>
      </div>
    </div>

    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span>
        Click the
        <i class="is-pink cup" @click="openModalAddCategory">+</i>
        to add new dish
      </span>
    </div>

    <span class="app-plus-icon" @click="openModalAddCategory">
      <app-icon name="add" />
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({})
export default class Dishes extends Vue {
  public categories: IDishCategory[] = [];

  public mounted() {
    this.getCategories();
  }

  public deleteDishCategory(category: IDishCategory) {
    http.delete(`/admin/menu/${this.vendorSlug}/dish-categories/${category.id}`).then((res: { success: boolean }) => {
      if (res.success) {
        this.categories.splice(this.categories.indexOf(category), 1);
      }
    });
  }

  public deleteDish(dish: IDish) {
    http.delete(`/admin/menu/${this.vendorSlug}/dishes/${dish.slug}`).then((res: { success: boolean }) => {
      for (let category of this.categories) {
        category.dishes.splice(category.dishes.indexOf(dish), 1);
      }
    });
  }

  public openModalEditCategory(category: IDishCategory) {
    ModalHub.$emit('open', 'modal-add-category', {
      animation: 'slide-left',
      data: { category },
    });
  }

  public openModalAddCategory() {
    ModalHub.$emit('open', 'modal-add-category', { animation: 'slide-left' });
  }

  private getCategories() {
    http.get(`/admin/menu/${this.vendorSlug}/dish-categories`).then((res: { dishCategories: IDishCategory[] }) => {
      this.categories = res.dishCategories;
    });
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}

export interface IDishCategory {
  id: number;
  name: string;
  photo: string;
  dishes: IDish[];
  open?: boolean;
}

interface IDish {
  id: number;
  name: string;
  price: number;
  checked?: boolean;
  slug: string;
}
</script>

<style lang="scss" scoped>
.dishes {
  padding: 20px 0;
}
.category {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
  }
  .add-icon {
    margin-left: auto;
    margin-right: 20px;
  }
  .popover {
    color: $dark;
  }
}
.dish {
  background-color: $lightgray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-photo {
    height: 60px;
    width: 60px;
    margin: -20px 20px -20px 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
  .popover {
    color: $dark;
    margin-left: auto;
  }
}
</style>
