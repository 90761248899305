import Vue from 'vue';

import AppAccordion from '@/_shared/components/app-accordion.vue';
import AppHeader from '@/_shared/components/app-header.vue';
import AppLoader from '@/_shared/components/app-loader.vue';
import AppModalLoader from '@/_shared/components/app-modal-loader.vue';
import AppNavBar from '@/_shared/components/app-nav-bar.vue';
import AppPopover from '@/_shared/components/app-popover.vue';
import OrderRow from '@/_shared/components/order-row.vue';
import ModalView from '@/_shared/modals/_modal-view.vue';

Vue.component('app-header', AppHeader);
Vue.component('modal-view', ModalView);
Vue.component('app-nav-bar', AppNavBar);
Vue.component('app-accordion', AppAccordion);
Vue.component('app-popover', AppPopover);
Vue.component('app-loader', AppLoader);
Vue.component('app-modal-loader', AppModalLoader);
Vue.component('order-row', OrderRow);
