<template>
  <div class="modal-subscription-created-wrapper">
    <h1>Success</h1>
    <div class="cloud"><app-icon name="ok" class="check-cloud"></app-icon></div>
    <h2>Welcome to Foodstuff</h2>
    <h4>Please head over to your account to get started.</h4>
    <input type="submit" class="btn-ham succes-confirm" value="Let's go" @click="close" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({
  components: {},
})
export default class SubscriptionCreated extends Vue {
  public modalHub = ModalHub;

  public close() {
    this.$router.push('/vendors');
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
  }
}
</script>

<style lang="scss" scoped>
.modal-subscription-created-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fffcf7 !important;
  padding: 20px !important;
  border-radius: 15px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 345px !important;
  height: 470px !important;

  .cloud {
    position: relative;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    line-height: normal;
    background-color: transparent;
    background-image: url('../../_assets/decor/cloud.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .check-cloud {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
  }

  h1 {
    font-family: Recoleta;
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    @include bp-650 {
      font-size: 50px;
    }
    @include bp-500 {
      font-size: 40px;
    }
  }
  h2 {
    font-family: Recoleta;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
  }
  h4 {
    font-family: $Sofia;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }
  .succes-confirm {
    justify-self: flex-end;
    font-family: $Sofia;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }
}
</style>
