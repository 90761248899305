<template>
  <div>
    <div class="container">
      <div v-if="coupons.length > 0" class="coupons">
        <div v-if="isSuperAdmin">
          <div class="coupon">
            <span class="name">Name</span>
            <span>Availability</span>
            <span>Usage</span>
            <span>Type</span>
            <span>Discount</span>
            <span class="long">Restaurants</span>
            <span class="long">Order types</span>
            <span>For new users</span>
            <span>Limit per user</span>
            <span>Min cart</span>
            <span>Max total</span>
            <span style="width: 20px; min-width: 20px"></span>
          </div>
          <ul>
            <li v-for="coupon in coupons" :key="coupon.id" class="coupon">
              <span class="name">
                {{ coupon.name }}
              </span>
              <span>
                <small> {{ coupon.start }}<br />-<br />{{ coupon.end }} </small>
              </span>
              <span>
                <small> {{ coupon.used }} / {{ coupon.usingLimit }} </small>
              </span>
              <span>
                <small>
                  {{ coupon.type.replace('_', ' ') }}
                </small>
              </span>
              <span>
                <small>
                  {{
                    coupon.type === 'free_delivery'
                      ? '-'
                      : coupon.type === 'percent'
                        ? `${coupon.discount}%`
                        : coupon.discount
                  }}
                </small>
              </span>
              <span class="long">
                <small>
                  {{ getRestaurantsName(coupon) }}
                </small>
              </span>
              <span class="long">
                <small>
                  {{ getOrderTypes(coupon) }}
                </small>
              </span>
              <span>
                <small>
                  {{ coupon.isForNewUsers ? 'yes' : 'no' }}
                </small>
              </span>
              <span>
                <small>
                  {{ coupon.limitPerUser }}
                </small>
              </span>
              <span>
                <small>
                  {{ coupon.minimumCartPrice || '-' }}
                </small>
              </span>
              <span>
                <small>
                  {{ coupon.maxTotal || '-' }}
                </small>
              </span>
              <span style="width: 20px; min-width: 20px">
                <app-popover>
                  <div @click="deleteCouponHandler(coupon.id)">
                    <app-icon name="delete" />
                    Delete
                  </div>
                </app-popover>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="empty-holder">
        <app-icon name="empty" />
        <span>Empty here</span>
      </div>
    </div>

    <span class="add-icon" @click.stop="openAddCouponModal">
      <app-icon name="add" />
    </span>
    <router-view />
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({})
export default class CouponsList extends Vue {
  coupons: any = [];
  public isSuperAdmin: boolean = this.$store.state.isSuperAdmin;

  public beforeDestroy() {
    ModalHub.$off('setupDeliveryEmail');
  }

  async mounted() {
    this.getCoupons();

    ModalHub.$on('on-confirm', (id: number) => {
      this.deleteCoupon(id);
    });
    ModalHub.$on('setupCoupon', (res: any) => {
      this.coupons.push({
        ...res.data,
        start: couponDate(res.data.start),
        end: couponDate(res.data.end),
      });
    });
  }

  private getCoupons() {
    http.get(`/admin/coupon`).then((response: any) => {
      response.coupons.forEach((el: any) => {
        this.coupons.push({
          ...el,
          start: couponDate(el.start),
          end: couponDate(el.end),
        });
      });
    });
  }

  public deleteCouponHandler(id: number) {
    ModalHub.$emit('open', 'modal-are-you-sure', {
      data: {
        title: 'you want to delete this coupon?',
        text: 'This action cannot be undone.',
        variable: id,
      },
    });
  }

  private getRestaurantsName(coupon: any) {
    if (coupon.restaurants.length === 0) {
      return 'all';
    }
    return coupon.restaurants.map((el: any) => el.name).join(', ');
  }

  private getOrderTypes(coupon: any) {
    if (coupon.orderTypes.length === 0) {
      return 'all';
    }
    return coupon.orderTypes.join(', ');
  }

  public openAddCouponModal() {
    ModalHub.$emit('open', 'modal-add-coupon', { animation: 'slide-left' });
  }

  public async deleteCoupon(id: number) {
    await http.delete(`/admin/coupon/${id}`).then((response: any) => {
      this.coupons = this.coupons.filter((coupon: { id: number }) => coupon.id !== id);
    });
  }
}
const couponDate = (date: Date) => {
  return moment(date).tz('Europe/London').format('YYYY-MM-DD');
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 256px;
}
.coupons {
  padding-bottom: 120px;
  overflow-x: auto;
  span {
    margin-bottom: auto;
    margin-top: auto;
    text-align: left;
  }
  small {
    font-size: 12px;
  }
  .coupon {
    text-align: center;
    position: relative;
    min-height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $white;
    cursor: pointer;
    user-select: none;
    padding: 0 20px;
    min-width: 1200px;

    span {
      width: 80px;
      min-width: 80px;
      max-width: 100px;
      padding: 0 0.3em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .long {
      min-width: 140px;
      width: 140px;
    }
    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 50%;
      }
      p {
        margin-left: 10px;
        color: $dark;
        transition: 0.5s all;
        font-family: $Montserrat;
        font-size: 16px;
        line-height: 140%;
        font-weight: 600;
      }
    }
    &:hover {
      p {
        color: $pink;
      }
    }
    &-publish {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .popover {
      margin-top: auto;
      margin-bottom: auto;
    }
    .name {
      padding-left: 0;
      user-select: all;
      overflow: hidden;
      word-break: break-word;
      width: 200px;
      &:hover {
        overflow: visible;
        color: pink;
      }
    }
  }
}
.addDelivery {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
.add-icon {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
</style>
