<template>
  <div class="modal-select-coupon-restaurants">
    <div class="columns is-gapless modal-header">
      <div class="column is-3 close-modal-wrapper" @click="closeAddCoupon()">
        <router-link to="/coupons" class="close-modal">
          <app-icon name="close"></app-icon>
        </router-link>
      </div>
      <div class="column is-6 modal-header-title">
        <p>Select restaurants</p>
      </div>
      <div class="column is-3"></div>
    </div>
    <app-input
      v-model="searchField"
      class="search-input"
      placeholder="Search category"
      @input="searchRestaurants"
    ></app-input>
    <div class="modal-content">
      <app-checkbox
        v-for="restaurant in restaurants"
        :key="restaurant.id"
        :value="localSelectedRestaurants.findIndex((item) => item.id === restaurant.id) !== -1"
        :disabled="false"
        textOnTheLeft="true"
        big="true"
        @input="(event) => handleRestaurantClick(event, restaurant)"
        >{{ restaurant.name }}</app-checkbox
      >
    </div>
    <div class="bottom-container">
      <button
        class="btn-pink modal-submit"
        value="Save"
        @click="
          (e) => {
            e.preventDefault();
            saveSelectedRestaurants(localSelectedRestaurants);
          }
        "
      >
        Save
      </button>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import http from '@/_shared/utils/http';

@Component({
  components: {
    AppLogo,
  },
  directives: {
    mask,
  },
  props: {
    selectedRestaurants: Array,
    saveSelectedRestaurants: Function,
    onClose: Function,
  },
})
export default class ModalSelectCouponRestaurants extends Vue {
  $props!: {
    selectedRestaurants: IRestaurant[];
    saveSelectedRestaurants: Function;
    onClose: Function;
  };

  private initialRestaurants: IRestaurant[] = [];
  private restaurants: IRestaurant[] = [];
  private localSelectedRestaurants: IRestaurant[] = [];
  private searchField = '';
  private loading = false;

  searchRestaurants() {
    if (this.searchField.length <= 0) {
      this.restaurants = this.initialRestaurants;
    } else {
      this.restaurants = this.initialRestaurants.filter((rest) =>
        rest.name.toLowerCase().includes(this.searchField.toLowerCase()),
      );
    }
  }

  closeAddCoupon() {
    this.$props.onClose();
  }

  handleRestaurantClick(value: boolean, restaurant: IRestaurant) {
    const indx = this.localSelectedRestaurants.findIndex((rest: IRestaurant) => rest.id === restaurant.id);
    if (indx >= 0) {
      this.localSelectedRestaurants.splice(indx, 1);
    } else {
      this.localSelectedRestaurants.push(restaurant);
    }
  }

  created() {
    this.localSelectedRestaurants = [];
    this.searchField = '';
    this.loading = true;
    http
      .get('/restaurants')
      .then((res: any) => {
        this.restaurants = res.restaurants;
        this.initialRestaurants = res.restaurants;
        this.$props.selectedRestaurants.forEach((rest: IRestaurant) => {
          if (this.initialRestaurants.findIndex((r) => r.id === rest.id) >= 0) {
            this.localSelectedRestaurants.push(rest);
          }
        });
      })
      .catch((err: any) => {
        // eslint-disable-next-line
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

interface IRestaurant {
  id: number;
  name: string;
}
</script>

<style lang="scss" scoped>
.modal-select-coupon-restaurants {
  position: fixed;
  right: 1px;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 0;
  min-width: 740px;
  width: 45%;
  z-index: 3;
  top: 0;
  height: 100vh;
  @include bp-500 {
    min-width: 345px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .modal-header {
    height: 100px;
    width: 100%;
    border-bottom: 1px solid $gray;
    background-color: $white;
    margin-bottom: 0;

    .close-modal-wrapper {
      display: flex;
      align-items: center;

      .close-modal {
        height: 20px;
        width: 20px;
        margin-left: 40px;
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .search-input {
    margin: 0 40px 20px;
  }
  .modal-content {
    width: 100%;
    background-color: $white;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 30px;
    padding-top: 10px;
    overflow-y: scroll;
    height: 60vh;
  }
  .modal-input > * {
    width: 100%;
  }
  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-submit {
      width: 80%;
    }
  }
  .btn-disabled {
    opacity: 0.2;
  }
  .-ml-10 {
    margin-left: -10px;
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 400;
    font-family: Sofia, sans-serif;
  }
}
</style>
