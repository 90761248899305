<template>
  <div class="wrapper">
    <app-nav-bar>
      <li>
        <router-link to="/vendors" tag="a">All Vendors</router-link>
      </li>
    </app-nav-bar>

    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { AuthService } from '@/auth/auth-service';

@Component({})
export default class Vendors extends Vue {
  public isSuperAdmin: boolean = false;

  async mounted() {
    this.isSuperAdmin = await AuthService.isSuperAdmin();
    this.$store.commit('isSuperAdmin', this.isSuperAdmin);
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 40px;
}
</style>
