<template>
  <div v-if="vendor">
    <div class="vendor-head">
      <div class="container">
        <div class="vendor">
          <div class="vendor-logo">
            <img :src="vendor.logo" alt="Ugly Dumpling" />
          </div>
          <div class="vendor-info">
            <p class="vendor-name">{{ vendor.name }}</p>
            <p class="vendor-status" :class="{ published: vendor.isPublished }">
              {{ vendor.isPublished ? 'Published' : 'Unpublished' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-nav-bar class="vendor-nav">
      <li>
        <router-link :to="`/vendors/${vendorSlug}/dashboard`" tag="a">Dashboard</router-link>
      </li>
      <li>
        <router-link :to="`/vendors/${vendorSlug}/menu`" tag="a">Menu</router-link>
      </li>
      <li>
        <router-link :to="`/vendors/${vendorSlug}/orders`" tag="a">Orders</router-link>
      </li>
      <li>
        <router-link :to="`/vendors/${vendorSlug}/settings`" tag="a">Settings</router-link>
      </li>
      <li>
        <a class="freshbot-btn" @click="toggleOpenChat()">Live chat</a>
      </li>
    </app-nav-bar>
    <router-view @nameEvent="changeName" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import store from '@/store/store';

@Component({
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    try {
      // Check whether user has access to this vendor (is super-admin OR is vendor-admin with active subscription):
      const res: { grantAccess: string[] } = await http.get(`/admin/vendors/${to.params.slug}/permissions-check`);
      if (res.grantAccess[0] !== 'verified') return next();

      // Redirect to Sign-Up flow if ToS have not been confirmed or there is no subscription assigned to this vendor:
      if (!store.state.isTerms || !store.state.stripeId) {
        const { code } = await http.get(`/admin/vendors/${to.params.slug}/invitation/${store.state.user?.email}`);
        return next(`/sign-up?code=${code}`);
      }

      // Show 'Vendor unpublished' modal:
      ModalHub.$emit('open', 'modal-payment-due-unpublished', { animation: 'fade-enter' });
      if (from.path !== '/vendors') next('/vendors');
    } catch (_) {
      if (from.path !== '/vendors') next('/vendors');
    }
  },
})
export default class Vendor extends Vue {
  public vendor: IVendor | { name: '' } = { name: '' };
  public isSuperAdmin: boolean = this.$store.state.isSuperAdmin;

  async mounted() {
    this.getVendor();
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }

  private toggleOpenChat() {
    const { user, vendorSlug } = this.$store.state;
    const { firstName, email } = user;

    window.fcWidget?.user.setProperties({
      // eslint-disable-next-line
      firstName: this.vendor.name || vendorSlug || firstName,
      // eslint-disable-next-line
      email: email,
      externalId: email,
    });
    const isOpen = window.fcWidget?.isOpen();
    // eslint-disable-next-line
    isOpen === false ? window.fcWidget?.open() : window.fcWidget?.close();
  }

  private getVendor() {
    http
      .get(`/admin/vendors/${this.vendorSlug}/short`)
      .then((res: { vendor: IVendor }) => {
        this.$store.commit('stripeId', res.vendor.stripeAccountId);
        this.$store.commit('isTerms', res.vendor.isTermsConfirmed);
        this.$store.commit('vendorSlug', res.vendor.slug);
        this.vendor = res.vendor;
      })
      .catch(() => {
        this.$router.push('/vendors');
      });
  }

  public changeName(e: string) {
    this.vendor.name = e;
  }
}

interface Window {
  Freshbots: any;
}

interface IVendor {
  id: number;
  name: string;
  logo: string;
  isPublished: boolean;
  isTermsConfirmed: boolean;
  stripeAccountId: string | null;
  slug: string;
}
</script>

<style lang="scss" scoped>
.vendor-head {
  background-color: $white;
}
.vendor {
  padding-top: 20px;
  display: flex;
  align-items: center;
  &-logo {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $lightgray;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-name {
    font-size: 16px;
    color: $dark;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &-status {
    color: $red;
    &.published {
      color: $green;
    }
  }
  &-info {
    margin-left: 10px;
  }
  &-nav {
    overflow-x: auto;
  }
}
.vendor-nav a.router-link-active {
  color: $pink;
  border-bottom: 2px solid $pink;
}
.freshbot-btn {
  cursor: pointer;
}
</style>
