<template>
  <div class="forgot-password-modal">
    <ValidationObserver ref="form" tag="form" @submit.prevent="recover">
      <h2>Forgot password</h2>
      <app-input v-model="form.email" rules="email" name="Email" type="text">Enter your email address</app-input>
      <button type="submit" class="btn-pink">Recover</button>
      <p v-if="message" class="recovery-message" :class="{ error: isError }">{{ message }}</p>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { FirebaseError } from '@firebase/util';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({})
export default class PasswordRequest extends Vue {
  public $refs!: {
    form: HTMLFormElement;
  };
  public modalHub = ModalHub;
  private form = { email: '' };
  private isError = false;
  private message = '';

  async recover() {
    let isValid = await this.$refs.form.validate();
    if (!isValid) return;

    try {
      await sendPasswordResetEmail(getAuth(), this.form.email);
    } catch (err) {
      if (err instanceof FirebaseError && err.code === 'auth/user-not-found') {
        http
          .post('/accounts/forgot-password', this.form)
          .then(() => {
            this.isError = false;
            this.message = 'Check your email for recovery password link.';
          })
          .catch((err: any) => {
            this.isError = true;
            this.message = err;
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot-password-modal {
  &.modal-inner {
    width: 350px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: left;
  }
  h2 {
    margin-bottom: 1.2em;
  }
  .app-input {
    margin-bottom: 0.5em;
  }
  .btn-pink {
    display: block;
    width: 100%;
  }
  .recovery-message {
    font-size: 0.75em;
    text-align: center;
    margin-bottom: 0;
    margin-top: 1.2em;
    font-weight: bold;
  }
  .error {
    color: #f00;
  }
}
</style>
