<template>
  <div class="modal-unpublished-wrapper">
    <h1>We've had to unpublish you.</h1>
    <div class="cloud"><app-icon name="x" class="check-cloud"></app-icon></div>
    <h4>Your subscription payment is now past due.</h4>
    <h4>Please retry card or change billing details to update your account.</h4>
    <input type="submit" class="btn-green unpublish-btn" value="Retry your card" @click="retryCard" />
    <input type="submit" class="btn-pink unpublish-btn" value="Change billing details" @click="changeDetails" />
    <template v-if="isFetching">
      <app-modal-loader />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import stripeInstance from '@/_shared/lib/stripe-custom-elements';
import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({})
export default class PaymentDueUnpublished extends Vue {
  public modalHub = ModalHub;
  private isFetching: boolean = false;

  public async retryCard() {
    if (!this.$store.state.stripeId) return;
    this.isFetching = true;

    let result: any;
    try {
      result = await http.post('accounts/vendors/retry-stripe-payment', {
        stripeAccountId: this.$store.state.stripeId,
      });
    } catch (error) {
      if ((error as any).type === 'StripeCardError') {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: (error as any).raw.message,
          },
        });
      } else {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: error,
          },
        });
      }
      this.isFetching = false;
      return;
    }

    const { clientSecret, status } = result;
    if (status === 'succeeded') {
      this.isFetching = false;
      ModalHub.$emit('open', 'modal-republish-success', {
        animation: 'fade-enter',
      });
      return;
    } else if (status === 'requires_action') {
      const { stripe } = stripeInstance;
      if (!stripe) {
        this.isFetching = false;
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: 'Something went wrong',
          },
        });
        return;
      }

      stripe.confirmCardPayment(clientSecret).then((result) => {
        if (result.error) {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-error', {
            data: {
              message: result.error.message,
            },
          });
        } else {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-republish-success', {
            animation: 'fade-enter',
          });
        }
      });
    } else {
      this.isFetching = false;
      ModalHub.$emit('open', 'modal-error', {
        data: {
          message: 'Something went wrong',
        },
      });
    }
  }

  public changeDetails() {
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
    if (this.$store.state.stripeId) {
      ModalHub.$emit('open', 'modal-billing-update', { animation: 'fade-enter' });
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-unpublished-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 345px !important;
  height: 470px !important;

  .cloud {
    position: relative;
    height: 70px;
    width: 70px;
    margin: 0 auto;
    line-height: normal;
    background-color: transparent;
    background-image: url('../../_assets/decor/red-cloud.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .check-cloud {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
  }
  h1 {
    font-family: Montserrat;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    padding-top: 20px;
  }
  h4 {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    line-height: 21px;
  }

  .unpublish-btn {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}
</style>
