import http from '@/_shared/utils/http';

class Auth {
  async isSuperAdmin(): Promise<boolean> {
    return await http.get(`/accounts/permissions-check`).then((res: { grantAccess: boolean }) => {
      return res.grantAccess;
    });
  }
}

export const AuthService = new Auth();
