<template>
  <div>
    <div class="container">
      <div class="input-wrapper">
        <app-input v-model="searchField" placeholder="Search Vendor..." @input="getVendors" />
      </div>
      <div v-if="vendors.length > 0" class="vendors">
        <div v-for="vendor in vendors" :key="vendor.slug" class="vendor" @click="goToVendor(vendor)">
          <div class="vendor-info">
            <img :src="vendor.logo" alt />
            <p>{{ vendor.name }}</p>
          </div>
          <div v-if="isSuperAdmin" class="vendor-publish" @click.stop>
            <app-switcher v-model="vendor.isPublished" class="is-marginless" @input="publishVendor(vendor.slug)">
              published
            </app-switcher>
            <app-popover>
              <div @click="deleteVendorHandler(vendor.slug)"><app-icon name="delete" />Delete vendor</div>
              <div @click="goToSettings(vendor.slug)"><app-icon name="pencil" />Edit vendor</div>
              <div @click="openEditFinanceDetailsModal(vendor)">
                <app-icon name="discount-2" />{{
                  vendor.financeDetails ? 'Edit finance details' : 'Add finance details'
                }}
              </div>
            </app-popover>
          </div>
        </div>
      </div>
      <div v-else class="empty-holder">
        <app-icon name="empty" />
        <span>Empty here</span>
      </div>
    </div>

    <router-link v-if="isSuperAdmin" to="/vendors/add" tag="a" class="addVendor">
      <app-icon name="add" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { AuthService } from '@/auth/auth-service';

@Component({})
export default class VendorsList extends Vue {
  public searchField: string = '';
  public vendors: any[] = [];
  public isSuperAdmin: boolean = false;

  async mounted() {
    this.getVendors();

    this.isSuperAdmin = await AuthService.isSuperAdmin();
    this.$store.commit('isSuperAdmin', this.isSuperAdmin);

    ModalHub.$on('on-confirm', (string: string) => {
      this.deleteVendor(string);
    });
  }

  public beforeDestroy() {
    ModalHub.$off('on-confirm');
  }

  private getVendors() {
    http.get(`/admin/vendors?name=${this.searchField}`).then((response: any) => {
      this.vendors = response?.vendors || [];
    });
  }

  private async goToVendor(vendor: any) {
    if (this.isSuperAdmin || (vendor.isTermsConfirmed && vendor.stripeAccountId)) {
      this.$store.commit('stripeId', vendor.stripeAccountId);
      this.$store.commit('isTerms', vendor.isTermsConfirmed);
      this.$store.commit('vendorSlug', vendor.slug);
      this.$router.push(`/vendors/${vendor.slug}`);
    } else {
      const { code } = await http.get(`/admin/vendors/${vendor.slug}/invitation/${this.$store.state.user?.email}`);
      this.$router.push(`/sign-up?code=${code}`);
    }
  }

  private async publishVendor(slug: string) {
    await http.put(`/admin/vendors/${slug}/publish`);
  }

  public deleteVendorHandler(slug: string) {
    ModalHub.$emit('open', 'modal-are-you-sure', {
      data: {
        title: 'you want to delete this vendor?',
        text: 'This action cannot be undone.',
        variable: slug,
      },
    });
  }

  public async deleteVendor(slug: string) {
    await http
      .delete(`/admin/vendors/${slug}`)
      .then((response: any) => {
        this.vendors = this.vendors.filter((vendor: { slug: string }) => vendor.slug !== slug);
      })
      .catch((err: any) => ModalHub.$emit('open', 'modal-error', { data: { message: err } }));
  }

  public goToSettings(slug: string) {
    this.$router.push(`/vendors/${slug}/settings`);
  }

  public openEditFinanceDetailsModal(vendor: any) {
    ModalHub.$emit('open', 'modal-finance-details', {
      animation: 'slide-left',
      data: { vendor },
    });
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 256px;
}
.vendors {
  padding-bottom: 120px;
  .vendor {
    position: relative;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $white;
    cursor: pointer;
    user-select: none;
    padding: 0 20px;
    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 50%;
      }
      p {
        margin-left: 10px;
        color: $dark;
        transition: 0.5s all;
        font-family: $Montserrat;
        font-size: 16px;
        line-height: 140%;
        font-weight: 600;
      }
    }
    &:hover {
      p {
        color: $pink;
      }
    }
    &-publish {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .popover {
      margin-left: 10px;
    }
  }
}
.addVendor {
  position: fixed;
  bottom: 40px;
  right: 40px;
}
</style>
