<template>
  <div>
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      Specify discount
    </div>

    <validation-observer ref="form" tag="form" @submit.prevent="onSubmit">
      <div class="columns">
        <div class="column">
          <p class="label">Dish price</p>
          <p class="price">
            <span v-if="discountedPrice != data.dish.price">£{{ discountedPrice.toFixed(2) }}</span>
            <span :class="{ 'current-price': discountedPrice != data.dish.price }"
              >£{{ data.dish.price.toFixed(2) }}</span
            >
          </p>
        </div>
        <div class="column">
          <app-input v-model="discountPercents" rules="required|min_value:1|max_value:100|numeric" name="Discount"
            >Discount in % *</app-input
          >
        </div>
      </div>
      <button type="submit" class="btn-pink">Save</button>
    </validation-observer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IDish } from '@/menu/restaurants-menu.vue';

@Component({})
export default class ModalAddCategory extends Vue {
  @Prop() data!: {
    dish: IDish;
    vendorSlug: string;
    restaurantSlug: string;
    menuCategorySlug: string;
  };
  public modalHub = ModalHub;
  public discountPercents: any = 1;
  public $refs!: {
    form: HTMLFormElement;
  };

  mounted() {
    console.info(this.data);
    this.discountPercents = this.data.dish.discountPercents;
  }

  get discountedPrice() {
    let discountedPrice = this.data.dish.price;
    if (this.discountPercents > 0 && this.discountPercents <= 100) {
      discountedPrice = this.data.dish.price - (this.data.dish.price * this.discountPercents) / 100;
    }
    return Math.round(discountedPrice * 100) / 100;
  }
  public async onSubmit() {
    const isValid = await this.$refs.form.validate();
    if (isValid) {
      const path = `/admin/menu/${this.data.vendorSlug}/restaurants/${this.data.restaurantSlug}/menu-categories/${this.data.menuCategorySlug}/dishes/${this.data.dish.id}/discount`;
      http
        .post(path, {
          discount: this.discountPercents,
        })
        .then((res: { success: boolean; discountedPrice: number }) => {
          if (res.success) {
            this.modalHub.$emit('close', {
              discountedPrice: res.discountedPrice,
              discountPercents: this.discountPercents,
              restaurantSlug: this.data.restaurantSlug,
              menuCategorySlug: this.data.menuCategorySlug,
              dishId: this.data.dish.id,
            });
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.btn-pink {
  width: 100%;
  margin-top: 20px;
}
.price {
  color: $pink;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-top: 8px;
}
.current-price {
  margin-left: 25px;
  text-decoration: line-through;
}
</style>
