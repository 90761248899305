import axios from 'axios';
import { getAuth } from 'firebase/auth';

import ModalHub from '@/_shared/modals/modal-hub';

import Environment from '@/environment';
import router from '@/router';

class Http {
  public service: any;
  public token: string = '';

  public async get(path: string) {
    await this.setAxios();

    return this.service
      .get(path)
      .then((res: Response) => Http.handleSuccess(res))
      .catch((err: Error) => Http.handleError(err));
  }

  public async post(path: string, payload: any) {
    await this.setAxios();

    return this.service
      .post(path, payload)
      .then((res: Response) => Http.handleSuccess(res))
      .catch((err: Error) => Http.handleError(err));
  }

  public async put(path: string, payload?: any) {
    await this.setAxios();

    return this.service
      .put(path, payload)
      .then((res: Response) => Http.handleSuccess(res))
      .catch((err: Error) => Http.handleError(err));
  }

  public async patch(path: string, payload?: any) {
    await this.setAxios();

    return this.service
      .patch(path, payload)
      .then((res: Response) => Http.handleSuccess(res))
      .catch((err: Error) => Http.handleError(err));
  }

  public async delete(path: string) {
    await this.setAxios();

    return this.service
      .delete(path)
      .then((res: Response) => Http.handleSuccess(res))
      .catch((err: any) => Http.handleError(err));
  }

  private static handleSuccess(res: any) {
    return res.data;
  }

  private static handleError(err: any) {
    if (err && err.response) {
      if (err.response.status === 403) {
        if (router.currentRoute.path !== '/vendors') {
          router.push('/vendors');
        }

        if (err.response.data?.error === 'No settled invoice') {
          ModalHub.$emit('open', 'modal-payment-due-unpublished', { animation: 'fade-enter' });
        } else {
          ModalHub.$emit('open', 'modal-error', {
            data: { message: "(403) Unauthorized: You don't have permissions" },
          });
        }

        return;
      }

      throw err.response.data?.error || err.response.data;
    } else {
      throw err;
    }
  }

  public async setAxios() {
    // HOTFIX for loading Firebase App too late:
    for (let i = 0; i < 4; i += 1) {
      try {
        this.token = (await getAuth().currentUser?.getIdToken()) ?? '';
      } catch (_) {
        this.token = '';
      }

      if (this.token !== '') break;
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    this.service = axios.create({
      baseURL: Environment.baseApiUrl,
      headers: {
        authorizationfirebase: `${this.token}`,
        platform: 'admin',
      },
    });
  }
}

export default new Http();
