<template>
  <div v-if="order" class="restaurant" @click="emmitClick(order.id)">
    <div class="restaurant-logo">
      <img :src="order.restaurant.vendor.logo" :alt="order.restaurant.name" />
    </div>
    <div class="restaurant-info">
      <p class="restaurant-name">{{ order.restaurant.name }} ({{ order.restaurant.street }})</p>
      <p class="restaurant-time">
        {{ order.status === 'scheduled' ? `Food Due: ${formatTime(order.foodDueTime)}` : formatTime(order.createdAt) }}
      </p>
      <p class="restaurant-summary">£ {{ totalPriceWithoutDiscount.toFixed(2) }}</p>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class OrderRow extends Vue {
  @Prop() action: any;
  @Prop() order: any;

  public emmitClick(orderId: number) {
    this.$emit('onClick', { orderId, vendorSlug: this.order.restaurant.vendor.slug });
  }

  get totalPriceWithoutDiscount() {
    return Math.round(this.order.cartPrice * 100) / 100;
  }

  private formatTime(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}
</script>

<style lang="scss" scoped>
.restaurant {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  background-color: $white;
  &:hover {
    .restaurant-name {
      color: $pink;
    }
    .restaurant-more {
      color: $pink;
    }
  }
  &-logo {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &-info {
    margin-left: 20px;
    text-align: left;
  }
  &-name {
    color: $dark;
    font-size: 16px;
    font-family: $Montserrat;
    font-weight: 600;
    transition: 0.5s all;
  }
  &-time {
    color: $darkgray;
    font-size: 12px;
    margin: 6px 0;
    &.closed {
      font-weight: bold;
      color: $pink;
    }
  }
  &-summary {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
