<template>
  <component
    :is="icon"
    v-if="icon"
    class="icon"
    :close="close"
    :color="color"
    :direction="direction"
    :width="width"
    :height="height"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import arrow from '@/_shared/icons/icon-arrow.vue';

@Component({
  components: {
    arrow,
  },
  props: {
    icon: String,
    color: String,
    direction: String,
    close: Boolean,
    width: String,
    height: String,
  },
})
export default class Icon extends Vue {
  $props!: {
    direction: 'top-left' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left';
  };
}
</script>
