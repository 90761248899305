import * as Sentry from '@sentry/vue';
import Vue from 'vue';

import '@/_shared/components/_global';
import '@/_shared/controls';
import '@/_shared/utils/validation';

import App from './app.vue';
import Environment from '@/environment';
import router from '@/router';
import store from '@/store/store';

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: Environment.sentryDsn,
  environment: Environment.sentryEnv,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
