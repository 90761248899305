<template>
  <ValidationObserver id="form-example" v-slot="{ invalid }" ref="form" tag="form" @submit.prevent="updateVendor">
    <div class="vendor-main">
      <div class="container columns">
        <div class="vendor-left column is-3">
          <div class="vendor-input">
            <app-input v-model="form.name" rules="required" name="Name">Vendor name*</app-input>
          </div>
          <div class="vendor-input">
            <app-input v-model="form.companyName" name="CompanyName">Vendor company name</app-input>
          </div>
          <div v-if="isSuperAdmin" class="vendor-input">
            <app-input v-model="form.shortDescription" name="ShortDescription">Vendor short description</app-input>
          </div>
          <div class="vendor-input">
            <app-input v-model="form.phone" name="Phone">Vendor phone number</app-input>
          </div>
          <div class="vendor-logo-wrapper">
            <p>Vendor logo*</p>
            <app-file v-model="form.logo" rules="required" name="Logo" @input="addLogo($event)">
              <div v-if="previewLogo" class="vendor-logo">
                <span @click.stop.prevent="removeLogo">
                  <app-icon name="remove-red-circle" />
                </span>
                <img :src="previewLogo" alt />
              </div>
              <div v-else class="picture-holder">
                <span class="picture-holder-add">
                  <app-icon name="add-small"></app-icon>
                </span>
                <div class="picture-holder-parameters">
                  <p>Recommended</p>
                  <p>980x980 px</p>
                </div>
              </div>
            </app-file>
          </div>
          <div class="modal-switcher">
            <p>Remind about new order?</p>
            <app-switcher v-model="isFlashReminder" :flash="true" prefix="No" postfix="Yes"></app-switcher>
          </div>
          <div v-if="isSuperAdmin" class="modal-switcher">
            <p>Registered for VAT?</p>
            <app-switcher v-model="isVatRegistered" :flash="true" prefix="No" postfix="Yes"> </app-switcher>
          </div>
        </div>
        <div class="vendor-right column is-9">
          <div class="vendor-cover-wrapper">
            <p>Vendor cover picture*</p>
            <app-file v-model="form.cover" rules="required" name="Cover" @input="addCover($event)">
              <div v-if="previewCover" class="vendor-cover">
                <span @click.stop.prevent="removeCover">
                  <app-icon name="remove-red-circle" />
                </span>
                <img :src="previewCover" alt="" />
              </div>
              <div v-else class="picture-holder">
                <span class="picture-holder-add">
                  <app-icon name="add-small"></app-icon>
                </span>
                <div class="picture-holder-parameters">
                  <p>Recommended</p>
                  <p>1920x680 px</p>
                </div>
              </div>
            </app-file>
          </div>

          <div class="vendor-information">
            <app-textarea v-model="form.information" rules="required" name="Information"
              >Vendor information*</app-textarea
            >
          </div>

          <app-accordion>
            <template #head>
              <div class="accordion-title">Restaurants</div>
              <span class="add-icon" @click.stop="openAddRestaurantModal">
                <app-icon name="add-small" />
              </span>
            </template>
            <template #body>
              <SettingsRestaurant
                v-for="(restaurant, index) in restaurants"
                :key="index"
                :restaurant="restaurant"
                :index="index"
              />
            </template>
          </app-accordion>

          <app-accordion>
            <template #head>
              <div class="accordion-title">Users</div>

              <span class="add-icon" @click.stop="openAddUserModal">
                <app-icon name="add-small" />
              </span>
            </template>

            <template #body>
              <div v-for="(user, index) in users" :key="user.email + index" class="user-example">
                <p>{{ user.email }}</p>
                <div class="user-right-buttons">
                  <span @click="deleteUser(index)">
                    <app-icon name="delete" class="trash"></app-icon>
                  </span>
                </div>
              </div>
            </template>
          </app-accordion>

          <app-accordion>
            <template #head>
              <div class="accordion-title">Invitations</div>
            </template>

            <template #body>
              <div v-for="(invitation, index) in invitations" :key="index" class="user-example">
                <div>
                  <p>{{ invitation.email }}</p>
                  <a v-if="invitation.code" class="invitation-code" :href="`${signUpUrl}${invitation.code}`">{{
                    signUpUrl + invitation.code
                  }}</a>
                  <span v-else class="invitation-code"
                    >No invitation has been found - please try to remove that user and send an invitation again.</span
                  >
                </div>
                <div class="user-right-buttons">
                  <button v-if="copiedInvitationCode === invitation.code" type="button" class="btn-white resend">
                    Copied
                  </button>
                  <button v-else type="button" class="btn-white resend" @click="copyLink(invitation)">Copy link</button>
                  <span style="padding-left: 10px" @click="deleteInvitation(index)">
                    <app-icon name="delete" class="trash"></app-icon>
                  </span>
                </div>
              </div>
            </template>
          </app-accordion>

          <app-accordion>
            <template #head>
              <div class="accordion-title">Preparation times</div>
            </template>

            <template #body>
              <div v-for="(restaurant, index) in restaurants" :key="index" class="preptimes">
                <app-accordion v-if="restaurant.busyStatusPreparationTimes">
                  <template #head>
                    <div class="accordion-title">{{ restaurant.name }}</div>
                  </template>
                  <template #body>
                    <ValidationObserver id="form-example" ref="form" tag="form">
                      <div class="preptimes__container">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required|min_value:0|max_value:120"
                          name="normal preptime"
                        >
                          <div class="preptimes__element">
                            <span class="preptimes__mode">Normal mode</span>
                            <span>Standard preparation time when the kitchen is quiet</span>
                            <div class="preptimes__input">
                              <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
                              <input
                                v-model="restaurant.busyStatusPreparationTimes['normal']"
                                v-mask="'##'"
                                type="text"
                                class="input"
                                placeholder="15"
                                :class="{ 'input-error': errors.length }"
                              />
                            </div>
                          </div>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required|min_value:0|max_value:120"
                          name="busy preptime"
                        >
                          <div class="preptimes__element">
                            <span class="preptimes__mode">Busy mode</span>
                            <span>Preparation time when the kitchen is busy</span>
                            <div class="preptimes__input">
                              <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
                              <input
                                v-model="restaurant.busyStatusPreparationTimes['busy']"
                                v-mask="'##'"
                                type="text"
                                class="input"
                                placeholder="45"
                                :class="{ 'input-error': errors.length }"
                              />
                            </div>
                          </div>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required|min_value:0|max_value:120"
                          name="chaos preptime"
                        >
                          <div class="preptimes__element">
                            <span class="preptimes__mode">Chaos mode</span>
                            <span>Preparation time when the kitchen is exceptionally busy</span>
                            <div class="preptimes__input">
                              <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
                              <input
                                v-model="restaurant.busyStatusPreparationTimes['chaos']"
                                v-mask="'##'"
                                type="text"
                                class="input"
                                placeholder="65"
                                :class="{ 'input-error': errors.length }"
                              />
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </ValidationObserver>
                  </template>
                </app-accordion>
              </div>
            </template>
          </app-accordion>
        </div>
      </div>
    </div>

    <div class="container">
      <input
        class="btn-pink vendor-submit"
        type="submit"
        value="Save"
        form="form-example"
        :disabled="invalid"
        :class="{ 'btn-disabled': invalid }"
      />
    </div>

    <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
    <app-modal-loader v-if="loading" />
    <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
  </ValidationObserver>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';
import { toFormData } from '@/_shared/utils/to-form-data';

import { ISettingsRestaurant, RestaurantBusyStatus } from '@/_types/vendor-settings.types';

import SettingsRestaurant from './settings/settings-restaurant.vue';
import Environment from '@/environment';

@Component({
  directives: {
    mask,
  },
  components: {
    SettingsRestaurant,
  },
})
export default class Settings extends Vue {
  public previewLogo = '';
  public previewCover = '';
  public firstLogo = '';
  public firstCover = '';
  public logoChanged = false;
  public coverChanged = false;

  public signUpUrl = `${Environment.adminUrl}/sign-up?code=`;
  public copiedInvitationCode = '';
  public loading = false;
  public errors = '';
  public prepErrors = '';
  public isSuperAdmin = this.$store.state.isSuperAdmin;
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  public form: VendorForm = {
    name: '',
    companyName: null,
    shortDescription: null,
    phone: null,
    information: '',
    logo: null,
    cover: null,
    users: [],
  };

  public dayNumbers: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  public users: any[] = [];
  public invitations: any[] = [];
  public restaurants: ISettingsRestaurant[] = [];
  public prepTimes: any[] = [];
  public isFlashReminder: boolean = false;
  public isVatRegistered: boolean = false;

  public dietaries: IFilter[] = [];
  public categories: IFilter[] = [];
  public budgets: IFilter[] = [];
  public perfectFors: IFilter[] = [];

  mounted() {
    const { slug } = this.$route.params;
    http.get('/restaurants/filter-options').then((response: any) => {
      this.dietaries = response.dietaries;
      this.categories = response.categories;
      this.budgets = response.budgets;
      this.perfectFors = response.perfectFors;
      http
        .get(`/admin/vendors/${slug}`)
        .then((response: any) => {
          this.form = {
            name: response.vendor.name,
            companyName: response.vendor.companyName != 'null' ? response.vendor.companyName : '',
            shortDescription: response.vendor.shortDescription != 'null' ? response.vendor.shortDescription : '',
            phone: response.vendor.phone != 'null' ? response.vendor.phone : '',
            information: response.vendor.description,
            logo: response.vendor.logo,
            cover: response.vendor.cover,
            users: [],
          };

          this.users = response.users;
          this.invitations = response.invites;

          (this.restaurants = response.restaurants),
            this.restaurants.forEach((restaurant: any) => {
              restaurant.postCodeObject = restaurant.postCode;
              restaurant.postCode = restaurant.postCodeObject.postcode;
              restaurant.locationName = `${restaurant.number} ${restaurant.street}, ${restaurant.name}, ${restaurant.postCode}, ${restaurant.city}`;

              if (restaurant.categories) {
                const categoriesIds = restaurant.categories.map((c) => c.id);
                restaurant.categories = this.fullCatDiet(this.categories, categoriesIds);
                restaurant.chosenCategories = restaurant.categories.filter((c) => c.chosen);
                restaurant.categoriesTitle = this.catDietTitle(restaurant.categories);
              }

              if (restaurant.dietaries) {
                const dietariesIds = restaurant.dietaries.map((d) => d.id);
                restaurant.dietaries = this.fullCatDiet(this.dietaries, dietariesIds);
                restaurant.chosenDietaries = restaurant.dietaries.filter((d) => d.chosen);
                restaurant.dietariesTitle = this.catDietTitle(restaurant.dietaries);
              }

              if (restaurant.budgets) {
                const budgetIds = restaurant.budgets.map((b) => b.id);
                restaurant.budgets = this.fullCatDiet(this.budgets, budgetIds);
                restaurant.chosenBudgets = restaurant.budgets.filter((b) => b.chosen);
                restaurant.budgetsTitle = this.catDietTitle(restaurant.budgets);
              }

              if (restaurant.perfectFors) {
                const pfIds = restaurant.perfectFors.map((pf) => pf.id);
                restaurant.perfectFors = this.fullCatDiet(this.perfectFors, pfIds);
                restaurant.chosenPerfectFors = restaurant.perfectFors.filter((pf) => pf.chosen);
                restaurant.perfectForsTitle = this.catDietTitle(restaurant.perfectFors);
              }

              restaurant.days = {};
              restaurant.workingHours.forEach((wh: any) => {
                const dayName = Object.entries(this.dayNumbers).filter((day: any) => day[1] === wh.day);
                restaurant.days[dayName[0][0]] = [
                  wh.startTime.substring(0, 5),
                  wh.endTime.substring(0, 5),
                  wh.isClosed,
                ];
              });

              restaurant.busyStatusPreparationTimes = {
                normal: restaurant.busyStatusPreparationTime[0].extraTime,
                busy: restaurant.busyStatusPreparationTime[1].extraTime,
                chaos: restaurant.busyStatusPreparationTime[2].extraTime,
              };
            });
          this.previewCover = response.vendor.cover;
          this.previewLogo = response.vendor.logo;
          this.firstLogo = response.vendor.logo;
          this.firstCover = response.vendor.cover;
          this.isFlashReminder = response.vendor.isFlashReminder;
          this.isVatRegistered = response.vendor.isVatRegistered;
        })
        .catch((err: any) => {
          // eslint-disable-next-line
          console.log(err);
          ModalHub.$emit('open', 'modal-error', {
            message: 'Something has gone wrong with vendor update, please, contact our support to solve the problem',
          });
        });
    });
  }

  public fullCatDiet(array1: any, array2: any) {
    return array1.map((elem: any) => {
      if (array2.includes(elem.id)) {
        return { ...elem, chosen: true };
      } else {
        return elem;
      }
    });
  }

  public catDietTitle(arr: any) {
    return arr
      .filter((elem: any) => elem.chosen)
      .map((elem: any) => elem.name)
      .join(', ');
  }

  updateVendor() {
    const { slug } = this.$route.params;
    this.loading = true;

    const restaurants: any = this.restaurants.map((restaurant: any) => {
      const days = Object.entries(restaurant.days).map((elem: any) => {
        const day = this.dayNumbers[elem[0]];
        return {
          day,
          startTime: elem[1][0],
          endTime: elem[1][1],
          isClosed: elem[1][2],
        };
      });
      if (!restaurant.hasOwnProperty('id')) {
        restaurant.id = null;
      }

      // Quick fix - when adding new restaurant this field is null, causing error - refactor - set default values
      let busyStatusPreparationTimes = restaurant.busyStatusPreparationTimes;
      if (busyStatusPreparationTimes) {
        busyStatusPreparationTimes = Object.entries<string>(restaurant.busyStatusPreparationTimes).map((status) => ({
          name: status[0],
          extraTime: parseInt(status[1]),
        }));
      }
      // end of quick fix

      return {
        id: restaurant.id,
        vendor: slug,
        name: restaurant.name,
        slug: restaurant.slug,
        number: restaurant.number,
        street: restaurant.street,
        city: restaurant.city,
        postCode: `${restaurant.postCodeObject.id}`,
        categories: restaurant.chosenCategories.map((e) => e.id),
        dietaries: restaurant.chosenDietaries.map((e) => e.id),
        budgets: restaurant.chosenBudgets.map((e) => e.id),
        perfectFors: restaurant.chosenPerfectFors.map((e) => e.id),
        clickAndCollect: restaurant.clickAndCollect,
        allowScheduledOrders: restaurant.allowScheduledOrders,
        allowOnDemandOrders: restaurant.allowOnDemandOrders,
        isCaterer: restaurant.isCaterer,
        minHoursInAdvance: restaurant.minHoursInAdvance === '' ? null : restaurant.minHoursInAdvance,
        minCartPrice: restaurant.minCartPrice === '' ? null : restaurant.minCartPrice,
        discountMinCartPrice: restaurant.discountMinCartPrice,
        discountPercentage: restaurant.discountPercentage,
        workingHours: days,
        busyStatusPreparationTimes: busyStatusPreparationTimes || undefined,
      };
    });

    const json = {
      restaurants,
      name: this.form.name,
      logo: (this.logoChanged ? this.form.logo : this.firstLogo) || '',
      cover: (this.coverChanged ? this.form.cover : this.firstCover) || '',
      description: this.form.information,
      users: this.users,
      invites: this.invitations,
      isFlashReminder: this.isFlashReminder,
      isVatRegistered: this.isVatRegistered,
      companyName: this.form.companyName || '',
      shortDescription: this.form.shortDescription || '',
      phone: this.form.phone || '',
    };

    http
      .post(`/admin/vendors/${slug}`, toFormData(json))
      .then((res: any) => this.$router.go(0))
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: err,
          },
        });
      });
  }

  get userEmails() {
    return this.users.map((user: any) => user.email);
  }

  get invitationEmails() {
    return this.invitations.map((inv: any) => inv.email);
  }

  beforeCreate() {
    ModalHub.$on('confirm-delete-restaurant', (restaurantIndex: any) => {
      this.deleteRestaurant(restaurantIndex);
    });

    ModalHub.$on('addUser', (e: any) => {
      if (!this.invitationEmails.includes(e.email) && !this.userEmails.includes(e.email)) {
        this.users.push(e);
        this.form.users.push(e.email);
      } else {
        ModalHub.$emit('open', 'modal-error', { data: { message: 'User with such email is already in your list' } });
      }
    });

    ModalHub.$on('addRestaurant', (e: any) => {
      this.restaurants.push(e);
    });

    ModalHub.$on('editRestaurant', (e: any) => {
      let restaurant = this.restaurants[e.index];

      restaurant.name = e.name;
      restaurant.street = e.street;
      restaurant.city = e.city;
      restaurant.number = e.number;
      restaurant.postCode = e.postCode;
      restaurant.postCodeObject = e.postCodeObject;
      restaurant.locationName = e.locationName;
      restaurant.chosenDietaries = e.chosenDietaries;
      restaurant.chosenCategories = e.chosenCategories;
      restaurant.chosenBudgets = e.chosenBudgets;
      restaurant.chosenPerfectFors = e.chosenPerfectFors;
      restaurant.dietaries = e.dietaries;
      restaurant.categories = e.categories;
      restaurant.budgets = e.budgets;
      restaurant.perfectFors = e.perfectFors;
      restaurant.dietariesTitle = e.dietariesTitle;
      restaurant.categoriesTitle = e.categoriesTitle;
      restaurant.budgetsTitle = e.budgetsTitle;
      restaurant.perfectForsTitle = e.perfectForsTitle;

      this.restaurants[e.index] = restaurant;
      this.$forceUpdate();
    });

    ModalHub.$on('restaurant-startEditing', (index: number) => {
      const restaurant = this.restaurants[index];
      ModalHub.$emit('open', 'modal-add-restaurant', { animation: 'slide-left', data: { ...restaurant, index } });
    });

    ModalHub.$on('restaurant-delete', (index: number) => {
      this.deleteRestaurantHandler(index);
    });

    ModalHub.$on('restaurant-changeBusyStatus', (slug: string, status: RestaurantBusyStatus) => {
      this.changeRestaurantBusyStatus(slug, status);
    });
  }

  public destroyed() {
    ModalHub.$off('addUser');
    ModalHub.$off('addRestaurant');
    ModalHub.$off('editRestaurant');
    ModalHub.$off('confirm-delete-restaurant');
  }

  public deleteUser(index: number) {
    this.users.splice(index, 1);
  }

  public deleteInvitation(index: number) {
    this.invitations.splice(index, 1);
  }

  public deleteRestaurant(index: number) {
    this.restaurants.splice(index, 1);
  }

  public deleteRestaurantHandler(index: number) {
    ModalHub.$emit('open', 'modal-are-you-sure', {
      data: {
        title: 'you want to delete this restaurant?',
        text: 'This action cannot be undone.',
        variable: index,
        eventName: 'confirm-delete-restaurant',
      },
    });
  }

  public editRestaurant(index: number) {
    const restaurant = this.restaurants[index];
    ModalHub.$emit('open', 'modal-add-restaurant', { animation: 'slide-left', data: { ...restaurant, index } });
  }

  public async copyLink(invitation: any) {
    await navigator.clipboard.writeText(this.signUpUrl + invitation.code);
    this.copiedInvitationCode = invitation.code;
    setTimeout(() => (this.copiedInvitationCode = ''), 1000);
  }

  public addLogo(file: any) {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewLogo = e.target.result;
    };
    reader.readAsDataURL(file);
    this.logoChanged = true;
  }

  public removeLogo() {
    this.previewLogo = '';
    this.form.logo = null;
  }

  public addCover(file: any) {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.previewCover = e.target.result;
    };
    reader.readAsDataURL(file);
    this.coverChanged = true;
  }

  public removeCover() {
    this.previewCover = '';
    this.form.cover = null;
  }

  public openAddRestaurantModal() {
    ModalHub.$emit('open', 'modal-add-restaurant', { animation: 'slide-left' });
  }

  public openAddUserModal() {
    ModalHub.$emit('open', 'modal-add-user', { animation: 'slide-left' });
  }

  public changeRestaurantBusyStatus(restaurantSlug: string, status: RestaurantBusyStatus) {
    const restaurant = this.restaurants.find((rest) => rest.slug === restaurantSlug);
    if (!restaurant) return;

    const oldStatus = restaurant.busyStatus;
    restaurant.busyStatus = status;

    http
      .put(`/admin/vendors/${this.$route.params.slug}/restaurants/${restaurantSlug}/busy-status`, { status })
      .catch((err: any) => {
        restaurant.busyStatus = oldStatus;
      });
  }
}

interface VendorForm {
  name: string;
  companyName?: string | null;
  shortDescription?: string | null;
  phone?: string | null;
  information: string;
  logo: any;
  cover: any;
  users: any[];
}

interface IFilter {
  id: number;
  name: string;
  chosen: boolean;
}
</script>

<style lang="scss" scoped>
.input-error {
  border: solid 2px red;
}
.vendor-main {
  border-bottom: 1px solid $gray;
  .vendor-left {
    margin-bottom: 40px;
    padding-top: 0;
    .vendor-input {
      height: 126px;
      width: 100%;
      padding: 20px 20px;
      background-color: $white;
      margin-top: 20px;
    }
    .vendor-logo-wrapper {
      height: 343px;
      width: 100%;
      padding: 20px 20px;
      background-color: $white;
      margin-top: 20px;
      p {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 112.9%;
        color: #535151;
        margin-bottom: 10px;
      }
      .vendor-logo {
        width: 100%;
        height: 256px;
        position: relative;
        span {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          user-select: none;
        }
        img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  .vendor-right {
    padding-top: 0;
    .vendor-information {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      padding: 20px;
      background-color: $white;
    }
    .accordion-title {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      color: $dark;
      margin-left: 20px;
    }
    .add-icon {
      margin-left: auto;
      margin-right: 20px;
    }
    .user-example {
      width: 100%;
      height: 80px;
      background-color: $lightgray;
      margin-top: 10px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      p {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: $dark;
      }
      .trash {
        margin-right: 20px;
        cursor: pointer;
      }
      .resend {
        background-color: $pink;
        color: white;
        margin-right: 10px;
        padding: 8px 20px;
        &:hover {
          opacity: 0.8;
        }
        &:active {
          transform: scale(0.95);
        }
      }
      .invitation-code {
        font-size: 0.5em;
        color: #555;
      }
    }
    .vendor-cover-wrapper {
      height: 343px;
      width: 100%;
      padding: 20px 20px;
      background-color: $white;
      margin-top: 20px;
      margin-bottom: 20px;
      p {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 112.9%;
        color: #535151;
        margin-bottom: 10px;
      }
      .vendor-cover {
        width: 100%;
        height: 256px;
        position: relative;
        span {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          user-select: none;
        }
        img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .vendor-additional {
      background-color: $white;
      padding: 20px 20px;
      margin-bottom: 20px;
      .additional-top {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          font-family: $Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 100%;
          color: #030208;
        }
      }
    }
  }
}
.vendor-submit {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 32px;
  width: 256px;
}
.time {
  width: calc(100% + 40px);
  height: 80px;
  background-color: $lightgray;
  margin-top: 10px;
  margin-left: -20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &-title {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: $dark;
    margin-left: 20px;
    @include bp-500 {
      font-size: 14px;
    }
  }
  &-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    .label {
      margin-bottom: 0;
    }

    .input {
      max-width: 72px;
      margin: 0 10px;
      border: $controlBorder;
      padding-left: 9px;
      @include control-states;

      @include bp-500 {
        font-size: 13px;
        max-width: 36px;
        height: 36px;
        margin: 0 4px;
      }
    }
  }
}
.modal-switcher {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 20px;
  background-color: $white;

  @include bp-1120 {
    padding: 10px 10px;
  }
  @include bp-920 {
    padding: 20px 0;
  }
  p {
    margin-bottom: 20px;
    @include bp-920 {
      font-size: 14px;
    }
    @include bp-768 {
      font-size: 20px;
    }
  }
}
.prep-definition {
  font-size: 12px;
}
.btn-disabled {
  opacity: 0.2;
}
.preptimes {
  &__element {
    background-color: $lightgray;
    position: relative;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      text-align: center;

      > * {
        margin-top: 0.5em;
      }
    }
  }
  &__input {
    position: relative;
    .error {
      position: absolute;
      top: 50px;
      text-align: center;
      z-index: 10;
      font-size: 10px;
      width: 80px;
    }
    input {
      width: 62.5px;
      height: 50px;
      margin-right: 1em;
    }
  }
  &__mode {
    margin-left: 1em;
    font-weight: 700;
    min-width: 135px;
  }
}
</style>
