<template>
  <div class="order-card" :class="order.status" @click="openOrderInfo(order)">
    <div class="order-title">{{ orderDate }} / {{ order.restaurant.name }}</div>
    <div v-if="order.status === 'ready_for_delivery'" class="status">Waiting for delivery</div>
    <div v-else-if="order.status === 'ready_for_collect'" class="status">Waiting to be collected</div>
    <div v-else class="status">{{ order.status }}<span v-if="order.type === 'collect'"> - Click & Collect</span></div>
    <div class="status">
      <span>{{ restaurantOrdersCountText }}</span>
    </div>
    <div class="price">
      <span>{{ order.user.firstName }}</span>
      <span>£ {{ totalPriceWithoutDiscount.toFixed(2) }}</span>
    </div>
    <div class="order-id">
      <div>id: {{ order.friendlyId }}</div>
      <div>{{ order.type === 'collect' ? 'Pickup' : 'Due' }} : {{ dueTo }}</div>
    </div>
    <div v-if="order.kitchenNotes || order.deliveryNotes" class="notes">
      <div v-if="order.kitchenNotes">
        Kitchen Note:
        <div class="notes-text">{{ order.kitchenNotes }}</div>
      </div>
      <div v-if="order.deliveryNotes">
        Delivery Note:
        <div class="notes-text">{{ order.deliveryNotes }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({})
export default class OrderCard extends Vue {
  public modalHub = ModalHub;
  @Prop() order!: any;

  get restaurantOrdersCountText() {
    const orderCount = this.order.restaurantOrders;
    if (!orderCount) return '';

    if (orderCount === 1) return 'New Customer - First order from you';
    else if (orderCount < 10) return `${orderCount} orders from you`;
    else if (orderCount > 10) return `Loyal Customer - ${orderCount} orders from you`;

    return '';
  }

  get orderDate() {
    const date = new Date(new Date(this.order.createdAt).toLocaleString('en-US', { timeZone: 'Europe/London' }));
    return `${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  }

  get totalPriceWithoutDiscount() {
    return Math.round(this.order.cartPrice * 100) / 100;
  }

  get deliveryTime() {
    const date = new Date(new Date(this.order.deliverToTime).toLocaleString('en-US', { timeZone: 'Europe/London' }));
    return `${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
  }

  get dueTo() {
    return moment(this.order.foodDueTime).tz('Europe/London').format('HH:mm');
  }

  openOrderInfo(order: IOrderCard) {
    switch (order.status) {
      case 'new':
        ModalHub.$emit('open', 'modal-order-new', { data: { vendorSlug: this.$route.params.slug, orderId: order.id } });
        break;
      case 'preparing':
        ModalHub.$emit('open', 'modal-order-preparing', {
          data: { vendorSlug: this.$route.params.slug, orderId: order.id },
        });
        break;
      case 'ready_for_delivery':
        ModalHub.$emit('open', 'modal-order-ready', {
          data: { vendorSlug: this.$route.params.slug, orderId: order.id },
        });
        break;
      case 'ready_for_collect':
        ModalHub.$emit('open', 'modal-order-collect', {
          data: { vendorSlug: this.$route.params.slug, orderId: order.id },
        });
        break;
      case 'delivering':
        ModalHub.$emit('open', 'modal-order-delivering', {
          data: { vendorSlug: this.$route.params.slug, orderId: order.id },
        });
        break;
    }
  }
}

export interface IOrderCard {
  id: number;
  orderTime: string;
  status: string;
  restaurant: string;
  deliverToTime: string;
  price: number;
  restaurantOrders: number | null;
}
</script>

<style lang="scss" scoped>
.order-card {
  background-color: $white;
  padding: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 112%;
  width: 292px;
  margin: 10px;
  cursor: pointer;
  transition: 0.5s all;
  &:hover {
    background-color: darken($light, 5);
  }
  div {
    margin-bottom: 10px;
  }
  .status {
    text-transform: uppercase;
  }
  .delivery-time {
    font-weight: 400;
  }
  .price {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    span:last-child {
      font-size: 20px;
    }
  }
  .order-id {
    font-size: 16px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    div {
      margin-bottom: 0;
    }
  }
  &.new {
    .status {
      color: $green;
    }
  }
  &.preparing {
    background-color: $pink;
    color: $white;
    &:hover {
      background-color: lighten($pink, 10);
    }
  }
  &.ready_for_delivery {
    background-color: $blue;
    color: $white;
    &:hover {
      background-color: lighten($blue, 10);
    }
  }
  &.ready_for_collect {
    background-color: $blue;
    color: $white;
    &:hover {
      background-color: lighten($blue, 10);
    }
  }
  &.delivering {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: lighten($green, 10);
    }
  }
}
.notes {
  font-size: 14px;
  padding-top: 20px;
}
.notes-text {
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
