<template>
  <div class="asidedLayout">
    <aside class="asidedLayout-aside">
      <div class="asidedLayout-aside-inside">
        <div class="asidedLayout-aside-logo">
          <router-link to="/">
            <img src="../../../_assets/logo.png" alt />
          </router-link>
        </div>
        <div class="asidedLayout-aside-content">
          <slot name="aside"></slot>
        </div>
      </div>
    </aside>
    <main class="asidedLayout-body">
      <div v-if="$slots.top" class="asidedLayout-top">
        <slot name="top"></slot>
      </div>
      <div class="asidedLayout-body-content">
        <slot name="body"></slot>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: ['title', 'desc', 'asideTitle', 'asideDesc'],
})
export default class AsidedLayout extends Vue {}
</script>

<style lang="scss" scoped>
.asidedLayout {
  position: relative;
  display: flex;
  overflow: hidden;
  font-family: $Sofia;
  font-weight: 400;
  background-color: $vanilla;
  @include bp-768 {
    flex-direction: column;
  }
  &-top {
    position: absolute;
    top: 22px;
    right: 40px;
    @include fontsize-20;
    @include bp-920 {
      top: 18px;
      right: 30px;
    }
    @include bp-768 {
      order: 1000;
      position: relative;
      top: auto;
      right: auto;
      margin-top: 40px;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
      color: #3e69bd;
      &:hover {
        color: $hamdark;
      }
    }
  }
  &-aside {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 420px;
    background: $ham;
    @include bp-1300 {
      width: 290px;
    }
    @include bp-920 {
      width: 250px;
    }
    @include bp-768 {
      overflow: visible;
      position: relative;
      justify-content: center;
      align-items: center;
      min-height: 128px;
      width: auto;
      text-align: center;
    }
    @include bp-380 {
      min-height: 100px;
    }
    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 20px;
      display: block;
      background-image: url('~@/_assets/decor/wiggle-ham-left.png');
      background-position: top left;
      background-repeat: repeat-y;
      @include bp-768 {
        top: 100%;
        left: 0;
        right: 0;
        bottom: auto;
        height: 20px;
        width: auto;
        background-image: url('~@/_assets/decor/wiggle-ham-top.png');
        background-repeat: repeat-x;
      }
    }
    &-logo {
      margin-bottom: 20px;

      @include bp-768 {
        margin-bottom: 0;
      }
      img {
        display: block;
        margin: 0 auto;
        max-width: 176px;
        @include bp-920 {
          width: 140px;
        }
        @include bp-768 {
          width: 170px;
        }
      }
    }
    &-inside {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding: 45px 50px;
      @include bp-1300 {
        padding: 25px 35px;
      }
      @include bp-920 {
        padding: 20px 25px;
      }
      @include bp-768 {
        overflow-y: visible;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 1 70%;
      @include bp-768 {
        flex: auto;
      }
    }
    > header {
      font-weight: 100;
      @include bp-768 {
        display: none;
      }
      > :not(:first-child) {
        margin-top: 30px;
        @include bp-768 {
          margin-top: 10px;
        }
      }
      p {
        line-height: 1.3;
      }
    }
  }
  &-aside + &-body {
    margin-left: 420px;
    @include bp-1300 {
      margin-left: 370px;
    }
    @include bp-1120 {
      margin-left: 300px;
    }
    @include bp-920 {
      margin-left: 250px;
    }
    @include bp-768 {
      margin-left: 0;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 45px 40px;
    @include bp-920 {
      padding: 45px 30px;
    }
    @include bp-768 {
      overflow: visible;
      padding: 35px 30px;
    }
    @include bp-380 {
      padding: 35px 20px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 100%;

      > * {
        max-width: 100%;
      }
      header {
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 30px;
        line-height: 1.3;

        > :not(:first-child) {
          margin-top: 12px;
        }
        &::after {
          opacity: 0.1;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          width: 210px;
          height: 1px;
          margin: 0 auto;
          background-color: #000;
          @include bp-768 {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
