<template>
  <div class="modal-main">
    <div v-if="paymentMethod === 'invoice'" class="title">Invoice Details</div>
    <div v-else class="title">VAT Receipt Details</div>
    <div class="subtitle">Order ID: {{ data.friendlyId }}</div>
    <app-input v-model="form.companyName">Company name*</app-input>
    <button class="btn-pink" @click="send">Generate</button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({ components: {} })
export default class ModalInvoiceXero extends Vue {
  @Prop() data!: { friendlyId: number; paymentMethod: string };
  public modalHub = ModalHub;
  public form = { companyName: '' };

  public send() {
    if (!this.form.companyName) {
      ModalHub.$emit('open', 'modal-error', {
        data: { message: 'Company name is required!', missingData: true },
      });
      return;
    }

    http
      .post(`/admin/orders/generate-invoice`, { friendlyId: this.data.friendlyId, companyName: this.form.companyName })
      .catch((err: any) => ModalHub.$emit('open', 'modal-error', { data: { message: err } }));

    ModalHub.$emit('close');
  }
}
</script>

<style lang="scss" scoped>
.modal-main {
  &.modal-inner {
    width: 340px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}
.title {
  font-size: 20px;
}
.subtitle {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 150%;
}
.app-input {
  text-align: start;
}
</style>
