<template>
  <ValidationObserver ref="form" tag="form" class="modal-billing-update-wrapper" @submit.prevent="updateAccount">
    <div @click="goBackToRetryModal">
      <Icon icon="arrow" direction="left" class="list-icon" />
      <h1>Update billing details</h1>
    </div>
    <div class="columns is-variable is-mobile is-2-mobile">
      <app-input
        id="first-name"
        v-model="account.first_name"
        class="is-marginless column is-half"
        rules="required"
        name="First Name"
      >
        First Name
      </app-input>
      <app-input
        id="last_name"
        v-model="account.last_name"
        class="is-marginless column is-half"
        rules="required"
        name="Last Name"
        >Last Name
      </app-input>
    </div>
    <div class="columns is-variable is-mobile is-2-mobile">
      <app-input
        id="address1"
        v-model="account.address1"
        class="is-marginless column is-half"
        rules="required"
        name="Address"
      >
        Street Address
      </app-input>
      <app-input id="address2" v-model="account.address2" class="is-marginless column is-half" name="Address 2">
        Address 2
      </app-input>
    </div>
    <div class="columns is-variable is-mobile is-2-mobile">
      <app-input id="city" v-model="account.city" class="is-marginless column is-half" rules="required" name="City">
        City
      </app-input>
      <app-input
        id="postal_code"
        v-model="account.postal_code"
        class="is-marginless column is-half"
        rules="required"
        name="Post Code"
      >
        Post Code
      </app-input>
    </div>

    <stripe-card :class="`stripe-class`" />

    <input type="submit" class="btn-pink unpublish-btn" value="Update billing details" />
    <template v-if="isFetching">
      <app-modal-loader />
    </template>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import Icon from '@/_shared/icons/icon.vue';
import stripeInstance from '@/_shared/lib/stripe-custom-elements';
import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import StripeCard from '../../payments/stripe-card.vue';

@Component({
  components: {
    Icon,
    StripeCard,
  },
})
export default class BillingUpdate extends Vue {
  public $refs!: {
    form: HTMLFormElement;
  };

  public modalHub = ModalHub;

  private account = {
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    postal_code: '',
    country: 'GB',
  };

  private isFetching: boolean = false;

  public goBackToRetryModal() {
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
    ModalHub.$emit('open', 'modal-payment-due-unpublished', { animation: 'fade-enter' });
  }

  mounted() {
    stripeInstance.create();
  }

  public async updateAccount() {
    let isValid = await this.$refs.form.validate();
    if (!stripeInstance.stripe) return;

    const { stripe, elements } = stripeInstance;

    if (!stripe || !elements) return;

    this.isFetching = true;

    const cardElement = elements.getElement('card');
    if (!cardElement) return;

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${this.account.first_name} ${this.account.last_name}`,
        address: {
          country: this.account.country,
          city: this.account.city,
          line1: this.account.address1,
          line2: this.account.address2,
          postal_code: this.account.postal_code,
        },
      },
    });

    if (paymentMethodResult.error || !paymentMethodResult) {
      this.isFetching = false;
      return;
    }

    const { paymentMethod } = paymentMethodResult;
    if (!paymentMethod) return;

    let result: any;
    try {
      result = await http.post('accounts/vendors/update-stripe-billing', {
        customer_id: this.$store.state.stripeId,
        billing_details: paymentMethod.billing_details,
        payment_method: paymentMethod.id,
      });
    } catch (error) {
      if ((error as any).type === 'StripeCardError') {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: (error as any).raw.message,
          },
        });
      } else {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: error,
          },
        });
      }
      this.isFetching = false;
      return;
    }

    const { clientSecret, status } = result;

    if (status === 'requires_action') {
      stripe.confirmCardPayment(clientSecret).then((result) => {
        if (result.error) {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-error', {
            data: {
              message: result.error.message,
            },
          });
        } else {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-republish-success', { animation: 'fade-enter' });
          this.$router.push(`/vendors/${this.$store.state.vendorSlug}/menu/restaurants}`);
        }
      });
    } else {
      this.isFetching = false;
      ModalHub.$emit('open', 'modal-republish-success', { animation: 'fade-enter' });
      this.$router.push(`/vendors/${this.$store.state.vendorSlug}/menu/restaurants}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-billing-update-wrapper {
  position: absolute;
  background-color: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 345px !important;
  height: 520px !important;

  .input {
    border-radius: 3px;
    height: 2em;
  }
  h1 {
    font-family: Montserrat;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
  }
  .unpublish-btn {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .list-icon {
    margin: 10px;
    width: 20px;
    cursor: pointer;
  }
}
</style>
