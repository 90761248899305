<template>
  <div class="logistics">
    <div class="container">
      <div v-if="zones.length" class="zone-cards">
        <div v-for="zone of zones" :key="zone.name" class="zone-card">
          <div class="logistics-container">
            <div class="zone-card-name">{{ zone.name }}</div>
            <div class="zone-card-middle">
              <span
                v-if="zones.length"
                class="switcher"
                :style="[updating ? { pointerEvents: 'none' } : '']"
                @click.stop=""
              >
                <template v-for="mode of busyModes">
                  <button
                    :key="mode"
                    class="modes"
                    :class="[
                      zone.busyMode === mode ? 'btn-pink' : 'btn-white border-btn',
                      { 'btn-stop': mode === 'STOP' },
                      { 'btn-stop-active': zone.busyMode === 'STOP' && mode === 'STOP' },
                    ]"
                    @click="changeBusyMode(zone, mode)"
                  >
                    {{ mode }}
                  </button>
                </template>
              </span>
            </div>

            <div class="zone-card-right">
              <span
                v-if="deliveryStrategies.length"
                class="switcher"
                :style="[updating ? { pointerEvents: 'none' } : '']"
                @click.stop=""
              >
                <template v-for="deliveryStrategy of deliveryStrategies">
                  <button
                    :key="deliveryStrategy"
                    :class="[zone.deliveryStrategy === deliveryStrategy ? 'btn-pink' : 'btn-white']"
                    @click="changeZoneDeliveryStrategy(zone, deliveryStrategy)"
                  >
                    {{ deliveryStrategy }}
                  </button>
                </template>
              </span>
            </div>
          </div>
          <div class="foodstuff-delivery">
            <div class="foodstuff-delivery__header">
              <p>Foodstuff Delivery Hours</p>
              <span class="add-icon" @click="() => createNewDeliveryInterval(zone)"><app-icon name="add-small" /></span>
            </div>
            <div v-for="hours in zone.deliveryHours" :key="hours.id" class="foodstuff-delivery__interval">
              <FoodstuffDeliveryHours :zone="zone" :hours="hours" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { Zone } from '@/_types/shared.types';

import FoodstuffDeliveryHours from './foodstuff-delivery-hours.vue';

@Component({
  components: {
    FoodstuffDeliveryHours,
  },
})
export default class Logistics extends Vue {
  public zones: any[] = [];
  public deliveryStrategies: string[] = [];
  public updating: boolean = false;
  public busyModes: string[] = [];
  public async mounted() {
    await this.getZones();
    await this.getDeliveryStrategies();
  }

  private async getDeliveryStrategies() {
    this.updating = true;
    await http
      .get(`/admin/logistics/deliveryStrategies`)
      .then((res: { deliveryStrategies: string[] }) => {
        this.deliveryStrategies = res.deliveryStrategies;
      })
      .catch((e: any) => {
        this.deliveryStrategies = [];
        ModalHub.$emit('open', 'modal-error', { data: { message: e } });
      })
      .finally(() => {
        this.updating = false;
      });
  }

  private async getZones() {
    this.updating = true;
    await http
      .get(`/admin/logistics/zones`)
      .then((res: { zones: any[]; busyModes: string[] }) => {
        this.zones = res.zones;
        this.busyModes = res.busyModes;
      })
      .catch((e: any) => {
        this.zones = [];
        ModalHub.$emit('open', 'modal-error', { data: { message: e } });
      })
      .finally(() => {
        this.updating = false;
      });
  }

  private async changeZoneDeliveryStrategy(zone: any, deliveryStrategy: string) {
    this.updating = true;
    zone.deliveryStrategy = deliveryStrategy;
    await http
      .put(`/admin/logistics/zone/${zone.id}`, { deliveryStrategy })
      .catch((e: any) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: e } });
      })
      .finally(async () => {
        await this.getZones();
        this.updating = false;
      });
  }

  private async changeBusyMode(zone: any, busyMode: string) {
    this.updating = true;
    zone.busyMode = busyMode;
    await http
      .put(`/admin/logistics/busy-mode/${zone.id}`, { busyMode })
      .catch((e: any) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: e } });
      })
      .finally(async () => {
        await this.getZones();
        this.updating = false;
      });
  }

  private createNewDeliveryInterval(zone: Zone) {
    const z = this.zones.find((z) => z.id === zone.id);
    z.deliveryHours.push({ start: '00:00', end: '23:59' });
  }
}
</script>

<style lang="scss" scoped>
.logistics-container {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  border-radius: 10px;
  @include bp-500 {
    flex-direction: column;
  }
}
.zone {
  &-cards {
    margin: 40px 0;
  }
  &-card {
    background-color: $white;
    margin-bottom: 20px;
    @include bp-480 {
      font-size: 12px;
    }
    &-name {
      text-transform: capitalize;
      font-weight: 600;
      width: 100px;
      @include bp-500 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
      }
    }
    &-middle {
      text-align: center;
      max-width: 600px;
    }
    &-right {
      margin: 5px;
      .switcher {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include bp-500 {
          flex-direction: row;
        }
      }
      button {
        text-transform: lowercase;
        @include bp-980 {
          padding: 15px 20px;
        }
        @include bp-480 {
          font-size: 12px;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
.switcher .modes {
  margin: 0 0.5rem;
  font-size: 14px;
  width: 11rem;
  height: 50px;
  button {
    padding: 10px 15px;
  }
  @include bp-980 {
    width: 12rem;
  }
  @include bp-480 {
    font-size: 12px;
  }
}
.btn-stop {
  background-color: rgba(255, 0, 0, 0.15);
  color: white;
  &:hover {
    background-color: rgba(255, 0, 0, 0.9);
  }
}
.btn-stop-active {
  background-color: red;
}
.zone-card-middle .border-btn {
  border: 1px solid #e5e5e5 !important;
  margin: 6px;
}
.foodstuff-delivery {
  border-top: 1px solid #ebebeb;
  min-height: 50px;
  padding: 1em;

  &__header {
    display: flex;
    align-items: center;
    .add-icon {
      cursor: pointer;
      margin-left: 1em;
    }
  }
}
</style>
