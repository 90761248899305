<template>
  <div class="modal-add-vendor">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      Modificators category
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmitDebounced">
      <app-input v-model="category.name" rules="required" name="modificators category">
        Modificators category name*
      </app-input>
      <p v-if="data" class="title">Edit modificators</p>
      <p v-else class="title">Create modificators</p>
      <transition-group name="fade">
        <div v-for="(modificator, index) of category.modificators" :key="`modificator-${index}`" class="modificator">
          <div class="modificator-fields">
            <app-input v-model="modificator.name" rules="required" name="Modificator name" class="modificator-name">
              Modificators name*
            </app-input>
            <app-input
              v-model="modificator.price"
              rules="required|decimal"
              name="Modificator price"
              class="modificator-price"
            >
              Price, £*
            </app-input>
            <app-input
              v-model="modificator.taxRate"
              rules="required|tax_rate"
              name="Modificator tax rate"
              class="modificator-tax"
            >
              Tax, %*
            </app-input>
          </div>
          <div v-if="category.modificators.length > 1" class="modificator-delete" @click="deleteField(index)">
            Delete
          </div>
        </div>
      </transition-group>

      <div class="add-another" @click="addOneMore">
        Add another
        <app-icon name="add-small" />
      </div>

      <div v-if="errorMessge" class="error">{{ errorMessge }}</div>
      <button type="submit" class="btn-pink" :disabled="invalid" :class="{ 'btn-disabled': invalid }">Save</button>
      <template v-if="isFetching">
        <app-modal-loader />
      </template>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IModificatorCategory } from '@/menu/modificators.vue';

@Component({})
export default class ModalAddModificator extends Vue {
  public modalHub = ModalHub;
  public errorMessge = '';
  @Prop() data!: { category: IModificatorCategory };

  private isFetching: boolean = false;

  public category: any = {
    name: '',
    modificators: [
      {
        name: '',
        price: '0',
      },
    ],
  };

  mounted() {
    if (this.data && this.data.category) {
      this.category = this.data.category;
    }
  }

  public addOneMore() {
    this.category.modificators.push({
      name: '',
      price: '0',
    });
  }

  public deleteField(index: number) {
    this.category.modificators.splice(index, 1);
  }
  public onSubmitDebounced = _.debounce(this.onSubmit, 200);

  // TODO: remove soldOut array from modifiers in category
  public onSubmit() {
    if (this.data) {
      this.isFetching = true;
      const categoryId = this.category.id;
      delete this.category.id;
      delete this.category.open;
      delete this.category.isActionsOpen;
      http
        .put(`/admin/menu/${this.vendorSlug}/modificators/${categoryId}`, this.category)
        .then((res: { modificatorCategory: IModificatorCategory }) => {
          this.isFetching = false;
          if (res.modificatorCategory) {
            this.$router.go(0);
          }
        })
        .catch((err: any) => {
          this.isFetching = false;
          this.errorMessge = err.message;
        });
    } else {
      this.isFetching = true;
      http
        .post(`/admin/menu/${this.vendorSlug}/modificators`, this.category)
        .then((res: { modificatorCategory: IModificatorCategory }) => {
          this.isFetching = false;
          if (res.modificatorCategory) {
            this.$router.go(0);
          }
        })
        .catch((err: any) => {
          this.isFetching = false;
          this.errorMessge = err.message;
        });
    }
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}
</script>

<style lang="scss" scoped>
.modal-add-vendor {
  &.modal-inner {
    right: 0;
    padding-bottom: 100px;
  }
}
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.title {
  font-size: 20px;
}
.btn-pink {
  width: 100%;
}
.modificator {
  padding: 20px;
  margin: 20px -20px;
  background-color: $lightgray;
  &-fields {
    display: flex;
  }
  &-name {
    width: 100%;
  }
  &-price {
    width: 120px;
    margin-left: 10px;
  }
  &-tax {
    width: 100px;
    margin-left: 10px;
  }
  &-delete {
    font-size: 14px;
    font-weight: 500;
    color: $red;
    cursor: pointer;
    user-select: none;
    transition: 0.5s all;
    &:hover {
      color: $pink;
    }
  }
}
.add-another {
  color: $pink;
  font-weight: 300;
  font-size: 14px;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.5s all;
  display: inline-block;
  .icon {
    margin-left: 10px;
  }
  svg rect {
    transition: 0.5s all;
  }
  &:hover {
    color: darken($pink, 10);
    svg rect {
      fill: darken($pink, 10);
    }
  }
}
.btn-disabled {
  opacity: 0.2;
}
</style>
