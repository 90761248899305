<template>
  <div>
    <app-nav-bar>
      <li v-if="isSuperAdmin"><router-link to="active" tag="a">Active</router-link></li>
      <li><router-link to="history" tag="a">History</router-link></li>
      <li><router-link to="scheduled" tag="a">Scheduled</router-link></li>
    </app-nav-bar>
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Orders extends Vue {
  public isSuperAdmin: boolean = this.$store.state.isSuperAdmin;
}
</script>
