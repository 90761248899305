<template>
  <div class="restaurant">
    <div class="restaurant-left">
      <p class="restaurant-text title">{{ restaurant.name }}</p>
      <p class="restaurant-text bold location">{{ restaurant.locationName }}</p>
      <p v-for="(value, name, index) in restaurant.days" :key="name + index" class="restaurant-text">
        {{ name }} -
        {{ restaurant.days[name][2] ? 'Closed' : `${restaurant.days[name][0]}-${restaurant.days[name][1]}` }}
      </p>
      <div class="categories">
        <p class="restaurant-text bold">Dietaries:</p>
        <p class="restaurant-text">
          {{ restaurant.dietariesTitle }}
        </p>
      </div>
      <div class="categories">
        <p class="restaurant-text bold">Categories:</p>
        <p class="restaurant-text">
          {{ restaurant.categoriesTitle }}
        </p>
      </div>
      <div class="categories">
        <p class="restaurant-text bold">Budget:</p>
        <p class="restaurant-text">
          {{ restaurant.budgetsTitle }}
        </p>
      </div>
      <div class="categories">
        <p class="restaurant-text bold">Perfect for:</p>
        <p class="restaurant-text">
          {{ restaurant.perfectForsTitle }}
        </p>
      </div>
      <div v-if="isSuperAdmin" class="toggle-checkbox">
        <p class="restaurant-text bold">
          <app-checkbox
            v-model="restaurant.isCaterer"
            class="is-marginless"
            textOnTheLeft="true"
            :disabled="!isSuperAdmin"
            >Is caterer?</app-checkbox
          >
        </p>
      </div>
      <div v-if="isSuperAdmin" class="toggle-checkbox">
        <p class="restaurant-text bold">
          <app-checkbox
            v-model="restaurant.clickAndCollect"
            class="is-marginless"
            textOnTheLeft="true"
            :disabled="!isSuperAdmin"
            >Allow placing click & collect orders:</app-checkbox
          >
        </p>
      </div>
      <div v-if="isSuperAdmin" class="toggle-checkbox">
        <p class="restaurant-text bold">
          <app-checkbox
            v-model="restaurant.allowOnDemandOrders"
            class="is-marginless"
            textOnTheLeft="true"
            :disabled="!isSuperAdmin"
            >Allow placing on-demand orders:</app-checkbox
          >
        </p>
      </div>
      <div v-if="isSuperAdmin" class="toggle-checkbox">
        <p class="restaurant-text bold">
          <app-checkbox
            v-model="restaurant.allowScheduledOrders"
            class="is-marginless"
            textOnTheLeft="true"
            :disabled="!isSuperAdmin"
            >Allow placing scheduled orders:</app-checkbox
          >
        </p>
      </div>
      <div
        v-if="isSuperAdmin && restaurant.allowScheduledOrders"
        class="toggle-checkbox hours-input"
        style="margin-left: 10px"
      >
        <p class="restaurant-text bold" style="width: 100%; max-width: 360px">
          Minimum hours in advance required for discount and in scheduled-only menu categories [h]:
        </p>
        <input
          v-model="restaurant.minHoursInAdvance"
          type="number"
          class="input hours-number-input"
          :disabled="!isSuperAdmin || !restaurant.allowScheduledOrders"
          min="0"
        />
      </div>
      <div
        v-if="isSuperAdmin && restaurant.allowScheduledOrders"
        class="toggle-checkbox hours-input"
        style="margin-left: 10px"
      >
        <p class="restaurant-text bold" style="width: 100%; max-width: 360px">
          Minimum cart price required for discount [£]:
        </p>
        <input
          v-model="restaurant.discountMinCartPrice"
          type="number"
          min="0"
          class="input hours-number-input"
          :disabled="!isSuperAdmin"
        />
      </div>
      <div
        v-if="isSuperAdmin && restaurant.allowScheduledOrders"
        class="toggle-checkbox hours-input"
        style="margin-left: 10px"
      >
        <p class="restaurant-text bold" style="width: 100%; max-width: 360px">Discount value after threshold [%]:</p>
        <input
          v-model="restaurant.discountPercentage"
          type="number"
          min="0"
          max="100"
          class="input hours-number-input"
          :disabled="!isSuperAdmin"
        />
      </div>
      <div v-if="isSuperAdmin" class="toggle-checkbox hours-input">
        <p class="restaurant-text bold">Minimum cart price [£]:</p>
        <input
          v-model="restaurant.minCartPrice"
          type="number"
          min="0"
          class="input hours-number-input"
          :disabled="!isSuperAdmin"
        />
      </div>
      <div v-if="restaurant.busyStatus" class="statuses">
        <p class="restaurant-text bold">Restaurant status:</p>
        <button
          class="status"
          :class="[restaurant.busyStatus === 'normal' ? 'btn-pink' : 'btn-white border-btn']"
          @click.prevent="changeRestaurantBusyStatus(restaurant.slug, 'normal')"
        >
          Normal
        </button>
        <button
          class="status"
          :class="[restaurant.busyStatus === 'busy' ? 'btn-pink' : 'btn-white border-btn']"
          @click.prevent="changeRestaurantBusyStatus(restaurant.slug, 'busy')"
        >
          Busy
        </button>
        <button
          class="status"
          :class="[restaurant.busyStatus === 'chaos' ? 'btn-pink' : 'btn-white border-btn']"
          @click.prevent="changeRestaurantBusyStatus(restaurant.slug, 'chaos')"
        >
          Chaos
        </button>
      </div>
    </div>
    <div class="restaurant-right">
      <span @click="deleteRestaurantHandler(index)">
        <app-icon name="delete" class="trash"></app-icon>
      </span>
      <span @click="editRestaurantHandler(index)">
        <app-icon name="pencil" class="trash"></app-icon>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

import { ISettingsRestaurant } from '@/_types/vendor-settings.types';

@Component({})
export default class SettingsRestaurant extends Vue {
  @Prop() restaurant!: ISettingsRestaurant;
  @Prop() index!: number;

  isSuperAdmin = this.$store.state.isSuperAdmin;

  changeRestaurantBusyStatus(restaurantSlug: string, status: string) {
    ModalHub.$emit('restaurant-changeBusyStatus', restaurantSlug, status);
  }

  deleteRestaurantHandler(index: number) {
    ModalHub.$emit('restaurant-delete', index);
  }

  editRestaurantHandler(index: number) {
    ModalHub.$emit('restaurant-startEditing', index);
  }
}
</script>

<style scoped lang="scss">
.restaurant {
  width: 100%;
  min-height: 190px;
  background-color: $lightgray;
  position: relative;
  margin-top: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .restaurant-left {
    margin-left: 10px;
    .restaurant-text {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: $dark;

      &.bold {
        font-weight: 600;
      }
      &.location {
        margin-bottom: 10px;
      }
      &.title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .categories {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      .restaurant-text {
        &.bold {
          margin-right: 5px;
        }
      }
    }
  }
  .restaurant-right {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 30px;
    .trash {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
.toggle-checkbox {
  margin-top: 10px;
  label {
    display: inline-block;
    margin-left: 1em;
  }
}
.statuses {
  margin-top: 10px;
}
.hours-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.hours-number-input {
  height: 25px;
  width: 80px;
}
.status {
  margin-right: 1rem;
  margin-top: 1rem;
  width: 8rem;
  @include bp-980 {
    width: 6rem;
  }
  @include bp-480 {
    font-size: 12px;
  }
}
</style>
