<template>
  <div>
    <ValidationProvider v-slot="{ errors, validate }" tag="div" :name="name" :rules="rules" :bails="false">
      <label class="app-file">
        <input :accept="fileAcceptRules" type="file" @change="validate" @input="handleFileChange" />
        <slot></slot>
        <span class="error">{{ errors[0] }}</span>
      </label>
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppFile extends Vue {
  @Prop() rules: any;
  @Prop() name: any;
  @Prop() type: string | undefined;

  public videoAcceptRules = 'video/mp4, video/x-m4v, video/mov';
  public photoAcceptRules = 'image/png, image/gif, image/jpeg';

  public async handleFileChange(event: any) {
    let file = event.target.files[0];
    // 5MB MAX
    if (file.size < 5 * 1024 * 1024) {
      this.$emit('input', file);
    } else {
      alert('File is too big');
    }
  }

  get fileAcceptRules() {
    if (this.type === 'video') {
      return this.videoAcceptRules;
    }

    if (this.type === 'video-and-photo') {
      return `${this.videoAcceptRules}, ${this.photoAcceptRules}`;
    }

    return this.photoAcceptRules;
  }
}
</script>

<style lang="scss" scoped>
.app-file {
  cursor: pointer;
  input {
    display: none;
  }
}
.error {
  margin-top: 20px;
}
</style>
