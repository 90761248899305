<template>
  <div class="menu">
    <div v-if="restaurants.length">
      <div v-for="(r, rId) of restaurants" :key="r.slug" class="restaurant">
        <app-accordion>
          <template #head>
            <div class="restaurant-name">{{ r.name }}</div>
            <div class="clear-icon" @click.prevent.stop="clearMenuCategoryHandler(r)">
              <app-icon name="delete" />
              Clear menu
            </div>
            <span class="switcher" @click.stop="">
              <button :class="{ 'btn-pink': !r.closed, 'btn-white': r.closed }" @click="closeRestaurant(r, false)">
                Open
              </button>
              <button
                :class="{ 'btn-pink': r.closed, 'btn-white': !r.closed }"
                class="btn-pink"
                @click="closeRestaurant(r, true)"
              >
                Close
              </button>
            </span>
            <span class="add-icon" @click.prevent.stop="openModalAddMenuCategory(r)">
              <app-icon name="add-small" />
            </span>
          </template>
          <template #body>
            <div v-for="(c, cId) of r.menuCategories" :key="c.id" class="category">
              <app-accordion>
                <template #head>
                  <div class="category-name">{{ c.name }}</div>
                  <app-checkbox v-model="c.isScheduledOnly" :disabled="true" @input="onCheckbox(r, c)"
                    >Scheduled only
                    {{ r.minHoursInAdvance ? `(min ${r.minHoursInAdvance} hours in advance)` : '' }}</app-checkbox
                  >
                  <app-popover>
                    <div v-if="cId !== 0" @click="moveMenuCategory('up', c, rId)">
                      <app-icon name="dropdown" class="icon-move-menu-category" style="transform: rotate(180deg)" />
                      Up
                    </div>

                    <div
                      v-if="cId !== r.menuCategories.length - 1"
                      class="icon-move-menu-category"
                      @click="moveMenuCategory('down', c, rId)"
                    >
                      <app-icon name="dropdown" /> Down
                    </div>

                    <div @click="deleteMenuCategory(c, r)"><app-icon name="delete" /> Delete</div>
                    <div @click="openModalEditCategory(c, r)"><app-icon name="pencil" /> Edit</div>
                  </app-popover>
                </template>
                <template #body>
                  <div v-for="(d, dIdx) of c.dishes" :key="d.slug" class="dish">
                    <div class="dish-photo">
                      <img
                        v-if="d.photo && d.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg'"
                        :src="d.photo"
                        :alt="d.name"
                      />
                    </div>
                    <p class="dish-name">{{ d.name }}</p>
                    <p class="dish-price">
                      <span
                        :class="{
                          'dish-price--discounted': !!d.discountPercents,
                        }"
                        >£{{ d.price.toFixed(2) }}</span
                      >
                      <span v-if="d.discountPercents">£{{ d.discountedPrice.toFixed(2) }}</span>
                    </p>
                    <app-switcher v-model="d.sold" @input="markDishSoldOut(d, r)">sold out</app-switcher>
                    <span
                      class="dish-discount"
                      :class="{ 'dish-discount--active': d.discountPercents }"
                      @click="openModalSpecifyDiscount(d, r, c)"
                    >
                      <app-icon name="discount" />
                    </span>
                    <app-popover>
                      <div @click="openModalSpecifyDiscount(d, r, c)"><app-icon name="pencil" /> Set discount</div>
                      <div @click="removeDiscount(d, r, c)"><app-icon name="delete" /> Remove discount</div>
                      <div v-if="dIdx !== 0" @click="moveDish('up', rId, c, cId, dIdx, r.slug)">
                        <app-icon name="dropdown" class="icon-move-menu-category" style="transform: rotate(180deg)" />
                        Up
                      </div>
                      <div
                        v-if="dIdx !== c.dishes.length - 1"
                        class="icon-move-menu-category"
                        @click="moveDish('down', rId, c, cId, dIdx, r.slug)"
                      >
                        <app-icon name="dropdown" /> Down
                      </div>
                    </app-popover>
                  </div>
                </template>
              </app-accordion>
            </div>
          </template>
        </app-accordion>
      </div>
    </div>

    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span>
        Empty here (you can add restaurants in your
        <router-link :to="`/vendors/${vendorSlug}/settings`" tag="a">
          <i class="is-pink cup" @click="modalHub.$emit('close')">settings</i>
        </router-link>
        )
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({})
export default class RestaurantsMenu extends Vue {
  public restaurants: IRestaurant[] = [];

  public mounted() {
    this.getRestaurants();
    this.subscribeForMenuCategories();
    this.subscribeForDiscount();
    ModalHub.$on('on-confirm', (restaurant: IRestaurant) => {
      this.clearMenuCategory(restaurant);
    });
  }

  public beforeDestroy() {
    ModalHub.$off('on-confirm');
  }

  private onCheckbox(restaurant: IRestaurant, menuCategory: IRestaurantMenuCategory) {
    http
      .patch(
        `/admin/menu/${this.vendorSlug}/restaurants/${restaurant.slug}/menu-categories/${menuCategory.slug}/type`,
        { isScheduledOnly: menuCategory.isScheduledOnly },
      )
      .catch((err: any) => console.error(err));
  }

  public getRestaurants() {
    http.get(`/admin/menu/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      let restaurants: IRestaurant[] = res.restaurants;
      for (let r in restaurants) {
        restaurants[r].menuCategories = this.sortMenuCategories(restaurants[r].menuCategories);
      }
      this.restaurants = restaurants;
    });
  }

  public sortMenuCategories(data: any) {
    return data.sort((a: any, b: any) => parseFloat(a.order) - parseFloat(b.order));
  }

  public openModalAddMenuCategory(restaurant: IRestaurant) {
    ModalHub.$emit('open', 'modal-add-menu-category', {
      animation: 'slide-left',
      data: { restaurantSlug: restaurant.slug, isCaterer: restaurant.isCaterer },
    });
  }

  public moveMenuCategory(move: string, c: any, rId: number) {
    let data: INewOrder = {
      newOrder: [
        {
          id: c.id,
          order: c.order,
        },
      ],
    };

    if (move === 'up') {
      data.newOrder[0].order--;
    } else if (move === 'down') {
      data.newOrder[0].order++;
    }

    let arrOldOrder: any = this.restaurants[rId].menuCategories.filter(
      (i: IRestaurantMenuCategory) => i.order === data.newOrder[0].order,
    );

    if (arrOldOrder.length > 0) {
      for (let data in arrOldOrder) {
        if (move === 'up') {
          arrOldOrder[data].order++;
        } else if (move === 'down') {
          if (arrOldOrder[data].order !== 0) {
            arrOldOrder[data].order--;
          }
        }

        delete arrOldOrder[data]['name'];
        delete arrOldOrder[data]['availableFrom'];
        delete arrOldOrder[data]['availableTo'];
        delete arrOldOrder[data]['dishes'];
        delete arrOldOrder[data]['slug'];
      }

      data.newOrder = data.newOrder.concat(arrOldOrder);
    }
    http.put(`/admin/menu/${this.vendorSlug}/restaurants`, data).then((res: any) => {
      this.getRestaurants();
    });
  }

  public moveDish(
    move: string,
    restaurantId: number,
    menuCategory: IRestaurantMenuCategory,
    menuCategoryIdx: number,
    dishIdx: number,
    restaurantSlug: string,
  ) {
    let dishesInCategory = this.restaurants[restaurantId].menuCategories[menuCategoryIdx].dishes;

    if (move === 'down') {
      dishesInCategory = this.moveElement(dishesInCategory, dishIdx, dishIdx + 1);
    } else if (move === 'up') {
      dishesInCategory = this.moveElement(dishesInCategory, dishIdx, dishIdx - 1);
    }

    const dishIdsWithOrder = dishesInCategory.map((d: any, idx: number) => ({ dishId: d.id, order: idx }));
    const data = { dishIdsWithOrder };

    http
      .put(
        `/admin/menu/${this.vendorSlug}/restaurants/${restaurantSlug}/menu-categories/${menuCategory.slug}/order`,
        data,
      )
      .then((res: any) => {
        this.getRestaurants();
      });
  }

  private moveElement(array: number[], fromIndex: number, toIndex: number) {
    const element = array.splice(fromIndex, 1)[0];

    array.splice(toIndex, 0, element);

    return array;
  }

  public openModalEditCategory(menuCategory: IRestaurantMenuCategory, restaurant: IRestaurant) {
    ModalHub.$emit('open', 'modal-add-menu-category', {
      animation: 'slide-left',
      data: { menuCategory, restaurantSlug: restaurant.slug, isCaterer: restaurant.isCaterer },
    });
  }

  public subscribeForMenuCategories() {
    ModalHub.$on('close', (data: { menuCategory: IRestaurantMenuCategory; restaurantSlug: string }) => {
      if (data && data.menuCategory) {
        for (let restaurant of this.restaurants) {
          if (restaurant.slug === data.restaurantSlug) {
            const matchingMCIndex = restaurant.menuCategories.findIndex((mc) => mc.id === data.menuCategory.id);
            if (matchingMCIndex >= 0) {
              const existingMCs = [...restaurant.menuCategories];
              restaurant.menuCategories = [];
              for (let i = 0; i < existingMCs.length; i += 1) {
                if (i === matchingMCIndex) restaurant.menuCategories.push(data.menuCategory);
                else restaurant.menuCategories.push(existingMCs[i]);
              }
            } else {
              restaurant.menuCategories.push(data.menuCategory);
            }
            break;
          }
        }
      }
    });
  }

  public subscribeForDiscount() {
    ModalHub.$on('close', this.findDishAndSetDiscount);
  }

  public deleteMenuCategory(menuCategory: IRestaurantMenuCategory, restaurant: IRestaurant) {
    return http
      .delete(`/admin/menu/${this.vendorSlug}/restaurants/${restaurant.slug}/menu-categories/${menuCategory.slug}`)
      .then((res: { success: boolean }) => {
        if (!res.success) return false;
        for (let restaurant of this.restaurants) {
          if (restaurant.slug === restaurant.slug) {
            restaurant.menuCategories.splice(restaurant.menuCategories.indexOf(menuCategory), 1);
            break;
          }
        }
      });
  }

  public clearMenuCategoryHandler(restaurant: IRestaurant) {
    ModalHub.$emit('open', 'modal-are-you-sure', {
      data: {
        title: 'you want to clear the menu?',
        text: 'This action cannot be undone.',
        variable: restaurant,
      },
    });
  }

  public clearMenuCategory(restaurant: IRestaurant) {
    const allCategories = restaurant.menuCategories.map((menu: IRestaurantMenuCategory) =>
      this.deleteMenuCategory(menu, restaurant),
    );
    return Promise.all(allCategories);
  }

  public openModalSpecifyDiscount(dish: IDish, restaurant: IRestaurant, menuCategory: IRestaurantMenuCategory) {
    ModalHub.$emit('open', 'modal-specify-discount', {
      animation: 'slide-left',
      data: {
        dish,
        vendorSlug: this.vendorSlug,
        restaurantSlug: restaurant.slug,
        menuCategorySlug: menuCategory.slug,
      },
    });
  }

  public removeDiscount(dish: IDish, restaurant: IRestaurant, menuCategory: IRestaurantMenuCategory) {
    http
      .delete(
        `/admin/menu/${this.vendorSlug}/restaurants/${restaurant.slug}/menu-categories/${menuCategory.slug}/dishes/${dish.slug}/discount`,
      )
      .then((res: { success: boolean }) => {
        if (res.success) {
          this.findDishAndSetDiscount({
            discountedPrice: null,
            discountPercents: null,
            restaurantSlug: restaurant.slug,
            menuCategorySlug: menuCategory.slug,
            dishSlug: dish.slug,
          });
        }
      });
  }

  public findDishAndSetDiscount(data: {
    discountedPrice: any;
    discountPercents: any;
    menuCategorySlug: string;
    dishSlug: string;
    restaurantSlug: string;
  }) {
    if (data) {
      for (let restaurant of this.restaurants) {
        if (restaurant.slug === data.restaurantSlug) {
          for (let menuCategory of restaurant.menuCategories) {
            if (menuCategory.slug === data.menuCategorySlug) {
              for (let dish of menuCategory.dishes) {
                if (dish.slug === data.dishSlug) {
                  dish.discountedPrice = data.discountedPrice;
                  dish.discountPercents = data.discountPercents;
                  // Force update we need when new dish comes from modal (it`s not reactive because added on the fly)
                  this.$forceUpdate();
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  public markDishSoldOut(dish: IDish, restaurant: IRestaurant) {
    http
      .post(`/admin/menu/${this.vendorSlug}/restaurants/${restaurant.slug}/dishes/${dish.slug}/sold-out`, {})
      .then((res: { success: boolean; soldOut: boolean }) => {
        if (res.success) {
          for (let r of this.restaurants) {
            if (r.slug == restaurant.slug) {
              for (let mc of r.menuCategories) {
                for (let d of mc.dishes) {
                  if (d.slug == dish.slug) {
                    d.sold = res.soldOut;
                  }
                }
              }
            }
          }
        }
      });
  }

  public closeRestaurant(restaurant: IRestaurant, flag: boolean) {
    if (restaurant.closed !== flag) {
      restaurant.closed = flag;
      http.post(`/admin/vendors/${this.vendorSlug}/restaurants/${restaurant.slug}/close`, {});
    }
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}

interface IRestaurant {
  id: number;
  name: string;
  closed: boolean;
  allowOnDemandOrders: boolean;
  menuCategories: IRestaurantMenuCategory[];
  slug: string;
  isCaterer: boolean;
}

interface INewOrderItem {
  id: number;
  order: number;
}

interface INewOrder {
  newOrder: INewOrderItem[];
}

export interface IRestaurantMenuCategory {
  id?: number;
  name: string;
  photo?: string;
  isScheduledOnly: boolean;
  availableFrom: string;
  availableTo: string;
  dishes: number[] | any[];
  order?: number;
  slug: string;
}

export interface IDish {
  id: number;
  name: string;
  photo: string;
  price: number;
  discountedPrice: number | null;
  discountPercents: number | null;
  sold: boolean;
  slug: string;
}
</script>

<style lang="scss" scoped>
.menu {
  padding: 20px 0;
}
.restaurant {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: auto;
  }
  .switcher {
    margin: 0 20px 0 0;
    color: $dark;
    @include bp-480 {
      margin-right: 5px;
    }
    button {
      margin: 3px 10px;
      height: 33px;
    }
  }
  .delete-icon {
    margin-right: 10px;
  }
  .clear-icon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $black;
    margin-right: 10px;
  }
}
.category {
  position: relative;
  &:last-child {
    padding-bottom: 40px;
  }
  .accordion {
    background-color: $lightgray;
  }
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
  }
  .popover {
    margin-left: auto;
    color: $dark;
  }
  .app-switcher {
    margin: 0 20px 0 auto;
  }
}
.dish {
  background-color: $white;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-photo {
    height: 60px;
    width: 60px;
    margin: -20px 20px -20px 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    &--discounted {
      margin-right: 10px;
      text-decoration: line-through;
    }
  }
  &-discount {
    cursor: pointer;
    @include bp-650 {
      display: none;
    }
    &--active {
      circle {
        fill: $pink;
        stroke: $pink;
      }
      path {
        fill: $white;
      }
    }
  }
  .popover {
    margin-left: 20px;
    @include bp-650 {
      margin-left: 0;
    }
  }
}
.c-checkbox {
  margin-top: 15px;
}
</style>
