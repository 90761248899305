/**
 * I found this rule on 2 version of vee-validate
 * https://github.com/logaretm/vee-validate/releases/tag/2.2.15 in folder rules
 * I don`t know why, but it was deleted in version 3
 * @param value
 * @param decimals
 * @param separator
 */

const validate = (value: any, { decimals = '2', separator = '.' } = {}): boolean => {
  if (!value || value === '') {
    return false;
  }

  if (Array.isArray(value)) {
    return value.every((val) => validate(val, { decimals, separator }));
  }

  if (Number(decimals) === 0) {
    return /^-?\d*$/.test(value);
  }

  const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
  const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

  if (!regex.test(value)) {
    return false;
  }

  const parsedValue = parseFloat(value);

  // eslint-disable-next-line
  return parsedValue === parsedValue;
};

const decimal: any = {
  validate,
};

export default decimal;
