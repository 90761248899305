/**
 * Serialize JS Object with files to FormData
 * @param dataObject
 * @return {FormData}
 */

export const toFormData = (dataObject: any): FormData => {
  const formData = new FormData();
  for (const k in dataObject) {
    if (dataObject.hasOwnProperty(k)) {
      if (dataObject[k] && dataObject[k].files) {
        const file = dataObject[k].files[0];
        formData.append(k, file, file.name);
      } else if (dataObject[k] && dataObject[k] instanceof File) {
        formData.append(k, dataObject[k], dataObject[k].name);
      } else if (Array.isArray(dataObject[k])) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else if (typeof dataObject[k] === 'object' && !(dataObject[k] instanceof File)) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else {
        formData.append(k, dataObject[k]);
      }
    }
  }
  return formData;
};
