<template>
  <div class="header">
    <div class="container">
      <ul class="nav">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AppNavBar extends Vue {}
</script>

<style lang="scss" scoped>
.header {
  background-color: $white;
  height: 80px;
  border-bottom: 1px solid #f3f4f8;
  .container {
    background-color: $white;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.nav {
  display: flex;
  align-items: center;
  margin: 0 -15px;
  li {
    font-size: 16px;
    font-weight: 400;
    margin: 0 15px;
    a {
      height: 80px;
      line-height: 80px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: 0.5s all;
      @include bp-480 {
        height: 40px;
        line-height: 16px;
      }
    }
    a:hover {
      color: $pink;
    }
    a.router-link-exact-active {
      color: $pink;
      border-bottom: 2px solid $pink;
    }
  }
}
</style>
