<template>
  <div class="modal-add-dish-modificator">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      {{ isEdited ? 'Edit Dish Modificator Group' : 'Add Dish Modificator Group' }}
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmit">
      <app-input v-model="modificatorGroup.name" rules="required" name="Category name">Category name*</app-input>

      <app-checkbox v-model="modificatorGroup.required" class="is-rounded">Mandatory</app-checkbox>

      <div class="limit-wrapper">
        <app-input
          v-if="modificatorGroup.required"
          v-model="modificatorGroup.limitMin"
          rules="required|min_value:1|numeric"
          name="Limit"
        >
          Min items user must select
        </app-input>
        <app-input v-model="modificatorGroup.limitMax" rules="required|min_value:1|numeric" name="Limit">
          Max items user can select
        </app-input>
      </div>

      <div class="subtitle">+ Add modificators</div>

      <div v-if="modificatorCategories.length" class="modificators">
        <div v-for="c of modificatorCategories" :key="c.id" class="category">
          <app-accordion :is-open="c.open">
            <template #head>
              <div class="category-name">{{ c.name }}</div>
            </template>
            <template #body>
              <div v-for="m of c.modificators" :key="m.id" class="modificator">
                <app-checkbox v-model="m.checked" class="is-rounded is-marginless">
                  <p class="modificator-name">{{ m.name }}</p>
                  <p v-if="m.price" class="modificator-price">+ £{{ m.price.toFixed(2) }}</p>
                </app-checkbox>
              </div>
            </template>
          </app-accordion>
        </div>
      </div>

      <div v-else class="empty-holder">
        <app-icon name="empty" />
        <span>
          You don’t have any modificators you can add it
          <router-link :to="{ name: 'modificators', params: { id: vendorId } }" tag="a">
            <i class="is-pink cup" @click="modalHub.$emit('close')">here</i>
          </router-link>
        </span>
      </div>

      <button type="submit" class="btn-pink" :disabled="invalid" :class="{ 'btn-disabled': invalid }">
        {{ isEdited ? 'Edit' : 'Add' }}
      </button>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IModificatorGroup } from '@/menu/add-dish.vue';
import { IModificatorCategory } from '@/menu/modificators.vue';

@Component({})
export default class ModalAddDishModificators extends Vue {
  @Prop() data!: { modificatorGroup: IModificatorGroup };
  public modalHub = ModalHub;
  public modificatorCategories: IModificatorCategory[] = [];
  public isEdited = false;

  public modificatorGroup: IModificatorGroup = {
    name: '',
    required: false,
    limitMin: 0,
    limitMax: 1,
    modificators: [],
  };

  public mounted() {
    this.getModificators();
    if (this.data) {
      this.modificatorGroup = this.data.modificatorGroup;
      this.isEdited = true;
    }

    // Ensure limitMin is 0 if category is non-mandatory
    // and at least 1 if category is mandatory:
    if (this.modificatorGroup.required && !this.modificatorGroup.limitMin) this.modificatorGroup.limitMin = 1;
    watch(
      () => this.modificatorGroup.required,
      (isRequired) => {
        this.modificatorGroup.limitMin = isRequired ? 1 : 0;
      },
    );
  }

  private getModificators() {
    http
      .get(`/admin/menu/${this.vendorSlug}/modificators`)
      .then((res: { modificatorCategories: IModificatorCategory[] }) => {
        this.modificatorCategories = res.modificatorCategories;
        this.resetCategories();
        this.checkedSelectedModificators();
      });
  }

  public onSubmit() {
    this.modificatorGroup.modificators = [];
    this.modificatorCategories.map((c: IModificatorCategory) => {
      c.open = false;
      c.modificators.map((m: any) => {
        if (m.checked) {
          this.modificatorGroup.modificators.push(m);
        }
      });
    });

    if (this.isEdited) {
      this.modalHub.$emit('close');
    } else {
      this.modalHub.$emit('close', { modificatorGroup: this.modificatorGroup });
    }
  }

  private resetCategories() {
    this.modificatorCategories.map((c: IModificatorCategory) => {
      c.open = false;
      c.modificators.map((m: any) => {
        m.checked = false;
      });
    });
  }

  private checkedSelectedModificators() {
    for (let modificator of this.modificatorGroup.modificators) {
      for (let c of this.modificatorCategories) {
        for (let m of c.modificators) {
          if (modificator.id === m.id) {
            m.checked = true;
            c.open = true;
            break;
          }
        }
      }
    }
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}
</script>

<style lang="scss" scoped>
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.empty-holder {
  .is-pink {
    display: inline-block;
  }
}
.btn-pink {
  width: 100%;
}
.category {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
  }
  .popover {
    margin-left: auto;
  }
}
.modificators {
  background-color: $lightgray;
  margin: 40px -40px;
  padding: 20px 40px;
}
.modificator {
  background-color: $lightgray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  &:first-child {
    margin-top: -20px;
  }
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
  .icon {
    margin-left: auto;
    cursor: pointer;
  }
}
.limit-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 100%;
  margin-bottom: 40px;
}
.btn-disabled {
  opacity: 0.2;
}
</style>
