<template>
  <div class="onboarding-terms">
    <template v-if="isFetching">
      <app-modal-loader />
    </template>
    <header>
      <h1 class="fontfamily-recoleta fontsize-30">Foodstuff Contract</h1>
      <p class="color-coal60 fontsize-20">Please read carefully and click ‘I agree’ at the bottom to continue.</p>
    </header>

    <Terms></Terms>
    <div class="text-center">
      <button class="btn-ham sofia" @click="confirmTerms">
        I agree
        <Icon icon="arrow" direction="right" class="m-l-10" />
      </button>
    </div>
    <div class="top">
      Having trouble?
      <!-- TODO: link this up to something -->
      <a> Get Help </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import Icon from '@/_shared/icons/icon.vue';
import http from '@/_shared/utils/http';

import Terms from '../../auth/termsOfService.vue';

@Component({
  components: { Terms, Icon },
})
export default class OnboardingTerms extends Vue {
  private isFetching: boolean = false;
  @Prop() vendor: any;
  private confirmTerms() {
    this.isFetching = true;
    http
      .post(`/accounts/vendors/${this.vendor.slug ? this.vendor.slug : this.$store.state.vendorSlug}/confirm-terms`, {})
      .then((res: any) => {
        this.$store.commit('isTerms', true);
        this.isFetching = false;
        this.$emit('isTerms', true);
      });
  }
}
</script>

<style lang="scss" scoped>
header {
  text-align: center;
  margin-top: 60px;
}
.top {
  position: absolute;
  bottom: 22px;
  right: 40px;
  @include fontsize-20;
  @include bp-920 {
    bottom: 18px;
    right: 30px;
  }
  @include bp-768 {
    display: none;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
    color: #3e69bd;
    &:hover {
      color: $hamdark;
    }
  }
}
.sofia {
  font-family: $Sofia;
  font-weight: 400;
  width: 314px;
  margin-top: 30px;
}
</style>
