<template>
  <div class="orders-scheduled">
    <div>
      <div class="container">
        <div class="orders-filters">
          <app-select
            v-model="restaurantSlug"
            :options="restaurants"
            option-value="slug"
            select-title="All restaurants"
            @input="getScheduledOrders"
          />
          <app-input
            v-model="searchOrderId"
            class="search is-marginless"
            placeholder="Search by order id..."
            @input="getScheduledOrders"
          />
        </div>
      </div>
      <audio ref="audio" src="/done-for-you.mp3" class="orderAudio" />

      <div v-if="!loading" class="orders-block">
        <div class="container">
          <order-row
            v-for="order of orders"
            :key="order.id"
            :order="order"
            class="order delivered"
            @onClick="openOrderInfo"
          >
            <div class="order-price">
              <p>Order Id</p>
              <div class="price">{{ order.friendlyId }}</div>
            </div>

            <div class="order-price">
              <p>Type</p>
              <div class="price">{{ order.type }}</div>
            </div>

            <div class="order-status">
              <p>Order status</p>
              <p
                class="status"
                :class="{
                  green: order.status === 'delivered',
                  red: order.status === 'declined',
                }"
              >
                {{ order.status }}
              </p>
            </div>

            <button v-if="isSuperAdmin" class="btn-red" @click.stop="openOrderInfo" @click="cancel(order.id)">
              Cancel order
            </button>
          </order-row>
        </div>
      </div>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import http from '@/_shared/utils/http';

import { User } from '@/_types/shared.types';

import { IRestaurant } from '@/vendor/dashboard.vue';

import ModalHub from '../../_shared/modals/modal-hub';

@Component({})
export default class OrdersScheduled extends Vue {
  public restaurants: IRestaurant[] = [];
  public orders: any[] = [];
  public searchOrderId = '';
  public restaurantSlug = '';
  public loading = false;

  public user: User | null = null;

  public modalHub = ModalHub;
  public ordersLength: number = 0;
  public socket: any;

  public abstractSocket: number = 0;
  public abstractSocketTimer: any;

  public isSuperAdmin: boolean = this.$store.state.isSuperAdmin;

  async mounted() {
    this.getRestaurants();
    this.getScheduledOrders();
    this.getUser();
  }

  public created() {
    ModalHub.$on('updateOrders', this.getScheduledOrders);
  }

  public getScheduledOrders() {
    this.loading = true;

    let filters = '?statuses=scheduled';
    filters += `&startFoodDueTime=${new Date(new Date().setHours(1, 0, 0, 0)).toISOString()}`;
    if (this.searchOrderId) filters += `&friendlyId=${this.searchOrderId}`;
    if (this.restaurantSlug) filters += `&restaurantSlug=${this.restaurantSlug}`;

    http
      .get(`/admin/${this.vendorSlug}/orders/${filters}`)
      .then((res: any) => {
        const ordersResponse = [...res.scheduledOrders];
        this.ordersLength = ordersResponse.length;
        this.orders = ordersResponse;
        this.abstractSocket = ++this.abstractSocket;
        this.loading = false;
      })
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      });
  }

  public getRestaurants() {
    http.get(`/admin/vendors/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      this.restaurants = res.restaurants;
    });
  }

  public openOrderInfo(data: { orderId: number; vendorSlug: string }) {
    ModalHub.$emit('open', 'modal-history-info', { data });
  }

  public cancel(orderId: string) {
    ModalHub.$emit('open', 'modal-sure-delete', {
      data: { user: this.user, data: { vendorSlug: this.vendorSlug, orderId } },
    });
  }

  public getUser() {
    this.user = this.$store.state.user;
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}
</script>

<style lang="scss" scoped>
.orders-scheduled {
  padding-bottom: 20px;
}
.orders-filters {
  padding: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .app-input {
    width: 260px;
  }
  .search {
    .label {
      display: none;
    }
  }
  @include bp-480 {
    flex-direction: column;
  }
}
.orders-block {
  margin-bottom: 40px;
}
.order {
  font-size: 14px;
  margin: 0 -20px 20px;
  .order-status {
    margin-left: auto;
    margin-right: 60px;
    .status {
      font-weight: bold;
      margin-top: 10px;
      &.red {
        color: $red;
      }
      &.green {
        color: $green;
      }
    }
  }
  .order-price {
    margin-left: 60px;
    .price {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
