<template>
  <div class="onboarding">
    <template v-if="isFetching">
      <app-modal-loader />
    </template>
    <template v-if="!isFetching">
      <AsidedLayout>
        <!-- TOP -->
        <template #top>
          Having trouble?
          <!-- TODO: link this up to something -->
          <a> Get Help </a>
        </template>

        <!-- ASIDE -->
        <template #aside>
          <template v-if="currentStep === Step.SIGNUP">
            <header class="asided-header">
              <h2 class="fontfamily-recoleta fontsize-40">Bringing a fresh look to food delivery.</h2>
              <p class="fontfamily-recoleta fontsize-24">Join the ethical marketplace. No big brands. No BS.</p>
            </header>
          </template>
          <template v-else-if="[Step.TERMS, Step.BILLING].includes(currentStep)">
            <hide-at :breakpoints="{ small: 769 }" breakpoint="small">
              <div class="aside-list">
                <h2 class="fontfamily-recoleta aside-list-item step-done">
                  <app-icon name="check" class="list-icon" />
                  Account registration
                </h2>
                <h2 class="fontfamily-recoleta aside-list-item" :class="currentStep === 2 && 'step-done'">
                  <Icon v-if="currentStep === 1" icon="arrow" direction="right" class="list-icon" />
                  <app-icon v-else name="check" class="list-icon" />
                  Foodstuff Contract
                </h2>
                <h2 class="fontfamily-recoleta aside-list-item step-next" :class="currentStep === 2 && 'step-now'">
                  <app-icon v-if="currentStep === 1" name="dash" class="list-icon" />
                  <Icon v-else icon="arrow" direction="right" class="list-icon" />
                  Billing & Payments
                </h2>
              </div>
            </hide-at>
            <show-at breakpoint="small">
              <div class="progress-container">
                <div class="progress-column" :class="(currentStep === 1 || currentStep === 2) && 'step-reg'">
                  <div class="circle" :class="(currentStep === 1 || currentStep === 2) && 'step-reg'">
                    <app-icon name="check" class="circle-icon" />
                  </div>
                  <h2 class="progres-title">Registration</h2>
                </div>
                <div class="line" :class="(currentStep === 1 || currentStep === 2) && 'step-reg-line'" />
                <div class="progress-column" :class="currentStep === 2 && 'step-reg'">
                  <div class="circle" :class="currentStep === 2 && 'step-reg'">
                    <app-icon v-if="currentStep === 2" name="check" class="circle-icon" />
                  </div>
                  <h2 class="progres-title">Contract</h2>
                </div>
                <div class="line" :class="currentStep === 2 && 'step-reg-line'" />
                <div class="progress-column">
                  <div class="circle"><app-icon v-if="currentStep === 1" name="dash" class="circle-icon" /></div>
                  <h2 class="progres-title">Billing</h2>
                </div>
              </div>
            </show-at>
          </template>
        </template>

        <!-- BODY -->
        <template #body>
          <div class="onboarding-body">
            <template v-if="currentStep === Step.SIGNUP">
              <OnboardingSignUp @isSignup="isSignup" />
            </template>
            <template v-else-if="currentStep === Step.TERMS">
              <OnboardingTerms :vendor="vendor" @isTerms="isTerms" />
            </template>
            <template v-else-if="currentStep === Step.BILLING">
              <OnboardingBilling :vendor="vendor" />
            </template>
            <template v-else>
              <app-modal-loader />
            </template>
          </div>
        </template>
      </AsidedLayout>
    </template>

    <!-- LOADING -->
    <template v-else>
      <AsidedLayout>
        <template #body>
          <app-modal-loader />
        </template>
      </AsidedLayout>
    </template>
  </div>
</template>

<script lang="ts">
import { hideAt, showAt } from 'vue-breakpoints';
import { Component, Vue } from 'vue-property-decorator';

import Icon from '@/_shared/icons/icon.vue';
import AsidedLayout from '@/_shared/layouts/asided-layout/asided-layout.vue';
import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import OnboardingBilling from './onboarding/step-billing.vue';
import OnboardingSignUp from './onboarding/step-signup.vue';
import OnboardingTerms from './onboarding/step-terms.vue';

enum Step {
  SIGNUP = 0,
  TERMS = 1,
  BILLING = 2,
  COMPLETED = 100,
}

@Component({
  components: {
    AsidedLayout,
    OnboardingSignUp,
    OnboardingTerms,
    OnboardingBilling,
    Icon,
    hideAt,
    showAt,
  },
  computed: {
    Step: () => Step,
  },
  watch: {
    currentStep: function (step) {
      if (step === Step.COMPLETED) this.$router.push('/sign-in');
    },
  },
})
export default class Onboarding extends Vue {
  private isFetching: boolean = false;
  private currentStep: Step = 0;

  private vendor = {
    id: null,
    isTermsConfirmed: false,
    stripeAccountId: '',
    slug: '',
  };

  created() {
    if (!this.code && !this.$store.state.isTerms) {
      return (this.currentStep = 1);
    }

    if (!this.code && !this.$store.state.stripeId) {
      return (this.currentStep = 2);
    }

    this.isFetching = true;
    if (this.code) {
      http
        .get(`/accounts/vendors/account-setup/${this.code}`)
        .then((res: any) => {
          this.isFetching = false;
          if (!res.accountCreated) {
            this.vendor = res.vendor;
            this.currentStep = 0;
            return;
          }
          if (res.accountCreated && !res.vendor.isTermsConfirmed) {
            this.vendor = res.vendor;
            this.currentStep = 1;

            return;
          }
          if (res.accountCreated && res.vendor.isTermsConfirmed && !res.vendor.stripeAccountId) {
            this.vendor = res.vendor;
            this.currentStep = 2;
            return;
          } else {
            this.currentStep = 100;
            return;
          }
        })
        .catch((err: any) => {
          ModalHub.$emit('open', 'modal-error', {
            data: {
              message: err,
            },
          });
        });
    } else {
      this.$router.push('/sign-in');
    }
  }

  isSignup(vendor: any) {
    this.vendor = vendor;
    this.currentStep = 1;
  }

  isTerms(value: boolean) {
    this.vendor.stripeAccountId ? (this.currentStep = 100) : (this.currentStep = 2);
  }

  get code() {
    return this.$route.query['code'];
  }
}
</script>

<style lang="scss" scoped>
.onboarding {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .asidedLayout {
    min-height: 100%;
  }
  &-steps {
    height: 100%;
  }
  .asided-header {
    font-weight: 100;

    @include bp-768 {
      display: none;
    }
    > :not(:first-child) {
      margin-top: 30px;
      @include bp-768 {
        margin-top: 10px;
      }
    }
    p {
      line-height: 1.3;
    }
    .asided-body {
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 30px;
      line-height: 1.3;

      > :not(:first-child) {
        margin-top: 12px;
      }
      &::after {
        opacity: 0.1;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 210px;
        height: 1px;
        margin: 0 auto;
        background-color: #000;
        @include bp-768 {
          opacity: 0.6;
        }
      }
    }
  }
}
.aside-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  @include bp-768 {
    height: 130px;
  }
}
.aside-list-item {
  font-size: 25px;
  line-height: 34px;
  @include bp-1300 {
    font-size: 20px;
  }
}
.list-icon {
  width: 25px;
  margin-right: 20px;
}
.step-done {
  color: rgba(0, 0, 0, 0.4);
  font-weight: normal;
}
.step-next {
  color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
}
.step-now {
  color: black;
  font-weight: 600;
}
.progress-container {
  display: flex;
  position: relative;
  @include bp-480 {
    width: 320px;
  }
  @include bp-380 {
    width: 260px;
  }
}
.progress-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  height: 30px;
  width: 30px;
  border: 1px solid black;
  border-radius: 25px;
  position: relative;
  display: flex;
  align-items: center;
}
.circle-icon {
  margin: 0 auto;
  text-align: center;
}
.line {
  margin: auto 0;
  line-height: 20px;
  text-align: center;
  padding: 0;
  width: 140px;
  height: 2px;
  background-color: black;
}
.progres-title {
  font-family: $Sofia;
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  bottom: -70%;
}
.step-reg {
  color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
}
.step-reg-line {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
