<template>
  <ValidationObserver
    id="add-coupon-form"
    v-slot="{ invalid }"
    ref="form"
    class="modal-add-coupon"
    tag="form"
    @submit.prevent="addCoupon"
  >
    <transition name="slide-left">
      <ModalSelectCouponRestaurants
        v-if="selectCouponRestaurantsModalActive"
        class="modal-inner"
        :saveSelectedRestaurants="saveSelectedRestaurants"
        :selectedRestaurants="selectedRestaurants"
        :onClose="() => (selectCouponRestaurantsModalActive = false)"
      />
    </transition>
    <div class="columns is-gapless modal-header">
      <div class="column is-3 close-modal-wrapper" @click="closeAddCoupon()">
        <router-link to="/coupons" class="close-modal">
          <app-icon name="close"></app-icon>
        </router-link>
      </div>
      <div class="column is-6 modal-header-title">
        <p>Create coupon</p>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="modal-input">
      <app-input v-model="form.coupon.name" rules="required" name="Name">Name of coupon* </app-input>
      <label class="label">Coupon period</label>
      <app-datepicker v-model="dateRange" :autoApply="'autoApply'" class="dataRange" opens="right" />
      <label class="label">Which restaurants?</label>
      <div class="selected-restaurants" @click="openSelectRestaurantModal()">
        <span v-for="restaurant in selectedRestaurants" :key="restaurant.id" class="selected-restaurants--item">{{
          restaurant.name
        }}</span>
        <span v-if="selectedRestaurants.length === 0" class="selected-restaurants--item">All</span>
      </div>
      <label class="label">Order type</label>
      <app-checkbox
        v-for="orderType in COUPON_ORDER_TYPES"
        :key="orderType.value"
        :value="form.coupon.orderTypes.includes(orderType.value)"
        :disabled="false"
        textOnTheLeft="true"
        @input="(event) => handleOrderTypeClick(orderType.value)"
        >{{ orderType.label }}</app-checkbox
      >
      <app-input v-model="form.coupon.usingLimit" rules="required" name="Amount" class="mt-30"
        >Limit the total number of times this coupon can be redeemed *
      </app-input>
      <app-radio v-model="form.coupon.type" name="type" option="percent">Percent</app-radio>
      <app-radio v-model="form.coupon.type" name="type" option="specific_amount">Specific amount</app-radio>
      <app-radio v-model="form.coupon.type" name="type" option="free_delivery">Free Delivery</app-radio>
      <app-input
        v-if="form.coupon.type !== 'free_delivery'"
        v-model="form.coupon.discount"
        rules="required"
        name="Discount"
        >Discount*</app-input
      >
      <app-input v-model="form.coupon.maxTotal" name="MaxTotal">Max total price</app-input>
      <app-input v-model="form.coupon.minimumCartPrice" name="minimumCartPrice">Minimum cart price</app-input>
      <app-input v-model="form.coupon.limitPerUser" name="LimitPerUser" rules="required">Limit per user*</app-input>
      <app-switcher v-model="form.coupon.isForNewUsers" name="IsForNewUsers" class="switcher"
        ><span class="-ml-10">For new users only</span></app-switcher
      >
    </div>
    <div class="container">
      <input
        class="btn-pink modal-submit"
        :class="{ 'btn-disabled': invalid }"
        type="submit"
        value="Create"
        form="add-coupon-form"
        :disabled="invalid"
      />
    </div>
    <app-modal-loader v-if="loading" />
  </ValidationObserver>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { Component, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import ModalSelectCouponRestaurants from '@/_shared/modals/modal-select-coupon-restaurants.vue';
import { getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

@Component({
  components: {
    AppLogo,
    ModalSelectCouponRestaurants,
  },
  directives: {
    mask,
  },
})
export default class ModalAddCoupon extends Vue {
  public loading = false;
  public selectCouponRestaurantsModalActive = false;
  public selectedRestaurants: { id: number; name: string }[] = [];
  public dateRange = getCurrentMonthRange();
  public $refs!: {
    form: HTMLFormElement;
  };
  private COUPON_ORDER_TYPES = [
    { value: 'all', label: 'All order types' },
    { value: 'collect_asap', label: 'C&C ASAP' },
    { value: 'delivery_asap', label: 'Delivery ASAP' },
    { value: 'delivery_scheduled', label: 'Delivery Scheduled' },
    { value: 'collect_scheduled', label: 'C&C Scheduled' },
  ];

  public form: ICouponForm = {
    coupon: {
      name: '',
      usingLimit: 0,
      discount: 0,
      maxTotal: null,
      type: 'percent',
      isForNewUsers: false,
      limitPerUser: 10,
      minimumCartPrice: null,
      orderTypes: ['all'],
      restaurantIds: [],
    },
  };

  public async addCoupon() {
    //Discount = 0 for free delivery coupons
    if (this.form.coupon.type === 'free_delivery') {
      this.form.coupon.discount = 0;
    }

    this.form.coupon.name = this.form.coupon.name.toUpperCase();

    let isValid = await this.$refs.form.validate();
    if (isValid) {
      this.loading = true;
      const start = new Date(this.dateRange.startDate).toISOString();
      const end = new Date(this.dateRange.endDate).toISOString();

      http
        .post('/admin/coupon', {
          start,
          end,
          ...this.form.coupon,
        })
        .then((res: any) => {
          ModalHub.$emit('setupCoupon', { data: res });
        })
        .then((res: any) => {
          this.loading = false;
          ModalHub.$emit('close');
        })
        .catch((err: any) => {
          this.loading = false;
          ModalHub.$emit('open', 'modal-error', { data: { message: err } });
        });
    }
  }

  public closeAddCoupon() {
    ModalHub.$emit('close');
  }

  public handleOrderTypeClick(orderType: string) {
    const indx = this.form.coupon.orderTypes.findIndex((type: string) => type === orderType);
    if (indx >= 0) {
      this.form.coupon.orderTypes.splice(indx, 1);
    } else {
      this.form.coupon.orderTypes.push(orderType);
    }
  }

  public openSelectRestaurantModal() {
    this.selectCouponRestaurantsModalActive = true;
  }

  public saveSelectedRestaurants(restaurants: { id: number; name: string }[]) {
    this.selectCouponRestaurantsModalActive = false;
    this.selectedRestaurants = restaurants;
    this.form.coupon.restaurantIds = restaurants.map((restaurant) => restaurant.id);
  }
}

interface ICouponForm {
  coupon: {
    name: string;
    usingLimit: number;
    discount: number;
    maxTotal?: number | null;
    type: 'percent' | 'specific_amount' | 'free_delivery';
    isForNewUsers: boolean;
    limitPerUser: number | null;
    minimumCartPrice: number | null;
    restaurantIds: number[];
    orderTypes: string[];
  };
}
</script>

<style lang="scss" scoped>
.modal-add-coupon {
  position: absolute;
  right: 31vw;
  background-color: #fafafa;
  overflow-y: auto;
  padding: 0;
  min-width: 740px;
  width: 45%;
  top: 15%;
  @include bp-500 {
    min-width: 345px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .modal-header {
    height: 100px;
    width: 100%;
    border-bottom: 1px solid $gray;
    background-color: $white;

    .close-modal-wrapper {
      display: flex;
      align-items: center;

      .close-modal {
        height: 20px;
        width: 20px;
        margin-left: 40px;
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .container-wrapper {
    border-bottom: 1px solid $gray;
  }
  .modal-input {
    width: 100%;
    background-color: $white;
    align-items: center;
    padding: 20px 20px;
    margin-bottom: 30px;
  }
  .dataRange {
    margin-bottom: 30px;
  }
  .modal-input > * {
    width: 100%;
  }
  .modal-submit {
    width: 100%;
  }
  .btn-disabled {
    opacity: 0.2;
  }
  .switcher {
    gap: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-self: flex-start;
    align-items: flex-start;
  }
  .-ml-10 {
    margin-left: -10px;
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 400;
    font-family: Sofia, sans-serif;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .selected-restaurants {
    border: 1px solid #e5e5e5;
    min-height: 50px;
    border-radius: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    gap: 10px;

    &--item {
      padding: 7px;
      color: #4d4d4d;
      background-color: #e5e5e5;
      border-radius: 5px;
      height: fit-content;
      margin-right: 5px;
    }
  }
}
</style>
