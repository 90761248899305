<template>
  <div class="modal-order">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      <p>Order info</p>
    </div>
    <div class="columns modal-switcher">
      <div class="column is-6 switch" :class="{ active: navigation.info }" @click="changeNavigation">Order</div>
      <div class="column is-6 switch" :class="{ active: navigation.details }" @click="changeNavigation">Details</div>
    </div>
    <template v-if="order?.vendor && order?.restaurant">
      <div v-if="navigation.info" class="modal-main columns is-gapless is-multiline">
        <div class="restaurant-info column is-8">
          <img :src="order.vendor.logo" alt="" class="logo" />
          <div class="restaurant-title">
            <div class="restaurant-name">{{ order.restaurant.name }}</div>
            <div class="restaurant-location">
              {{ order.restaurant.number }}
              {{ order.restaurant.street }}, {{ order.restaurant.postCode.nearestStation }},
              {{ order.restaurant.postCode.postcode }}
            </div>
          </div>
        </div>
        <div class="order-condition column is-4">
          <slot name="status"></slot>
        </div>
        <div class="order-menu columns is-gapless">
          <div v-for="(dish, name, indexMain) of formatedDishes" :key="indexMain" class="order-menu-dish">
            <div class="dish-title">
              <div class="dish-name">
                {{ dish.amount }}x -
                <span>{{ dish.name }}</span>
              </div>
              <div
                v-for="(d, index) of dish.modificators"
                :key="`${d.id}_${index}${indexMain}`"
                class="dish-modificators"
              >
                {{ `${d.amount * dish.amount}x - ${d.name}` }}
              </div>
            </div>
            <div class="dish-price">
              <div>£ {{ (dish.price * dish.amount).toFixed(2) }}</div>
              <div v-for="(dPrice, indexPr) of dish.modificators" :key="`${dPrice.id}_${indexPr}${indexMain}`">
                £ {{ (dPrice.price * dPrice.amount * dish.amount).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
        <div class="total column is-12">
          <p class="total-title">Total</p>
          <p class="total-price">£ {{ cartPriceWithoutDiscount.toFixed(2) }}</p>
        </div>

        <div class="order-completion-info column is-12">
          <p class="completion-title">Order time</p>
          <p class="completion-info">{{ hoursAndMinutes(order.createdAt) }}</p>
        </div>
        <div class="order-completion-info column is-12">
          <p class="completion-title">Kitchen Note:</p>
          <p class="completion-info">{{ order.kitchenNotes }}</p>
        </div>
        <div class="order-completion-info column is-12">
          <p class="completion-title">Order accepted</p>
          <p v-if="orderAccept" class="completion-info">{{ orderAccept }}</p>
          <p v-else class="completion-info">...</p>
        </div>
        <div class="order-completion-info column is-12">
          <p class="completion-title">Kitchen completion time</p>
          <p v-if="orderKitchen" class="completion-info">
            {{ orderKitchen }}
          </p>
          <p v-else class="completion-info">...</p>
        </div>
      </div>
      <div v-if="navigation.details" class="modal-main columns is-multiline">
        <div class="order-details column is-12">
          <p class="completion-title">OrderId</p>
          <p class="completion-info">{{ order.friendlyId }}</p>
        </div>
        <div v-if="order.type === 'delivery'" class="order-details column is-12">
          <p class="completion-title">Delivery address</p>
          <p class="completion-info">
            {{ order.address.flatAndBuilding }} {{ order.address.street }},
            {{ order.address.postcode }}
          </p>
        </div>
        <div class="order-details last column is-12">
          <p class="completion-title">Customer details</p>
          <div class="customer-details">
            <div class="customer-info">
              <p>{{ order.user.firstName }} {{ order.user.lastName }}</p>
              <p>{{ order.contactPhone }}</p>
            </div>
          </div>
        </div>
      </div>
      <slot v-if="navigation.info" />
    </template>
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import { formatDishes } from '@/_shared/utils/cart';

@Component({ components: { AppLogo } })
export default class ModalOrderInfo extends Vue {
  @Prop() order?: any;
  @Prop() orderAccept?: string;
  @Prop() orderKitchen?: string;
  public modalHub = ModalHub;

  public navigation = {
    info: true,
    details: false,
  };

  public hoursAndMinutes(dateString: string) {
    const unformattedTimestamp = moment(dateString).tz('Europe/London');
    const now = moment().tz('Europe/London');

    if (unformattedTimestamp.isSame(now, 'day')) {
      return `Today, ${unformattedTimestamp.format('HH:mm')}`;
    } else {
      return unformattedTimestamp.format('YYYY-MM-DD, HH:mm');
    }
  }

  public changeNavigation() {
    this.navigation.info = !this.navigation.info;
    this.navigation.details = !this.navigation.details;
  }

  public close() {
    ModalHub.$emit('close');
  }

  get formatedDishes() {
    return formatDishes(this.order.cart.dishes);
  }

  get cartPriceWithoutDiscount() {
    return this.order.cartPrice;
  }
}
</script>

<style lang="scss" scoped>
.modal-order {
  &.modal-inner {
    max-width: 653px;
    width: 100%;
    overflow-x: hidden;
    padding: 0;
    padding-bottom: 100px;
  }
  .modal-head {
    margin: 40px 0;
    font-size: 14px;
    text-align: center;
    position: relative;
    .close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      margin-left: 40px;
    }
  }
}
.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray;
  height: 70px;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: $dark;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.active {
  color: $pink;
  border-bottom: 2px solid $pink;
}
.modal-main {
  padding: 40px 40px;
  margin: 0;
}
.logo {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.restaurant-info {
  display: flex;
  flex-direction: row;
}
.restaurant-title {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-left: 20px;
}
.restaurant-name {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $dark;
}
.restaurant-location {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
}
.order-condition {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 112.9%;
  .new,
  .delivering {
    color: #08b804;
  }
  .preparing {
    color: $pink;
  }
  .ready {
    color: #6c8fc3;
  }
}
.order-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid $gray;
}
.order-menu-dish {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 22px;
}
.dish-title {
  display: flex;
  flex-direction: column;
}
.dish-name {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: $dark;
  span {
    text-transform: uppercase;
  }
}
.dish-modificators {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: $dark;
}
.dish-price {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: $pink;
  width: 60px;
}
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-title {
    font-family: $Montserrat;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $dark;
  }
  &-price {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: $pink;
  }
}
.order-completion-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray;
}
.completion-title {
  font-family: $Montserrat;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
  &.last {
    margin-bottom: 0;
  }
}
.completion-info {
  font-family: $Montserrat;
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
  text-align: right;
  max-width: 170px;
  &.last {
    margin-bottom: 0;
  }
}
.order-details {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $gray;
  &.last {
    border-bottom: none;
  }
}
.customer-details {
  display: flex;
  flex-direction: row;
}
.customer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  p {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $dark;
  }
}
.customer-logo {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.submit-btn {
  width: calc(100% - 80px);
  height: 60px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $white;
  margin-bottom: 20px;
}
</style>
