<template>
  <div>
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      Dishes category
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmitDebounced">
      <app-input v-model="category" rules="required" name="Category name">Category name*</app-input>
      <button class="btn-pink" :class="{ 'btn-disabled': invalid }" type="submit" :disabled="invalid">Add</button>
      <template v-if="isFetching">
        <app-modal-loader />
      </template>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IDishCategory } from '@/menu/dishes.vue';

@Component({})
export default class ModalAddCategory extends Vue {
  @Prop() data!: { category: IDishCategory };
  public modalHub = ModalHub;
  public category = '';
  public categoryId!: number;

  private isFetching: boolean = false;

  mounted() {
    if (this.data) {
      this.category = this.data.category.name;
      this.categoryId = this.data.category.id;
    }
  }
  public onSubmitDebounced = _.debounce(this.onSubmit, 200);

  public onSubmit() {
    this.isFetching = true;
    if (this.categoryId) {
      http
        .put(`/admin/menu/${this.vendorSlug}/dish-categories/${this.categoryId}`, { name: this.category })
        .catch((err: Error) => {
          this.isFetching = false;
          console.error(err);
        })
        .then((res: { success: true }) => {
          this.isFetching = false;
          if (res.success) {
            this.$router.go(0);
          }
        });
    } else {
      http
        .post(`/admin/menu/${this.vendorSlug}/dish-categories`, { name: this.category })
        .catch((err: Error) => {
          this.isFetching = false;
          console.error(err);
        })
        .then((res: { dishCategory: IDishCategory }) => {
          this.isFetching = false;
          if (res.dishCategory) {
            this.$router.go(0);
          }
        });
    }
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}
</script>

<style lang="scss" scoped>
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.btn-pink {
  width: 100%;
  margin-top: 20px;
}
.btn-disabled {
  opacity: 0.2;
}
</style>
