<template>
  <div class="modal-flash-wrapper">
    <LogoApp />
    <h2>Incoming Order</h2>
    <input type="submit" class="btn-white close-flash-btn" value="Okay" @click="close" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import LogoApp from '@/_assets/LogoApp.vue';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({
  components: {
    LogoApp,
  },
})
export default class OrderFlash extends Vue {
  public modalHub = ModalHub;

  public close() {
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
  }
}
</script>

<style lang="scss" scoped>
.modal-flash-wrapper {
  position: absolute;
  background-color: #ffcacd !important;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  z-index: 900;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: {
    top: 15px;
    bottom: 20px;
    left: 15px;
    right: 15px;
  }
  h2 {
    font-family: Recoleta;
    font-weight: 900;
    font-size: 80px;
    margin-top: 20px;
    @include bp-650 {
      font-size: 50px;
    }
    @include bp-500 {
      font-size: 40px;
    }
  }
  .close-flash-btn {
    margin-top: 30px;
    width: 250px;
    font-size: 20px;
    @include bp-500 {
      width: 180px;
      font-size: 18px;
    }
  }
}
</style>
