import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { confirmed, length, max_value, min, min_value, numeric, required } from 'vee-validate/dist/rules';
import Vue from 'vue';

import decimal from './decimal';

extend('password', {
  validate: (value: string) => {
    const longEnough = value.length >= 8;
    const hasCapitalLetter = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);

    return longEnough && hasCapitalLetter && hasNumber;
  },
  message: () => 'Password should be at least 8 characters long, contain one big letter and a number.',
});

extend('isTrue', {
  validate: (value) => value === true,
  message() {
    return 'This is must be checked';
  },
});

extend('required', required);

extend('numeric', {
  message: () => 'The field must be a number',
  ...numeric,
});

extend('decimal', {
  message: (field, _) => `${field} must be number and can have only 2 digits after .`,
  ...decimal,
});

extend('email', {
  validate: (value: string) => {
    const re = /^(\S+)@([a-z0-9-]+)(\.)([a-z]{2,4})(\.?)([a-z]{0,4})+$/;
    return re.test(value);
  },
  message() {
    return 'E-mail entered incorrectly';
  },
});

extend('min', {
  message: (field, schema: any) => `${field} should be longer than ${schema.length} characters`,
  ...min,
});

extend('length', {
  message: (field, schema: any) => `${field} should exactly have ${schema.length} characters`,
  ...length,
});

extend('confirmed', confirmed);

extend('min_value', {
  message: (field, schema: any) => `${field} should be at least ${schema.min}`,
  ...min_value,
});

extend('max_value', {
  message: (field, schema: any) => `${field} cannot be greater than ${schema.max}`,
  ...max_value,
});

extend('tax_rate', {
  validate: (value: string) => {
    return value === '0' || value === '20';
  },
  message() {
    return 'Tax rate must be either 0 or 20';
  },
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
