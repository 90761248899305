<template>
  <ValidationObserver
    id="form-example"
    v-slot="{}"
    ref="form"
    tag="form"
    class="modal-wrapper"
    @submit.prevent="addUser"
  >
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      <p>Add user</p>
    </div>

    <app-input v-model="form.email" class="modal-email" rules="required|email" name="Email"> Email* </app-input>

    <input type="submit" class="btn-pink modal-submit" value="Add" />
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({
  components: {
    AppLogo,
  },
})
export default class ModalAddUser extends Vue {
  public modalHub = ModalHub;
  public form = { email: '' };
  public $refs!: {
    form: HTMLFormElement;
  };

  private async addUser() {
    if (await this.$refs.form.validate()) {
      ModalHub.$emit('close');
      ModalHub.$emit('addUser', {
        email: this.form.email,
      });
    }
  }

  public close() {
    ModalHub.$emit('close');
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  &.modal-inner {
    width: 550px;
  }
  .modal-head {
    margin: 0 0 40px;
    font-size: 14px;
    text-align: center;
    position: relative;
    .close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
  .modal-email {
    width: 100%;
    margin-bottom: 40px;
  }
  .title {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 100%;
    color: $dark;
  }
  .modal-switcher-wrap {
    width: calc(100% + 40px);
    height: 80px;
    margin-left: -20px;
    margin-top: 20px;
    background-color: $lightgray;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .modal-submit {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 100px;
    &:disabled {
      opacity: 0.7;
      background-color: $lightgray;
      color: $dark;
      cursor: default;
    }
  }
  .text-gray {
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
