<template>
  <div class="modal-error">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#E24E4E"
      />
      <path d="M40 51V19M40 60V58" stroke="white" stroke-width="2" />
    </svg>

    <div class="title">Error</div>
    <div class="subtitle">{{ message }}</div>
    <button v-if="data.missingData" class="btn-white" @click="close">Back</button>
    <button v-else class="btn-white" @click="closeAndReload">Reload</button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({})
export default class ModalError extends Vue {
  @Prop() data!: { message: string; missingData: Boolean };

  public close() {
    ModalHub.$emit('close');
  }

  public closeAndReload() {
    ModalHub.$emit('close');
    window.location.reload();
  }

  get message() {
    return this.data?.message
      ? this.data.message
      : 'Sorry, something went wrong. Please reload the page. If you keep seeing this message, contact support.';
  }
}
</script>

<style lang="scss" scoped>
.modal-error {
  &.modal-inner {
    width: 340px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}
.title {
  font-size: 20px;
  margin: 20px 0 10px;
}
.subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 150%;
}
.subtitle-code {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 150%;
}
.btn-white {
  background-color: #f8f8f8;
  width: 80%;
}
</style>
