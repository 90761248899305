<template>
  <div class="modificators">
    <div v-if="modificatorCategories.length">
      <div v-for="c of modificatorCategories" :key="c.id" class="category">
        <app-accordion>
          <template #head>
            <div class="category-name">{{ c.name }}</div>
            <app-popover>
              <div @click="deleteCategory(c)"><app-icon name="delete" /> Delete</div>
              <div @click="openModalEditModificator(c)"><app-icon name="pencil" /> Edit</div>
            </app-popover>
          </template>
          <template #body>
            <div v-for="m of c.modificators" :key="m.id" class="modificator">
              <app-accordion class="modificator-accordion">
                <template #head>
                  <div class="category-name">{{ m.name }}</div>
                  <p v-if="m.price" class="modificator-price">+ £{{ m.price }}</p>
                  <app-switcher
                    v-model="m.isMultiselect"
                    class="switcher multiselect"
                    @input="markModificatorIsMultiselect(m)"
                  >
                    multiselect
                  </app-switcher>
                </template>
                <template #body>
                  <div v-for="r of m.soldOut" :key="r.id" class="modificator">
                    <div class="modificator-wrapper">
                      <p class="modificator-name">{{ r.restaurant.name }}</p>
                    </div>
                    <app-switcher
                      class="switcher"
                      :value="r.isSoldOut"
                      @input="markModificatorSoldOut(m, r.restaurant)"
                    >
                      sold out
                    </app-switcher>
                  </div>
                </template>
              </app-accordion>
            </div>
          </template>
        </app-accordion>
      </div>
    </div>

    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span>
        Click the
        <i class="is-pink cup" @click="openModalAddModificator">+</i>
        to add new modificator
      </span>
    </div>

    <span class="app-plus-icon" @click="openModalAddModificator">
      <app-icon name="add" />
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IRestaurant } from '@/vendor/dashboard.vue';

@Component({})
export default class Modificators extends Vue {
  public restaurants: IRestaurant[] = [];
  public modificatorCategories: IModificatorCategory[] = [];

  private isSoldOutInRestaurant(categoryIdx: number, modificatorIdx: number, restaurantId: number) {
    return this.modificatorCategories[categoryIdx].modificators[modificatorIdx].soldOut.find(
      (el: any) => el.restaurantId === restaurantId,
    ).isSoldOut;
  }

  public openModalAddModificator() {
    ModalHub.$emit('open', 'modal-add-modificator', { animation: 'slide-left' });
  }

  public openModalEditModificator(category: IModificatorCategory) {
    ModalHub.$emit('open', 'modal-add-modificator', { animation: 'slide-left', data: { category } });
  }

  public async mounted() {
    await this.getRestaurants();
    await this.getModificators();
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }

  public deleteCategory(category: IModificatorCategory) {
    http.delete(`/admin/menu/${this.vendorSlug}/modificators/${category.id}`).then((res: { success: boolean }) => {
      if (res.success) {
        this.modificatorCategories.splice(this.modificatorCategories.indexOf(category), 1);
      }
    });
  }

  public markModificatorSoldOut(modificator: any, restaurant: IRestaurant) {
    http
      .put(`/admin/menu/${this.vendorSlug}/modificators/${modificator.id}/sold-out?restaurantSlug=${restaurant.slug}`)
      .then((res: any) => {});
  }

  public markModificatorIsMultiselect(modificator: any) {
    http.put(`/admin/menu/${this.vendorSlug}/modificators/${modificator.id}/multiselect`).then((res: any) => {});
  }

  private async getModificators() {
    const res: { modificatorCategories: IModificatorCategory[] } = await http.get(
      `/admin/menu/${this.vendorSlug}/modificators`,
    );

    this.modificatorCategories = res.modificatorCategories.map((c: any) => {
      return {
        id: c.id,
        name: c.name,
        modificators: c.modificators.map((m: any) => {
          return {
            id: m.id,
            price: m.price + '',
            taxRate: m.taxRate !== null ? m.taxRate + '' : null,
            name: m.name,
            isMultiselect: m.isMultiselect,
            soldOut: m.soldOutForRestaurants.map((rSO: any) => {
              return {
                restaurant: this.restaurants.find((r) => r.id === rSO.restaurantId),
                isSoldOut: rSO.isSoldOut,
              };
            }),
          };
        }),
      };
    });
    res.modificatorCategories.map((c: IModificatorCategory) => {
      c.open = false;
      c.isActionsOpen = false;
    });
  }

  public async getRestaurants() {
    const res: { restaurants: IRestaurant[] } = await http.get(`/admin/menu/${this.vendorSlug}/restaurants/`);
    let restaurants: IRestaurant[] = res.restaurants;
    this.restaurants = restaurants;
  }
}

export interface IModificatorCategory {
  id: number;
  name: string;
  open?: boolean;
  isActionsOpen?: boolean;
  modificators: IModificator[] | any;
  slug?: string;
}

export interface IModificator {
  checked?: boolean;
  id: number;
  name: string;
  price: number;
  taxRate: number;
}
</script>

<style lang="scss" scoped>
.modificators {
  padding: 20px 0;
}
.category {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
  }
  .popover {
    color: $dark;
    margin-left: auto;
  }
}
.modificator {
  background-color: $lightgray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  .switcher {
    margin: 0;
  }
  .multiselect {
    margin-left: auto;
    margin-right: 10px;
  }
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
  &-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .icon {
    margin-left: auto;
    cursor: pointer;
  }
}
.modificator-accordion {
  width: 100%;
}
</style>
