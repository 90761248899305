<template>
  <div class="app-input">
    <label class="label">
      <slot />
    </label>
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" :bails="false">
      <input
        ref="field"
        class="input"
        :class="{ 'is-error': errors.length }"
        :readonly="readonly"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        @input="dataUpdate()"
      />
      <span v-for="(error, i) in errors" :key="i" class="error">{{ error }}</span>
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppInput extends Vue {
  @Prop() settings: object | undefined;
  @Prop() value: any;
  @Prop() rules: any;
  @Prop() name: any;
  @Prop() type: any;
  @Prop() placeholder: any;
  @Prop() autocomplete: any;
  @Prop() readonly: any;
  public dataUpdate() {
    this.$emit('input', (this.$refs.field as Vue & { value: () => string }).value);
  }
}
</script>

<style lang="scss" scoped>
.app-input {
  margin-bottom: 20px;
  &.is-marginless {
    margin: 0;
  }
}
.input {
  border: 1px solid rgba(33, 33, 33, 0.2);
  display: block;
  width: 100%;
  @include control-states;

  &::placeholder {
    font-family: $Montserrat;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
  &[readonly],
  &[disabled] {
    color: $coal40;
    background-color: #f0eded;
    &:hover {
      cursor: not-allowed;
    }
  }
}
.label {
  margin-bottom: 10px;
}
.error {
  display: block;
  font-size: 13px;
  margin-top: 5px;
  color: $red;
}
</style>
