<template>
  <div class="orders-history">
    <div>
      <div class="container">
        <div class="orders-filters">
          <app-select
            v-model="vendorSlug"
            :options="vendors"
            :optionValue="'slug'"
            select-title="All vendors"
            @input="getRestaurants"
          />
          <app-select
            v-model="restaurantSlug"
            :options="restaurants"
            :optionValue="'slug'"
            select-title="All restaurants"
            @input="getOrdersHistory"
          />
          <app-datepicker v-model="dateRange" class="daterange" opens="left" @input="getOrdersHistory" />
          <app-input
            v-model="searchOrderId"
            class="search is-marginless"
            placeholder="Search by Order Id..."
            @input="getOrdersHistory"
          />
        </div>
      </div>

      <div v-if="!loading" class="orders-block">
        <div class="container">
          <order-row v-for="order of orders" :key="order.id" :order="order" class="order" @onClick="openOrderInfo">
            <div class="order-details-wrap">
              <div class="order-price">
                <p>Type</p>
                <div class="price capitalize">{{ order.type }}</div>
              </div>
              <div class="order-price">
                <p>Order Id</p>
                <div class="price">{{ order.friendlyId }}</div>
              </div>
              <div class="order-status">
                <p>Order status</p>
                <p
                  class="status"
                  :class="{
                    green: order.status === 'delivered',
                    red: order.status === 'declined',
                  }"
                >
                  {{ order.status }}
                </p>
              </div>
            </div>
            <app-popover>
              <div @click="openModalInvoiceEmbedded(order.friendlyId, order.paymentMethod)">
                Send {{ order.paymentMethod === 'invoice' ? 'invoice' : 'VAT receipt' }} to mailbox
              </div>
              <div @click="openModalInvoiceXero(order.friendlyId, order.paymentMethod)">
                Generate {{ order.paymentMethod === 'invoice' ? 'invoice' : 'VAT receipt' }} in Xero
              </div>
              <div v-if="order.groupId" @click="exportGroupBaskets(order.friendlyId, order.restaurant.name)">
                Export list of group baskets
              </div>
            </app-popover>
          </order-row>
        </div>
      </div>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import { formatTime, getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

import { IRestaurant } from '@/vendor/dashboard.vue';

@Component({})
export default class FinancesOrders extends Vue {
  public dateRange = getCurrentMonthRange();
  public restaurants: IRestaurant[] = [];
  public vendors = [];
  public orders: IOrderHistory[] = [];
  public searchOrderId = '';
  public restaurantSlug = '';
  public vendorSlug = '';
  public loading = false;

  mounted() {
    this.getVendors();
    this.getOrdersHistory();
  }

  public getOrdersHistory() {
    this.loading = true;
    let filters = '?statuses=new,preparing,ready_for_delivery,delivering,delivered,declined,scheduled';
    filters += `&startDate=${moment(this.dateRange.startDate).startOf('day').toISOString()}`;
    filters += `&endDate=${moment(this.dateRange.endDate).endOf('day').toISOString()}`;
    filters += this.restaurantSlug ? `&restaurantSlug=${this.restaurantSlug}` : '';
    filters += this.vendorSlug ? `&vendorSlug=${this.vendorSlug}` : '';
    filters += this.searchOrderId ? `&friendlyId=${this.searchOrderId}` : '';
    http
      .get(`/admin/orders/finances/all/${filters}`)
      .then((res: any) => {
        this.orders = res;
        this.loading = false;
      })
      .catch((err: any) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
        this.loading = false;
      });
  }

  public getRestaurants() {
    http.get(`/admin/vendors/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      this.restaurants = res.restaurants;
    });
    this.getOrdersHistory();
  }

  private getVendors() {
    http.get(`/admin/vendors?name=`).then((response: any) => {
      this.vendors = response.vendors;
    });
  }

  public openOrderInfo(data: { orderId: number; vendorSlug: string }) {
    ModalHub.$emit('open', 'modal-history-info', { data });
  }

  public async openModalInvoiceEmbedded(friendlyId: number, paymentMethod: string) {
    ModalHub.$emit('open', 'modal-invoice-embedded', { data: { friendlyId, paymentMethod } });
  }

  public async openModalInvoiceXero(friendlyId: number, paymentMethod: string) {
    ModalHub.$emit('open', 'modal-invoice-xero', { data: { friendlyId, paymentMethod } });
  }

  public async exportGroupBaskets(friendlyId: number, restaurantName: string) {
    http
      .get(`/admin/orders/group-baskets-csv?friendlyId=${friendlyId}`)
      .then((response: any) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response);
        anchor.target = '_blank';
        anchor.download = `${restaurantName} - ${friendlyId}.csv`;
        anchor.click();
      })
      .catch((e: any) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: e } });
      });
  }
}
interface IOrderHistory {
  id: string;
  friendlyId: string;
  status: string;
  statusDetails: string;
  deliverToTime: string;
  totalPrice: number;
  createdAt: string;
  restaurant: string;
  name: string;
  street: string;
}
</script>

<style lang="scss" scoped>
.orders-history {
  padding-bottom: 20px;
}
.orders-filters {
  padding: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .app-input {
    width: 260px;
  }
  .search {
    .label {
      display: none;
    }
  }
  @include bp-480 {
    flex-direction: column;
    & > * {
      margin: 10px 0;
    }
    .select {
      margin: 10px 0;
    }
  }
}
.orders-block {
  margin-bottom: 40px;
}
.order {
  font-size: 14px;
  margin: 0 -20px 20px;
  position: relative;
  .order-status {
    min-width: 90px;
    margin-right: 60px;
    .status {
      font-weight: bold;
      margin-top: 10px;
      &.red {
        color: $red;
      }
      &.green {
        color: $green;
      }
    }
  }
  .order-price {
    min-width: 90px;
    margin-right: 40px;
    .price {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.order-details-wrap {
  display: flex;
  width: 370px;
  margin-left: auto;
}
</style>
