<template>
  <div class="modal-add-dish-modificator">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      {{ isEdited ? 'Edit Menu Category' : 'Add Menu Category' }}
    </div>

    <ValidationObserver v-slot="{ invalid }" tag="form" @submit.prevent="onSubmit">
      <app-input v-model="menuCategory.name" rules="required" name="category">Category name*</app-input>

      <div v-if="isCaterer">
        <label class="label">Add photo*</label>
        <app-file v-model="menuCategory.photo" name="photo" @input="previewPhoto($event)">
          <div v-if="preview" class="menu-image">
            <span @click.stop.prevent="removePreview">
              <app-icon name="remove-red-circle" />
            </span>
            <img :src="preview" alt="" />
          </div>
          <div v-else class="picture-holder">
            <span class="picture-holder-add">
              <app-icon name="add-small" />
            </span>
            <div class="picture-holder-parameters">
              <p>Recommended</p>
              <p>1920 x 680 px</p>
            </div>
          </div>
        </app-file>
      </div>

      <div class="checkboxes">
        <app-checkbox v-model="menuCategory.isScheduledOnly" :disabled="true">Scheduled only</app-checkbox>
      </div>

      <div class="time-inputs">
        <p class="label">From</p>
        <input v-model="menuCategory.availableFrom" v-mask="'##:##'" type="text" class="input" placeholder="00:01" />
        <p class="label">To</p>
        <input v-model="menuCategory.availableTo" v-mask="'##:##'" type="text" class="input" placeholder="23:59" />
      </div>

      <div class="subtitle">Add dishes</div>

      <div v-if="categories.length" class="categories">
        <div v-for="c of categories" :key="c.id" class="category">
          <app-accordion :is-open="c.open">
            <template #head>
              <div class="category-name">{{ c.name }}</div>
            </template>
            <template #body>
              <div v-for="d of c.dishes" :key="d.id" class="dish">
                <app-checkbox v-model="d.checked" class="is-rounded is-marginless" />
                <div class="dish-photo">
                  <img
                    v-if="d.photo && d.photo !== 'https://storage.googleapis.com/foodstufff/default/stub.svg'"
                    :src="d.photo"
                    :alt="d.name"
                  />
                </div>
                <p class="dish-name">{{ d.name }}</p>
                <p class="dish-price">£{{ d.price.toFixed(2) }}</p>
              </div>
            </template>
          </app-accordion>
        </div>
      </div>

      <div v-else class="empty-holder">
        <app-icon name="empty" />
        <span>Empty here</span>
      </div>
      <button v-if="!loading" type="submit" class="btn-pink" :class="{ 'btn-disabled': invalid }">
        {{ isEdited ? 'Edit' : 'Add' }}
      </button>
    </ValidationObserver>

    <button v-if="loading" class="btn-white">
      <app-loader />
    </button>
  </div>
</template>

<script lang="ts">
import { mask } from '@titou10/v-mask';
import { toFormData } from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import { IDishCategory } from '@/menu/dishes.vue';
import { IRestaurantMenuCategory } from '@/menu/restaurants-menu.vue';

@Component({
  directives: {
    mask,
  },
})
export default class ModalAddMenuCategory extends Vue {
  public modalHub = ModalHub;
  public categories: IDishCategory[] = [];
  public restaurantSlug: string = '';
  public isCaterer = false;
  public isEdited = false;
  public loading = false;

  @Prop() data: any;

  public preview: string = '';
  public menuCategory: IRestaurantMenuCategory = {
    name: '',
    isScheduledOnly: true,
    availableFrom: '00:01',
    availableTo: '23:59',
    dishes: [],
    slug: '',
  };

  public mounted() {
    this.getCategories();
    if (this.data) {
      this.isCaterer = this.data.isCaterer;
      this.restaurantSlug = this.data.restaurantSlug;
      if (this.data.menuCategory) {
        this.isEdited = true;
        this.menuCategory = { ...this.data.menuCategory };
        this.preview = this.menuCategory.photo || '';
      }
    }
  }

  public onSubmit() {
    let data: any = { ...this.menuCategory };
    data.dishes = `[${this.collectMarketIds().join(',')}]`;

    if (this.isCaterer && !this.menuCategory.photo) {
      ModalHub.$emit('open', 'modal-error', {
        data: { message: 'Photo is required!', missingData: true },
      });
      return;
    }

    this.loading = true;
    if (this.isEdited) {
      let slug = data.slug;
      delete data.id;
      delete data.slug;
      http
        .put(
          `/admin/menu/${this.vendorSlug}/restaurants/${this.restaurantSlug}/menu-categories/${slug}`,
          toFormData(data),
        )
        .then((res: { menuCategory: IRestaurantMenuCategory }) => {
          if (!res.menuCategory) {
            this.modalHub.$emit('close');
          } else {
            this.modalHub.$emit('close', {
              menuCategory: res.menuCategory,
              restaurantSlug: this.restaurantSlug,
            });
          }
        })
        .finally(() => (this.loading = false));
    } else {
      delete data.order;
      http
        .post(`/admin/menu/${this.vendorSlug}/restaurants/${this.restaurantSlug}/menu-categories`, toFormData(data))
        .then((res: { menuCategory: IRestaurantMenuCategory }) => {
          if (!res.menuCategory) {
            this.modalHub.$emit('close');
          } else {
            this.modalHub.$emit('close', {
              menuCategory: res.menuCategory,
              restaurantSlug: this.restaurantSlug,
            });
          }
        })
        .finally(() => (this.loading = false));
    }
  }

  private collectMarketIds(): number[] {
    let ids: number[] = [];
    this.categories.map((c: IDishCategory) => {
      c.dishes.map((d: any) => {
        if (d.checked) {
          ids.push(d.id);
        }
      });
    });
    return ids;
  }

  private markSelectedDishes() {
    if (!this.menuCategory.dishes) return;
    this.categories.map((c: IDishCategory) => {
      for (let dish of c.dishes) {
        for (let selectedDish of this.menuCategory.dishes) {
          if (dish.slug === selectedDish.slug) {
            dish.checked = true;
            c.open = true;
            break;
          }
        }
      }
    });
  }

  private getCategories() {
    http.get(`/admin/menu/${this.vendorSlug}/dish-categories`).then((res: { dishCategories: IDishCategory[] }) => {
      this.categories = res.dishCategories;
      this.markSelectedDishes();
    });
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }

  public previewPhoto(file: any) {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  public removePreview() {
    this.preview = '';
    delete this.menuCategory.photo;
  }
}
</script>

<style lang="scss" scoped>
.modal-head {
  margin: 0 0 40px;
  font-size: 14px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
.time-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0;
  .label {
    margin-bottom: 0;
  }
  .input {
    margin: 0 5px;
    max-width: 72px;
    padding: 10px;
    text-align: center;
  }
}
.categories {
  background-color: $lightgray;
  margin: 40px -40px;
  padding: 20px 40px;
}
.category {
  position: relative;
  &-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
  }
  .add-icon {
    margin-left: auto;
    margin-right: 20px;
  }
}
.dish {
  background-color: $lightgray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  &-name {
    font-size: 14px;
    color: $dark;
  }
  &-photo {
    height: 60px;
    width: 60px;
    margin: -20px 20px -20px 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &-price {
    color: $pink;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
}
.btn-pink {
  width: 100%;
}
.btn-disabled {
  opacity: 0.2;
}
.checkboxes {
  display: flex;
  gap: 15px;
}
.menu-image {
  width: 100%;
  height: 256px;
  position: relative;
  span {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    user-select: none;
  }
  img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }
}
.btn-white {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: 0.5px solid black;
}
.btn-white:hover {
  background-color: $white;
}
</style>
