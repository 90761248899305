<template>
  <div class="modal-error">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ text }}</div>
    <div class="btn_wrap">
      <button class="btn-white" @click="close">No</button>
      <button class="btn-pink" @click="confirm">Yes</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';

@Component({})
export default class ModalAreYouSure extends Vue {
  @Prop() data!: { title: string; text: string; variable: any; eventName?: string };

  public close() {
    ModalHub.$emit('close');
  }
  public confirm() {
    ModalHub.$emit(`${this.data.eventName ? this.data.eventName : 'on-confirm'}`, this.data.variable);
    ModalHub.$emit('close');
  }
  get title() {
    return this.data && this.data.title ? `Are you sure ${this.data.title}` : 'Are you sure?';
  }
  get text() {
    return this.data && this.data.text ? this.data.text : '';
  }
}
</script>

<style lang="scss" scoped>
.modal-error {
  &.modal-inner {
    width: 340px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}
.title {
  font-size: 20px;
  margin: 20px 0 10px;
}
.subtitle {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 150%;
}
.btn_wrap {
  display: flex;
  justify-content: space-between;
  & > * {
    flex-basis: 40%;
  }
}
.btn-white {
  background-color: #f8f8f8;
}
</style>
