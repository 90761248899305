import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { User } from '@/_types/shared.types';

Vue.use(Vuex);

export interface AdminState {
  isSuperAdmin: boolean;
  isTerms: boolean;
  stripeId: string;
  vendorSlug: string;
  user: User | null;
}

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state: AdminState = {
  isSuperAdmin: false,
  isTerms: false,
  stripeId: '',
  vendorSlug: '',
  user: null,
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['isSuperAdmin', 'user'],
      key: 'session',
    }),
  ],
  state,
  mutations: {
    isSuperAdmin(state, payload) {
      state.isSuperAdmin = payload;
    },
    isTerms(state, payload) {
      state.isTerms = payload;
    },
    stripeId(state, payload) {
      state.stripeId = payload;
    },
    user(state, payload) {
      state.user = payload;
    },
    vendorSlug(state, payload) {
      state.vendorSlug = payload;
    },
    reset(state) {
      state.isSuperAdmin = false;
      state.isTerms = false;
      state.stripeId = '';
      state.vendorSlug = '';
      state.user = null;
    },
  },
});
