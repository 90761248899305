<template>
  <div class="modal" :class="{ active }" @click.self="closeModal">
    <transition :name="animation">
      <component :is="component" v-if="active" :data="componentData" class="modal-inner" />
    </transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalAddCoupon from '@/_shared/modals/modal-add-coupon.vue';
import ModalAddRestaurant from '@/_shared/modals/modal-add-restaurant.vue';
import ModalAddUser from '@/_shared/modals/modal-add-user.vue';
import ModalBillingUpdate from '@/_shared/modals/modal-billing-update.vue';
import ModalError from '@/_shared/modals/modal-error.vue';
import ModalFinanceDetails from '@/_shared/modals/modal-finance-details.vue';
import ModalHistoryInfo from '@/_shared/modals/modal-history-info.vue';
import ModalHub from '@/_shared/modals/modal-hub';
import ModalFlash from '@/_shared/modals/modal-new-order-flashing.vue';
import ModalOrderCollect from '@/_shared/modals/modal-order-collect.vue';
import ModalOrderDelivering from '@/_shared/modals/modal-order-delivering.vue';
import ModalOrderNew from '@/_shared/modals/modal-order-new.vue';
import ModalOrderPreparing from '@/_shared/modals/modal-order-preparing.vue';
import ModalOrderReady from '@/_shared/modals/modal-order-ready.vue';
import ModalPasswordRequest from '@/_shared/modals/modal-password-request.vue';
import ModalPaymentDueUnpublished from '@/_shared/modals/modal-payment-due-unpublished.vue';
import ModalRepublishSuccess from '@/_shared/modals/modal-republish-success.vue';
import ModalSubscriptionCreated from '@/_shared/modals/modal-subscription-created.vue';
import ModalSureDelete from '@/_shared/modals/modal-sure-delete.vue';

import ModalAddCategory from '@/menu/modal-add-category.vue';
import ModalAddDishModificators from '@/menu/modal-add-dish-modificators.vue';
import ModalAddMenuCategory from '@/menu/modal-add-menu-category.vue';
import ModalAddModificator from '@/menu/modal-add-modificator.vue';
import ModalSpecifyDiscount from '@/menu/modal-specify-discount.vue';

import ModalAreYouSure from './modal-are-you-sure.vue';
import ModalInvoiceEmbedded from './modal-invoice-embedded.vue';
import ModalInvoiceXero from './modal-invoice-xero.vue';

@Component({
  components: {
    ModalAddRestaurant,
    ModalAddModificator,
    ModalAddCategory,
    ModalAddDishModificators,
    ModalAddUser,
    ModalSpecifyDiscount,
    ModalAddMenuCategory,
    ModalError,
    ModalOrderNew,
    ModalOrderPreparing,
    ModalOrderReady,
    ModalOrderDelivering,
    ModalOrderCollect,
    ModalAreYouSure,
    ModalHistoryInfo,
    ModalAddCoupon,
    ModalFlash,
    ModalPasswordRequest,
    ModalSubscriptionCreated,
    ModalPaymentDueUnpublished,
    ModalRepublishSuccess,
    ModalBillingUpdate,
    ModalSureDelete,
    ModalInvoiceEmbedded,
    ModalInvoiceXero,
    ModalFinanceDetails,
  },
})
export default class ModalView extends Vue {
  public active = false;
  public component: string = '';
  public animation = 'fade';
  public componentData: any;

  public openModal(component: string, options?: ModalOptions) {
    this.active = true;
    this.component = component;
    this.animation = options && options.animation ? options.animation : 'fade';
    this.componentData = options && options.data ? options.data : null;
  }

  public closeModal() {
    this.active = false;
  }

  public mounted() {
    this.$nextTick(() => {
      ModalHub.$on('open', this.openModal);
      ModalHub.$on('close', this.closeModal);
    });
  }
}

interface ModalOptions {
  animation?: string;
  data?: any;
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  transition: 0.3s opacity;
  opacity: 0;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
.modal-inner {
  background-color: $white;
  padding: 40px;
  top: 0;
  right: 0;
  position: absolute;
  width: 460px;
  height: 100vh;
  overflow: scroll;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.2);
  &::-webkit-scrollbar {
    width: 0;
  }
  @include bp-480 {
    max-width: 100vw;
  }
}
</style>
