<template>
  <div
    :class="[
      'accordion',
      { 'accordion-nested': nested },
      { open: open && $slots.body },
      { empty: !$slots.body },
      { logisticStyles: isLogistic },
    ]"
  >
    <div
      class="accordion-head"
      :class="[{ logisticHeadStyles: isLogistic }, { 'slim-head': slim }]"
      @click="toggleOpen"
    >
      <app-icon v-if="$slots.body" class="accordion-icon" name="dropdown" />
      <slot name="head" />
    </div>
    <div v-if="$slots.body && open" class="accordion-body" :class="{ slim }">
      <slot name="body" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppAccordion extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: false }) logisticAccordion?: boolean;
  @Prop({ default: false }) slim!: boolean;
  @Prop({ default: false }) nested!: boolean;

  public open: boolean = false;
  public isLogistic: boolean = false;

  public toggleOpen() {
    this.open = !this.open;
  }

  mounted() {
    this.isLogistic = this.logisticAccordion ? this.logisticAccordion : false;
    this.open = this.isOpen;
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  background-color: $white;
  margin-bottom: 20px;
  max-height: 80px;
  overflow: hidden;
  transition: 0.5s all;

  .accordion-icon {
    transition: 0.5s all;
    transform: rotate(-90deg);
  }
  &.empty {
    .accordion-head {
      cursor: default;
    }
  }
  &.logisticStyles {
    @include bp-830 {
      height: 160px;
      max-height: 160px;
    }
  }
  &.open {
    max-height: 600px;
    overflow: auto;
    & > .accordion-head {
      color: $pink;
      .accordion-icon {
        transform: rotate(0);
      }
    }
  }
  &-head {
    height: 80px;
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    color: $dark;
    transition: 0.5s all;
    @include bp-480 {
      padding: 5px;
    }
  }
  &-body {
    padding: 20px;
    &.closed {
      height: 0px;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.logisticHeadStyles {
  @include bp-830 {
    height: 160px;
  }
}
.slim {
  padding-top: 0px;
  padding-bottom: 0px;
}
.slim-head {
  height: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.accordion-nested {
  margin-bottom: 0px;
}
</style>
