<template>
  <div class="modal-error">
    <div class="title">Are you sure?</div>

    <div class="btn_wrap">
      <button class="btn-white" @click="close">No</button>
      <button class="btn-pink" @click="confirm">Yes</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

@Component({
  components: {},
})
export default class ModalSureDelete extends Vue {
  @Prop() data!: { user: any; data: any };
  public modalHub = ModalHub;

  public close() {
    ModalHub.$emit('close', { animation: 'fade-leave-to' });
  }
  public confirm() {
    if (this.data.user) {
      http
        .put(`admin/${this.data.data.vendorSlug}/orders/${this.data.data.orderId}`, {
          status: 'declined',
          statusDetails: {
            declinedBy: `${this.data.user.firstName} ${this.data.user.lastName || ''}`.trim(),
            message: `The order is declined by ${this.data.user.firstName} ${this.data.user.lastName || ''}`.trim(),
          },
        })
        .then((res: any) => {
          ModalHub.$emit('updateOrders');
          ModalHub.$emit('close');
        })
        .catch((err: any) => ModalHub.$emit('open', 'modal-error', { data: { message: err } }));
    }

    ModalHub.$emit('close');
  }
}
</script>

<style lang="scss" scoped>
.modal-error {
  &.modal-inner {
    width: 340px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}
.title {
  font-size: 20px;
  margin: 20px 0 10px;
}
.subtitle {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 150%;
}
.btn_wrap {
  display: flex;
  justify-content: space-between;
  & > * {
    flex-basis: 40%;
  }
}
.btn-white {
  background-color: #f8f8f8;
}
</style>
