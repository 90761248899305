import moment from 'moment-timezone';

export const formatDate = (date: Date, format = 'YYYY-MM-DD'): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatTime = (date: Date) => {
  let h: string | number = date.getHours();
  let m: string | number = date.getMinutes();

  if (h < 10) h = `0${h}`;
  if (m < 10) m = `0${m}`;

  return `${h}:${m}`;
};

export type dataPicker = {
  startDate: Date;
  endDate: Date;
};

export const getCurrentMonthRange = (): dataPicker => {
  const now = new Date();
  return {
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
  };
};

export const getPreviousWeekRange = (): dataPicker => {
  const now = new Date();
  return {
    startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
  };
};
