<template>
  <div class="modal-order">
    <div class="modal-head">
      <div class="close" @click="modalHub.$emit('close')">
        <app-icon name="back" />
      </div>
      <p>Order info</p>
    </div>

    <div v-if="order" class="modal-main columns is-gapless is-multiline">
      <div class="restaurant-info column is-8">
        <div class="vendor logo">
          <img :src="order.vendor.logo" :alt="order.vendor.name + ' logo'" />
        </div>
        <div class="restaurant-title">
          <div class="restaurant-name">{{ order.restaurant.name }}</div>
          <div class="restaurant-location">{{ order.restaurant.street }}</div>
        </div>
      </div>

      <div class="order-condition order-condition-green column is-4">
        {{ order.status.split('_').join(' ') }}
      </div>
      <div class="order-menu columns is-gapless">
        <div v-for="(dish, name, indexMain) of formatedDishes" :key="indexMain" class="order-menu-dish">
          <div class="dish-title">
            <div class="dish-name">
              {{ dish.amount }}x -
              <span>{{ dish.name }}</span>
            </div>
            <div
              v-for="(d, index) of dish.modificators"
              :key="`${d.id}_${index}${indexMain}`"
              class="dish-modificators"
            >
              {{ `${d.amount * dish.amount}x - ${d.name}` }}
            </div>
          </div>
          <div class="dish-price">
            <div>£ {{ (dish.price * dish.amount).toFixed(2) }}</div>
            <div v-for="(dPrice, indexPr) of dish.modificators" :key="`${dPrice.id}_${indexPr}${indexMain}`">
              £ {{ (dPrice.price * dPrice.amount * dish.amount).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>

      <div class="total column is-12">
        <p class="total-title">Total</p>
        <p class="total-price">£{{ totalPriceWithoutDiscount.toFixed(2) }}</p>
      </div>

      <div
        v-for="log of order.statusesLog.filter(function (obj) {
          return !['picking', 'confirmed', 'arriving'].includes(obj.status);
        })"
        :key="log.status + log.time"
        class="order-completion-info column is-12"
      >
        <p class="completion-title">{{ log.status.split('_').join(' ') }}</p>
        <p class="completion-info">{{ formatTime(log.time) }}</p>
      </div>

      <div class="order-completion-info column is-12">
        <p class="completion-title">OrderId</p>
        <p class="completion-info">{{ order.friendlyId }}</p>
      </div>
      <div class="order-completion-info column is-12">
        <p class="completion-title">Delivery address</p>
        <p class="completion-info">
          {{ order.address.street }}, {{ order.address.flatAndBuilding }}, {{ order.address.postcode }}
        </p>
      </div>
      <div class="order-completion-info last column is-12">
        <p class="completion-title">Customer details</p>
        <div class="customer-details">
          <div class="customer-info">
            <p>{{ order.user.firstName }} {{ order.user.lastName }}</p>
            <p>{{ order.contactPhone }}</p>
          </div>
        </div>
      </div>
      <div class="order-completion-info last column is-12">
        <p class="completion-title">Type</p>
        <div class="customer-details">
          <div class="customer-info">
            <p class="capitalize">{{ order.type }}</p>
          </div>
        </div>
      </div>
      <div class="order-completion-info last column is-12">
        <p class="completion-title">Food Due Time</p>
        <div class="customer-details">
          <div class="customer-info">
            <p>{{ formatTime(order.foodDueTime) }}</p>
          </div>
        </div>
      </div>
      <download-csv
        :data="datatoPrint"
        :name="`order${order.friendlyId}.csv`"
        :labels="keysToDisplay"
        class="download-excel"
      >
        <app-icon name="excel"></app-icon>
      </download-csv>
    </div>
  </div>
</template>
<script lang="ts">
import moment from 'moment-timezone';
import downloadCsv from 'vue-json-csv';
import { Component, Prop, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import { formatDishes } from '@/_shared/utils/cart';
import http from '@/_shared/utils/http';

@Component({
  components: {
    AppLogo,
    downloadCsv,
  },
})
export default class ModalOrderHistoryDelivered extends Vue {
  @Prop() data!: { vendorSlug: any; orderId: any };
  public order: IOrderHistory | null = null;
  public modalHub = ModalHub;
  public csv = null;

  public mounted() {
    this.getOrder();
  }
  private keysToDisplay = {
    orderId: 'Order ID',
    item: 'Item',
    cost: 'Cost',
    orderDate: 'Date of order',
    orderStatus: 'Status',
  };

  public getOrder() {
    http
      .get(`/admin/${this.data.vendorSlug}/orders/${this.data.orderId}`)
      .then((order: IOrderHistory) => {
        this.order = order;
      })
      .catch((err: Error) => this.modalHub.$emit('open', 'modal-error', { data: { message: err } }));
  }
  get totalPriceWithoutDiscount() {
    return Math.round(parseFloat(this.order!.cartPrice) * 100) / 100;
  }

  get datatoPrint() {
    const result: any = [];
    const basket: any = this.order;

    basket.cart.dishes.forEach((dish: any) => {
      for (let i = 0; i < dish.amount; i++) {
        result.push({
          orderId: basket.friendlyId,
          orderDate: moment(basket.createdAt).format('YYYY-MM-DD'),
          orderStatus: basket.status,
          item: dish.name,
          cost: `£ ${dish.price}`,
        });
      }
      dish.modificators.forEach((modificator: any) => {
        for (let i = 0; i < modificator.amount * dish.amount; i++) {
          result.push({
            orderId: basket.friendlyId,
            orderDate: moment(basket.createdAt).format('YYYY-MM-DD'),
            orderStatus: basket.status,
            item: modificator.name,
            cost: `£ ${modificator.price}`,
          });
        }
      });
    });
    return result;
  }

  get formatedDishes() {
    return formatDishes(this.order!.cart.dishes);
  }

  public close() {
    ModalHub.$emit('close');
  }

  private formatTime(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
}

interface IOrderHistory {
  id: string;
  friendlyId: number;
  userId: number;
  vendorId: number;
  restaurantId: number;
  restaurantSlug: string;
  status: string;
  statusDetails: {};
  statusesLog: [
    {
      time: string;
      status: string;
    },
  ];
  processesDurationMins: string;
  deliverToTime: string;
  address: {
    street: string;
    postcode: string;
    flatAndBuilding: string;
  };
  contactPhone: string;
  cart: {
    dishes: [
      {
        id: number;
        name: string;
        price: number;
        amount: number;
        modificators: [];
      },
    ];
  };
  paymentMethod: string;
  stripePaymentIntentId: string;
  cartPrice: string;
  deliveryPrice: string;
  totalPrice: string;
  createdAt: string;
  updatedAt: string;
  restaurant: {};
  coupon: {
    discount: number;
  };
  vendor: {
    id: string;
    name: string;
    description: string;
    contactEmail: string;
    logo: string;
    cover: string;
    isPublished: string;
    createdAt: string;
    updatedAt: string;
    user: IOrderUser;
  };
  foodDueTime: string;
}

interface IOrderUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  photo: string;
  facebookId: string;
  createdAt: string;
  updatedAt: string;
}
</script>

<style lang="scss" scoped>
.modal-order {
  &.modal-inner {
    width: 100%;
    height: 100%;
    max-width: 460px;
    overflow-x: hidden;
    padding: 0;
  }
  .modal-head {
    margin: 40px 0 40px;
    font-size: 14px;
    text-align: center;
    position: relative;
    .close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      margin-left: 40px;
    }
  }
}
.modal-main {
  padding: 40px 40px;
  margin: 0;
}
.logo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.restaurant-info {
  display: flex;
  flex-direction: row;
}
.restaurant-title {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-left: 20px;
}
.restaurant-name {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $dark;
}
.restaurant-location {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
}
.order-condition {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 112.9%;
  &-green {
    color: #4da54b;
  }
  &-red {
    color: #e24e4e;
  }
  @include bp-650 {
    align-items: flex-start;
    margin-top: 20px;
  }
}
.order-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid $gray;
}
.order-reason {
  width: 100%;
  background-color: #f8f8f8;
  padding: 20px 20px 0px 20px;
  margin-top: 40px;
}
.order-reason-item {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  @include bp-650 {
    flex-direction: column;
    justify-content: center;
    height: 60px;
  }
}
.order-reason-title {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
}
.order-reason-text {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 150%;
}
.order-menu-dish {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 22px;
}
.dish-title {
  display: flex;
  flex-direction: column;
}
.dish-name {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: $dark;
  span {
    text-transform: uppercase;
  }
}
.dish-modificators {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: $dark;
}
.dish-price {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: $pink;
}
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-title {
    font-family: $Montserrat;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $dark;
  }
  &-price {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: $pink;
  }
}
.order-completion-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray;
}
.completion-title {
  font-family: $Montserrat;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
  &.last {
    margin-bottom: 0;
  }
}
.completion-info {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $dark;
  text-align: right;
  max-width: 170px;
}
.customer-details {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.customer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  p {
    font-family: $Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: $dark;
  }
}
.customer-photo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.btn-red {
  margin-top: 60px;
  width: 100%;
}
.btn-pink {
  margin-top: 60px;
  width: 100%;
}
.download-excel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
</style>
