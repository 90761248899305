import _ from 'lodash';

export const formatDishes = (cart: any) => {
  const finalBasket: any = [];
  cart.forEach((cartElement: any) => {
    const { amount, ...restOfCartElement } = cartElement;
    const index = finalBasket.findIndex(({ amount, ...rest }: any) => _.isEqual(rest, restOfCartElement));
    if (index > -1) {
      finalBasket[index].amount = finalBasket[index].amount + amount;
    } else {
      finalBasket.push(cartElement);
    }
  });
  return finalBasket;
};
