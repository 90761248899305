<template>
  <modal-order-info :order="order" :orderAccept="formatTime(acceptTime)" :orderKitchen="formatTime(kitchenTime)">
    <template #status>
      <p class="delivering">DELIVERING</p>
    </template>
    <div v-if="!loading" class="wrapper">
      <div class="btn-green submit" @click="submit">Delivered</div>
      <span class="btn-red submit" @click="cancel">Cancel order</span>
    </div>
    <app-loader v-else />
  </modal-order-info>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import ModalHub from '@/_shared/modals/modal-hub';
import ModalOrderInfo from '@/_shared/modals/modal-order-info-base.vue';
import http from '@/_shared/utils/http';

import { User } from '@/_types/shared.types';

@Component({
  components: {
    AppLogo,
    ModalOrderInfo,
  },
})
export default class ModalOrderDelivering extends Vue {
  @Prop() data!: { vendorSlug: any; orderId: any };
  public user: User | null = null;
  public order: any = {};
  public modalHub = ModalHub;
  public loading = false;

  public mounted() {
    this.getUser();
    http.get(`/admin/${this.data.vendorSlug}/orders/${this.data.orderId}`).then((order: any) => {
      this.order = order;
    });
  }

  public getUser() {
    this.user = this.$store.state.user;
  }

  get acceptTime() {
    return this.order?.statusesLog?.filter((elem: any) => elem.status === 'preparing')[0]?.time;
  }

  get kitchenTime() {
    return this.order?.statusesLog?.filter(
      (elem: any) => elem.status === 'ready_for_delivery' || elem.status === 'delivering',
    )[0]?.time;
  }

  public formatTime(time: string) {
    return time ? moment(time).tz('Europe/London').format('HH:mm') : null;
  }

  public submit() {
    this.loading = true;

    http
      .put(`admin/${this.data.vendorSlug}/orders/${this.data.orderId}`, {
        status: 'delivered',
      })
      .then((res: any) => {
        this.loading = false;
        ModalHub.$emit('updateOrders');
        ModalHub.$emit('close');
      })
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      });
  }

  public cancel() {
    ModalHub.$emit('open', 'modal-sure-delete', { data: { user: this.user, data: this.data } });
  }

  public close() {
    ModalHub.$emit('close');
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.submit {
  width: calc(100% - 80px);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $white;
}
</style>
