<template>
  <div>
    <app-nav-bar>
      <li><router-link to="restaurants" tag="a">Restaurant Menu</router-link></li>
      <li><router-link to="dishes" tag="a">Dishes</router-link></li>
      <li><router-link to="modificators" tag="a">Modificators</router-link></li>
    </app-nav-bar>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Menu extends Vue {}
</script>
