const LocalEnvironment = {
  clientUrl: 'http://localhost:8080',
  adminUrl: 'http://localhost:8081',
  // baseApiUrl: 'https://api-dev.wearefoodstuff.co.uk',
  baseApiUrl: 'http://localhost:3000',
  sentryDsn: '',
  sentryEnv: 'localhost',
  stripePublicKey:
    'pk_test_51INcMFDgRJ6RHY1AlDRZAAsrL0mUpN28JwlzCezG3qddimZ1TifhdA5kvXaE7tc3T2n7v6qmhhEhAceeeubWgmQT00oOBEA5OR',
};

const DevEnvironment = {
  clientUrl: 'https://dev.wearefoodstuff.co.uk',
  adminUrl: 'https://admin-dev.wearefoodstuff.co.uk',
  baseApiUrl: 'https://api-dev.wearefoodstuff.co.uk',
  sentryDsn: 'https://14687b1545234754a5791cbf34192796@o330611.ingest.us.sentry.io/1852687',
  sentryEnv: 'dev',
  stripePublicKey:
    'pk_test_51INcMFDgRJ6RHY1AlDRZAAsrL0mUpN28JwlzCezG3qddimZ1TifhdA5kvXaE7tc3T2n7v6qmhhEhAceeeubWgmQT00oOBEA5OR',
};

const StageEnvironment = {
  clientUrl: 'https://stage.wearefoodstuff.co.uk',
  adminUrl: 'https://admin-stage.wearefoodstuff.co.uk',
  baseApiUrl: 'https://api-stage.wearefoodstuff.co.uk',
  sentryDsn: 'https://14687b1545234754a5791cbf34192796@o330611.ingest.us.sentry.io/1852687',
  sentryEnv: 'stage',
  stripePublicKey:
    'pk_test_51INcMFDgRJ6RHY1AlDRZAAsrL0mUpN28JwlzCezG3qddimZ1TifhdA5kvXaE7tc3T2n7v6qmhhEhAceeeubWgmQT00oOBEA5OR',
};

const ProdEnvironment = {
  clientUrl: 'https://wearefoodstuff.co.uk',
  adminUrl: 'https://admin.wearefoodstuff.co.uk',
  baseApiUrl: 'https://api.wearefoodstuff.co.uk',
  sentryDsn: 'https://14687b1545234754a5791cbf34192796@o330611.ingest.us.sentry.io/1852687',
  sentryEnv: 'prod',
  stripePublicKey:
    'pk_live_51INcMFDgRJ6RHY1AK8iYURHLjIQwNXkkU5QmhaDZiJrWQARaFCmrHXDUyo2DmAFVOuhB2e69yGp6FOkSuKdNVeuJ00rVVlHx37',
};

function configureEnvironment() {
  switch (process.env.VUE_APP_ENV) {
    case 'dev':
      return DevEnvironment;
    case 'stage':
      return StageEnvironment;
    case 'master':
      return ProdEnvironment;
    default:
      return LocalEnvironment;
  }
}

const Environment = configureEnvironment();

export default Environment;
