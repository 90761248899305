<template>
  <div class="payouts">
    <div class="inputs-wrapper">
      <h2>Download spreadsheet</h2>
      <label class="label">Date range</label>
      <app-datepicker v-model="dateRangeDownload" opens="right" />
      <label class="label">First invoice number</label>
      <input v-model="invoiceNumber" class="text-field" type="number" placeholder="e.g. 10001" />
      <label class="label">Password</label>
      <input v-model="encryptionKey" class="text-field" type="password" autocomplete="new-password" />
      <input
        class="btn-pink modal-submit"
        :class="{ 'btn-disabled': loading }"
        type="submit"
        value="Download"
        :disabled="loading"
        @click.prevent="downloadPayoutsData"
      />
      <app-modal-loader v-if="loading" />
    </div>
  </div>
</template>

<script lang="ts">
import CryptoJS from 'crypto-js';
import { Component, Vue } from 'vue-property-decorator';
import * as XLSX from 'xlsx-js-style';

import ModalHub from '@/_shared/modals/modal-hub';
import { getPreviousWeekRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

@Component({})
export default class Payouts extends Vue {
  // State:
  public loading: boolean = false;
  public dateRangeDownload = getPreviousWeekRange();
  public invoiceNumber: number | null = null;
  public encryptionKey: string = '';

  // Spreadsheet styles:
  public readonly styleBold = { font: { name: 'Arial', sz: 10, bold: true } };
  public readonly styleStandard = { font: { name: 'Arial', sz: 10 } };
  public readonly styleStandardFloat = { ...this.styleStandard, numFmt: '#,##0.00' };
  public readonly styleStandardPercent = { ...this.styleStandard, numFmt: '0.0%' };
  public readonly styleStandardPound = { ...this.styleStandard, numFmt: '"£"#,##0.00' };

  private async downloadPayoutsData() {
    this.loading = true;

    const { startDate, endDate } = this.dateRangeDownload;
    const start = new Date(startDate).toISOString().split('T')[0];
    const end = new Date(endDate).toISOString().split('T')[0];

    try {
      const response: PayoutsData = await http.get(
        `/admin/payouts?startFoodDueDate=${start}&endFoodDueDate=${end}&invoiceNumber=${this.invoiceNumber}`,
      );

      const deliveryOrdersSheet = this.buildDeliveryOrdersSheet(response.deliveryOrdersData);
      const collectionOrdersSheet = this.buildCollectionOrdersSheet(response.collectionOrdersData);
      const deductionsSheet = this.buildDeductionsSheet(response.deductionsData);
      const additionalsSheet = this.buildAdditionalsSheet(response.additionalsData);
      const workplaceSheet = this.buildWorkplaceSheet(response.workplaceData);
      const calculationsSheet = this.buildCalculationsSheet(response.calculationsData);
      const xeroSheet = this.buildXeroCsvSheet(response.xeroData);
      const ccXeroSheet = this.buildCcXeroSheet(response.collectionXeroData);
      const statementSheet = this.buildPayoutStatementSheet(response.statementData);
      const allVendorsSheet = this.buildAllVendorsSheet(response.allVendorsData);
      const creditorsSheet = this.buildTradeCreditorsSheet(response.creditorsData);
      const starlingSheet = this.buildStarlingTemplateSheet(response.starlingData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, deliveryOrdersSheet, 'Delivery orders');
      XLSX.utils.book_append_sheet(workbook, collectionOrdersSheet, 'CC orders');
      XLSX.utils.book_append_sheet(workbook, deductionsSheet, 'Deductions');
      XLSX.utils.book_append_sheet(workbook, additionalsSheet, 'Additionals');
      XLSX.utils.book_append_sheet(workbook, workplaceSheet, 'Workplace');
      XLSX.utils.book_append_sheet(workbook, calculationsSheet, 'Calculations');
      XLSX.utils.book_append_sheet(workbook, xeroSheet, 'Xero CSV');
      XLSX.utils.book_append_sheet(workbook, ccXeroSheet, 'CC Xero');
      XLSX.utils.book_append_sheet(workbook, statementSheet, 'Payout Statement');
      XLSX.utils.book_append_sheet(workbook, allVendorsSheet, 'Vendor list');
      XLSX.utils.book_append_sheet(workbook, creditorsSheet, 'Trade creditors');
      XLSX.utils.book_append_sheet(workbook, starlingSheet, 'Starling Template');
      XLSX.writeFile(workbook, `payouts_${start}_${end}.xlsx`);
    } catch (e: any) {
      ModalHub.$emit('open', 'modal-error', { data: { message: e } });
    } finally {
      this.loading = false;
    }
  }

  // TAB 1:
  private buildDeliveryOrdersSheet(data: PayoutsData['deliveryOrdersData']): XLSX.WorkSheet {
    const columnNames = ['Vendor Name', 'Vendor ID', 'Sum of Cart Price', 'Count'];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const deliveryOrdersAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.sumOfCartPrice, t: 'n', s: this.styleStandardFloat },
      { v: el.count, t: 'n', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const deliveryOrdersSheet = XLSX.utils.aoa_to_sheet([headerRow, ...deliveryOrdersAOA]);
    deliveryOrdersSheet['!cols'] = [{ wch: vendorNameColumnChars }, { wch: 10 }, { wch: 15 }, { wch: 10 }];

    return deliveryOrdersSheet;
  }

  // TAB 2:
  private buildCollectionOrdersSheet(data: PayoutsData['collectionOrdersData']): XLSX.WorkSheet {
    const columnNames = ['Vendor Name', 'Vendor ID', 'Sum of Cart Price', 'Count'];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const collectionOrdersAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.sumOfCartPrice, t: 'n', s: this.styleStandardFloat },
      { v: el.count, t: 'n', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const collectionOrdersSheet = XLSX.utils.aoa_to_sheet([headerRow, ...collectionOrdersAOA]);
    collectionOrdersSheet['!cols'] = [{ wch: vendorNameColumnChars }, { wch: 10 }, { wch: 15 }, { wch: 10 }];

    return collectionOrdersSheet;
  }

  // TAB 3:
  private buildDeductionsSheet(data: PayoutsData['deductionsData']): XLSX.WorkSheet {
    const columnNames = ['Vendor Name', 'Vendor ID', 'Deduction', 'Description', 'Date of Deduction', 'Friendly ID'];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const deductionsAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.value, t: 'n', s: this.styleStandardFloat },
      { v: el.description, t: 's', s: this.styleStandard },
      { v: el.date, t: 's', s: this.styleStandard },
      { v: el.orderId || '', t: el.orderId ? 'n' : 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const descriptionColumnChars = data.reduce((w, r) => Math.max(w, r.description.length), 15);
    const deductionsSheet = XLSX.utils.aoa_to_sheet([headerRow, ...deductionsAOA]);
    deductionsSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: 10 },
      { wch: 10 },
      { wch: descriptionColumnChars },
      { wch: 15 },
      { wch: 10 },
    ];

    return deductionsSheet;
  }

  // TAB 4:
  private buildAdditionalsSheet(data: PayoutsData['additionalsData']): XLSX.WorkSheet {
    const columnNames = ['Vendor Name', 'Vendor ID', 'Additionals', 'Description', 'Date of Additional', 'Friendly ID'];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const additionalsAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.value, t: 'n', s: this.styleStandardFloat },
      { v: el.description, t: 's', s: this.styleStandard },
      { v: el.date, t: 's', s: this.styleStandard },
      { v: el.orderId || '', t: el.orderId ? 'n' : 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const descriptionColumnChars = data.reduce((w, r) => Math.max(w, r.description.length), 15);
    const additionalsSheet = XLSX.utils.aoa_to_sheet([headerRow, ...additionalsAOA]);
    additionalsSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: 10 },
      { wch: 10 },
      { wch: descriptionColumnChars },
      { wch: 15 },
      { wch: 10 },
    ];

    return additionalsSheet;
  }

  // TAB 5:
  private buildWorkplaceSheet(data: PayoutsData['workplaceData']): XLSX.WorkSheet {
    const columnNames = ['Vendor Name', 'Vendor ID', 'Friendly ID', 'Restaurant Discount', 'Cart Price'];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const workplaceAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.friendlyId, t: 'n', s: this.styleStandard },
      { v: el.restaurantDiscount, t: 'n', s: this.styleStandardFloat },
      { v: el.cartPrice, t: 'n', s: this.styleStandardFloat },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const workplaceSheet = XLSX.utils.aoa_to_sheet([headerRow, ...workplaceAOA]);
    workplaceSheet['!cols'] = [{ wch: vendorNameColumnChars }, { wch: 10 }, { wch: 10 }, { wch: 18 }, { wch: 15 }];

    return workplaceSheet;
  }

  // TAB 6:
  private buildCalculationsSheet(data: PayoutsData['calculationsData']): XLSX.WorkSheet {
    const columnNames = [
      'Vendor Name',
      'Delivery Orders',
      'Deductions',
      'Additionals',
      'Workplace Discount',
      'Delivery Total',
      'CC Orders',
      'CC Workplace Discount',
      'CC Total',
      'Delivery & CC Total',
      'Delivery Fees',
      'CC Fees',
      'Delivery Fees VAT',
      'CC Fees VAT',
      'Gross Delivery Fees',
      'Gross CC Fees',
      'Total Payable',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const calculationsAOA = data.map((el) => [
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.sumOfDeliveryCartPrices, t: 'n', s: this.styleStandardFloat },
      { v: el.sumOfDeliveryDeductions, t: 'n', s: this.styleStandardFloat },
      { v: el.sumOfDeliveryAdditionals, t: 'n', s: this.styleStandardFloat },
      { v: el.sumOfDeliveryRestaurantDiscounts, t: 'n', s: this.styleStandardFloat },
      { v: el.deliveryTotal, t: 'n', s: this.styleStandardFloat },
      { v: el.sumOfCollectionCartPrices, t: 'n', s: this.styleStandardFloat },
      { v: el.sumOfCollectionRestaurantDiscounts, t: 'n', s: this.styleStandardFloat },
      { v: el.collectionTotal, t: 'n', s: this.styleStandardFloat },
      { v: el.bothTypesTotal, t: 'n', s: this.styleStandardFloat },
      { v: el.totalPayable ? el.deliveryFees : '', t: el.totalPayable ? 'n' : 's', s: this.styleStandardFloat },
      { v: el.totalPayable ? el.collectionFees : '', t: el.totalPayable ? 'n' : 's', s: this.styleStandardFloat },
      { v: el.totalPayable ? el.deliveryFeesVAT : '', t: el.totalPayable ? 'n' : 's', s: this.styleStandardFloat },
      {
        v: el.totalPayable ? el.collectionFeesVAT : '',
        t: el.totalPayable ? 'n' : 's',
        s: this.styleStandardFloat,
      },
      {
        v: el.totalPayable ? el.deliveryFeesGross : '',
        t: el.totalPayable ? 'n' : 's',
        s: this.styleStandardFloat,
      },
      {
        v: el.totalPayable ? el.collectionFeesGross : '',
        t: el.totalPayable ? 'n' : 's',
        s: this.styleStandardFloat,
      },
      { v: el.totalPayable || '', t: el.totalPayable ? 'n' : 's', s: this.styleStandardFloat },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const calculationsSheet = XLSX.utils.aoa_to_sheet([headerRow, ...calculationsAOA]);
    calculationsSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: 13 },
      { wch: 10 },
      { wch: 10 },
      { wch: 18 },
      { wch: 12 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 18 },
      { wch: 12 },
      { wch: 10 },
      { wch: 15 },
      { wch: 12 },
      { wch: 18 },
      { wch: 15 },
      { wch: 15 },
    ];

    return calculationsSheet;
  }

  // TAB 7:
  private buildXeroCsvSheet(data: PayoutsData['xeroData']): XLSX.WorkSheet {
    const columnNames = [
      '*ContactName',
      'EmailAddress',
      'POAddressLine1',
      'POAddressLine2',
      'POAddressLine3',
      'POAddressLine4',
      'POCity',
      'PORegion',
      'POPostalCode',
      'POCountry',
      '*InvoiceNumber',
      'Reference',
      '*InvoiceDate',
      '*DueDate',
      'Total',
      'InventoryItemCode',
      '*Description',
      '*Quantity',
      '*UnitAmount',
      'Discount',
      '*AccountCode',
      '*TaxType',
      'TaxAmount',
      'TrackingName1',
      'TrackingOption1',
      'TrackingName2',
      'TrackingOption2',
      'Currency',
      'BrandingTheme',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const xeroAOA = data.map((el) => [
      { v: el.contactName, t: 's', s: this.styleStandard },
      { v: el.emailAddress, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.invoiceNumber, t: 's', s: this.styleStandard },
      { v: el.reference, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.inventoryItemCode, t: 's', s: this.styleStandard },
      { v: el.description, t: 's', s: this.styleStandard },
      { v: el.quantity, t: 'n', s: this.styleStandard },
      { v: el.unitAmount, t: 'n', s: this.styleStandardFloat },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.accountCode, t: 'n', s: this.styleStandard },
      { v: el.taxType, t: 's', s: this.styleStandard },
      { v: el.taxAmount, t: 'n', s: this.styleStandardFloat },
      { v: 'Location', t: 's', s: this.styleStandard },
      { v: el.city, t: 's', s: this.styleStandard },
      { v: el.totalAmount, t: 'n', s: this.styleStandardFloat },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.brandingTheme, t: 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.contactName.length), 15);
    const descriptionColumnChars = data.reduce((w, r) => Math.max(w, (r.description || '').length), 15);
    const emailColumnChars = data.reduce((w, r) => Math.max(w, (r.emailAddress || '').length), 15);
    const xeroSheet = XLSX.utils.aoa_to_sheet([headerRow, ...xeroAOA]);
    xeroSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: emailColumnChars },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 15 },
      { wch: 18 },
      { wch: 12 },
      { wch: 12 },
      { wch: 5 },
      { wch: 18 },
      { wch: descriptionColumnChars },
      { wch: 10 },
      { wch: 10 },
      { wch: 5 },
      { wch: 12 },
      { wch: 18 },
      { wch: 12 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 5 },
      { wch: 5 },
      { wch: 15 },
    ];

    return xeroSheet;
  }

  // TAB 8:
  private buildCcXeroSheet(data: PayoutsData['collectionXeroData']): XLSX.WorkSheet {
    const columnNames = [
      '*ContactName',
      'EmailAddress',
      'POAddressLine1',
      'POAddressLine2',
      'POAddressLine3',
      'POAddressLine4',
      'POCity',
      'PORegion',
      'POPostalCode',
      'POCountry',
      '*InvoiceNumber',
      'Reference',
      '*InvoiceDate',
      '*DueDate',
      'Total',
      'InventoryItemCode',
      '*Description',
      '*Quantity',
      '*UnitAmount',
      'Discount',
      '*AccountCode',
      '*TaxType',
      'TaxAmount',
      'TrackingName1',
      'TrackingOption1',
      'TrackingName2',
      'TrackingOption2',
      'Currency',
      'BrandingTheme',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const ccXeroAOA = data.map((el) => [
      { v: el.contactName, t: 's', s: this.styleStandard },
      { v: el.emailAddress, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.invoiceNumber, t: 's', s: this.styleStandard },
      { v: el.reference, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.inventoryItemCode, t: 's', s: this.styleStandard },
      { v: el.description, t: 's', s: this.styleStandard },
      { v: el.quantity, t: 'n', s: this.styleStandard },
      { v: el.unitAmount, t: 'n', s: this.styleStandardFloat },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.accountCode, t: 'n', s: this.styleStandard },
      { v: el.taxType, t: 's', s: this.styleStandard },
      { v: el.taxAmount, t: 'n', s: this.styleStandardFloat },
      { v: 'Location', t: 's', s: this.styleStandard },
      { v: el.city, t: 's', s: this.styleStandard },
      { v: el.totalAmount, t: 'n', s: this.styleStandardFloat },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.brandingTheme, t: 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.contactName.length), 15);
    const descriptionColumnChars = data.reduce((w, r) => Math.max(w, (r.description || '').length), 15);
    const emailColumnChars = data.reduce((w, r) => Math.max(w, (r.emailAddress || '').length), 15);
    const ccXeroSheet = XLSX.utils.aoa_to_sheet([headerRow, ...ccXeroAOA]);
    ccXeroSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: emailColumnChars },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 15 },
      { wch: 18 },
      { wch: 12 },
      { wch: 12 },
      { wch: 5 },
      { wch: 18 },
      { wch: descriptionColumnChars },
      { wch: 10 },
      { wch: 10 },
      { wch: 5 },
      { wch: 12 },
      { wch: 18 },
      { wch: 12 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 5 },
      { wch: 5 },
      { wch: 15 },
    ];

    return ccXeroSheet;
  }

  // TAB 9:
  private buildPayoutStatementSheet(data: PayoutsData['statementData']): XLSX.WorkSheet {
    const columnNames = [
      'ContactName',
      'Reference',
      'InvoiceNumber',
      'InvoiceDate',
      'Quantity',
      'UnitAmount',
      'CommissionAmount',
      'Commission%',
      'CCQuantity',
      'CCUnitAmount',
      'CCCommissionAmount',
      'CCCommission%',
      'Additionals',
      'Deductions',
      'Workplace Discount',
      'TotalPayout',
      'Email',
      'CC',
      'Additionals (description)',
      'Deductions (description)',
      'Workplace (description)',
      'Additionals Total',
      'Deductions Total',
      'Workplace Total',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const statementAOA = data.map((el) => [
      { v: el.contactName, t: 's', s: this.styleStandard },
      { v: el.reference, t: 's', s: this.styleStandard },
      { v: el.invoiceNumber, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: el.deliveryQuantity, t: 'n', s: this.styleStandard },
      { v: el.deliveryUnitAmount, t: 'n', s: this.styleStandardFloat },
      { v: el.deliveryCommissionAmount, t: 'n', s: this.styleStandardFloat },
      { v: el.deliveryCommissionPercent, t: 'n', s: this.styleStandardPercent },
      { v: el.collectionQuantity, t: 'n', s: this.styleStandard },
      { v: el.collectionUnitAmount, t: 'n', s: this.styleStandardFloat },
      { v: el.collectionCommissionAmount, t: 'n', s: this.styleStandardFloat },
      { v: el.collectionCommissionPercent, t: 'n', s: this.styleStandardPercent },
      { v: el.additionals, t: 'n', s: this.styleStandardFloat },
      { v: el.deductions, t: 'n', s: this.styleStandardFloat },
      { v: el.restaurantDiscounts, t: 'n', s: this.styleStandardFloat },
      { v: el.totalPayout, t: 'n', s: this.styleStandardFloat },
      { v: el.emailAddress, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.additionalsDescription, t: 's', s: this.styleStandard },
      { v: el.deductionsDescription, t: 's', s: this.styleStandard },
      { v: el.restaurantDiscountsDescription, t: 's', s: this.styleStandard },
      { v: el.additionals ? -el.additionals : undefined, t: 'n', s: this.styleStandardPound },
      { v: el.deductions ? -el.deductions : undefined, t: 'n', s: this.styleStandardPound },
      { v: el.restaurantDiscounts ? -el.restaurantDiscounts : undefined, t: 'n', s: this.styleStandardPound },
    ]);

    const additionalsDescriptionColumnChars = data
      .flatMap((el) => el.additionalsDescription?.split('\n') || [])
      .reduce((sum, additional) => Math.max(sum, additional.length), 20);
    const deductionsDescriptionColumnChars = data
      .flatMap((el) => el.deductionsDescription?.split('\n') || [])
      .reduce((sum, deduction) => Math.max(sum, deduction.length), 20);
    const discountsDescriptionColumnChars = data
      .flatMap((el) => el.restaurantDiscountsDescription?.split('\n') || [])
      .reduce((sum, discount) => Math.max(sum, discount.length), 20);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.contactName.length), 15);
    const emailColumnChars = data.reduce((w, r) => Math.max(w, (r.emailAddress || '').length), 15);
    const statementSheet = XLSX.utils.aoa_to_sheet([headerRow, ...statementAOA]);
    statementSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: 18 },
      { wch: 12 },
      { wch: 12 },
      { wch: 10 },
      { wch: 10 },
      { wch: 18 },
      { wch: 12 },
      { wch: 10 },
      { wch: 12 },
      { wch: 18 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
      { wch: 18 },
      { wch: 10 },
      { wch: emailColumnChars },
      { wch: 5 },
      { wch: additionalsDescriptionColumnChars },
      { wch: deductionsDescriptionColumnChars },
      { wch: discountsDescriptionColumnChars },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    return statementSheet;
  }

  // TAB 10:
  private buildAllVendorsSheet(data: PayoutsData['allVendorsData']): XLSX.WorkSheet {
    const decrypt = (ciphertext: string) => {
      try {
        return CryptoJS.AES.decrypt(ciphertext, this.encryptionKey).toString(CryptoJS.enc.Utf8);
      } catch (_) {
        return '';
      }
    };

    const columnNames = [
      'Vendor ID',
      'Restaurant',
      'City',
      'Emails',
      'Account Name',
      'Sort Code',
      'Account number',
      'Delivery Pricing Model',
      'CC Pricing Model',
      'VAT registered',
      'VAT number',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const allVendorsAOA = data.map((el) => [
      { v: el.vendorId, t: 'n', s: this.styleStandard },
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.city, t: 's', s: this.styleStandard },
      { v: el.emails, t: 's', s: this.styleStandard },
      { v: el.businessName, t: 's', s: this.styleStandard },
      { v: el.sortCode ? decrypt(el.sortCode) : '', t: 's', s: this.styleStandard },
      { v: el.accountNumber ? decrypt(el.accountNumber) : '', t: 's', s: this.styleStandard },
      { v: el.deliveryCommissionPercent, t: 'n', s: this.styleStandardPercent },
      { v: el.collectionCommissionPercent, t: 'n', s: this.styleStandardPercent },
      { v: el.isVatRegistered ? 'TRUE' : 'FALSE', t: 's', s: this.styleStandard },
      { v: el.vatNumber, t: 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const accountNameColumnChars = data.reduce((w, r) => Math.max(w, (r.businessName || '').length), 15);
    const emailsColumnChars = data.reduce((w, r) => Math.max(w, (r.emails || '').length), 15);
    const allVendorsSheet = XLSX.utils.aoa_to_sheet([headerRow, ...allVendorsAOA]);
    allVendorsSheet['!cols'] = [
      { wch: 8 },
      { wch: vendorNameColumnChars },
      { wch: 10 },
      { wch: emailsColumnChars },
      { wch: accountNameColumnChars },
      { wch: 10 },
      { wch: 15 },
      { wch: 18 },
      { wch: 15 },
      { wch: 12 },
      { wch: 10 },
    ];

    return allVendorsSheet;
  }

  // TAB 11:
  private buildTradeCreditorsSheet(data: PayoutsData['creditorsData']): XLSX.WorkSheet {
    const columnNames = [
      '*ContactName',
      'EmailAddress',
      'POAddressLine1',
      'POAddressLine2',
      'POAddressLine3',
      'POAddressLine4',
      'POCity',
      'PORegion',
      'POPostalCode',
      'POCountry',
      '*InvoiceNumber',
      '*InvoiceDate',
      '*DueDate',
      'Total',
      'InventoryItemCode',
      'Description',
      '*Quantity',
      '*UnitAmount',
      '*AccountCode',
      '*TaxType',
      'TaxAmount',
      'TrackingName1',
      'TrackingOption1',
      'TrackingName2',
      'TrackingOption2',
      'Currency',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const creditorsAOA = data.map((el) => [
      { v: el.contactName, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.invoiceNumber, t: 's', s: this.styleStandard },
      { v: el.invoiceDate, t: 's', s: this.styleStandard },
      { v: el.dueDate, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.description, t: 's', s: this.styleStandard },
      { v: el.quantity, t: 'n', s: this.styleStandard },
      { v: el.unitAmount, t: 'n', s: this.styleStandardFloat },
      { v: el.accountCode, t: 'n', s: this.styleStandard },
      { v: el.taxType, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: 'Location', t: 's', s: this.styleStandard },
      { v: el.city, t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
    ]);

    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.contactName.length), 15);
    const creditorsSheet = XLSX.utils.aoa_to_sheet([headerRow, ...creditorsAOA]);
    creditorsSheet['!cols'] = [
      { wch: vendorNameColumnChars },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 15 },
      { wch: 12 },
      { wch: 12 },
      { wch: 5 },
      { wch: 5 },
      { wch: 20 },
      { wch: 10 },
      { wch: 12 },
      { wch: 15 },
      { wch: 8 },
      { wch: 5 },
      { wch: 15 },
      { wch: 15 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
    ];

    return creditorsSheet;
  }

  // TAB 12:
  private buildStarlingTemplateSheet(data: PayoutsData['starlingData']): XLSX.WorkSheet {
    const decrypt = (ciphertext: string) => {
      try {
        return CryptoJS.AES.decrypt(ciphertext, this.encryptionKey).toString(CryptoJS.enc.Utf8);
      } catch (_) {
        return '';
      }
    };

    const columnNames = [
      'Sort Code',
      'Account Number',
      'First Name',
      'Last Name',
      'Business Name',
      'Reference',
      'Amount (GBP)',
      '',
      '',
      '',
      'Vendor',
      '',
    ];
    const headerRow = columnNames.map((el) => ({ v: el, t: 's', s: this.styleBold }));

    const starlingAOA = data.map((el) => [
      { v: el.sortCode ? decrypt(el.sortCode) : '', t: 's', s: this.styleStandard },
      { v: el.accountNumber ? decrypt(el.accountNumber) : '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.businessName, t: 's', s: this.styleStandard },
      { v: el.reference, t: 's', s: this.styleStandard },
      { v: el.amount, t: 'n', s: this.styleStandardFloat },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: '', t: 's', s: this.styleStandard },
      { v: el.vendorName, t: 's', s: this.styleStandard },
      { v: el.commissionPercent, t: 'n', s: this.styleStandardPercent },
    ]);

    const businessNameColumnChars = data.reduce((w, r) => Math.max(w, (r.businessName || '').length), 15);
    const vendorNameColumnChars = data.reduce((w, r) => Math.max(w, r.vendorName.length), 15);
    const starlingSheet = XLSX.utils.aoa_to_sheet([headerRow, ...starlingAOA]);
    starlingSheet['!cols'] = [
      { wch: 10 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
      { wch: businessNameColumnChars },
      { wch: 10 },
      { wch: 12 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: vendorNameColumnChars },
      { wch: 5 },
    ];

    return starlingSheet;
  }
}

interface PayoutsData {
  collectionOrdersData: {
    vendorName: string;
    vendorId: number;
    sumOfCartPrice: number;
    count: number;
  }[];
  deliveryOrdersData: {
    vendorName: string;
    vendorId: number;
    sumOfCartPrice: number;
    count: number;
  }[];
  deductionsData: {
    vendorName: string;
    vendorId: number;
    value: number;
    description: string;
    date: string;
    orderId: number;
  }[];
  additionalsData: {
    vendorName: string;
    vendorId: number;
    value: number;
    description: string;
    date: string;
    orderId: number;
  }[];
  workplaceData: {
    vendorName: string;
    vendorId: number;
    friendlyId: number;
    restaurantDiscount: number;
    cartPrice: number;
  }[];
  calculationsData: {
    vendorName: string;
    sumOfDeliveryCartPrices: number;
    sumOfDeliveryDeductions: number;
    sumOfDeliveryAdditionals: number;
    sumOfDeliveryRestaurantDiscounts: number;
    deliveryTotal: number;
    sumOfCollectionCartPrices: number;
    sumOfCollectionRestaurantDiscounts: number;
    collectionTotal: number;
    bothTypesTotal: number;
    deliveryFees?: number;
    deliveryFeesVAT?: number;
    deliveryFeesGross?: number;
    collectionFees?: number;
    collectionFeesVAT?: number;
    collectionFeesGross?: number;
    totalPayable?: number;
  }[];
  xeroData: {
    contactName: string;
    emailAddress?: string;
    invoiceNumber?: string;
    reference?: string;
    invoiceDate?: string;
    inventoryItemCode?: string;
    description?: string;
    quantity?: number;
    unitAmount?: number;
    accountCode?: number;
    taxType?: string;
    taxAmount?: number;
    city?: string;
    totalAmount?: number;
    brandingTheme?: string;
  }[];
  collectionXeroData: {
    contactName: string;
    emailAddress?: string;
    invoiceNumber?: string;
    reference?: string;
    invoiceDate?: string;
    inventoryItemCode?: string;
    description?: string;
    quantity?: number;
    unitAmount?: number;
    accountCode?: number;
    taxType?: string;
    taxAmount?: number;
    city?: string;
    totalAmount?: number;
    brandingTheme?: string;
  }[];
  statementData: {
    contactName: string;
    emailAddress?: string;
    invoiceNumber?: string;
    reference?: string;
    invoiceDate?: string;
    deliveryQuantity?: number;
    deliveryUnitAmount?: number;
    deliveryCommissionAmount?: number;
    deliveryCommissionPercent?: number;
    collectionQuantity?: number;
    collectionUnitAmount?: number;
    collectionCommissionAmount?: number;
    collectionCommissionPercent?: number;
    additionals?: number;
    deductions?: number;
    restaurantDiscounts?: number;
    totalPayout?: number;
    additionalsDescription?: string;
    deductionsDescription?: string;
    restaurantDiscountsDescription?: string;
  }[];
  allVendorsData: {
    vendorId: number;
    vendorName: string;
    isVatRegistered: boolean;
    city?: string;
    emails?: string;
    businessName?: string;
    sortCode?: string;
    accountNumber?: string;
    vatNumber?: string;
    deliveryCommissionPercent?: number;
    collectionCommissionPercent?: number;
  }[];
  creditorsData: {
    contactName: string;
    invoiceNumber?: string;
    invoiceDate?: string;
    dueDate?: string;
    description?: string;
    quantity?: number;
    unitAmount?: number;
    accountCode?: number;
    taxType?: string;
    city?: string;
  }[];
  starlingData: {
    vendorName: string;
    businessName?: string;
    sortCode?: string;
    accountNumber?: string;
    reference?: string;
    amount?: number;
    commissionPercent?: number;
  }[];
}
</script>

<style lang="scss" scoped>
.payouts {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  width: 100vw;
  height: calc(100vh - 100px);
}
.inputs-wrapper {
  width: 500px;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(209, 209, 209, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(209, 209, 209, 1);
  box-shadow: 8px 8px 24px 0px rgba(209, 209, 209, 1);
  padding: 40px;
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label {
  margin-top: 20px;
  margin-bottom: 5px;
}
.text-field {
  @extend .input;
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  border: $controlBorder;
  height: $controlHeight;
  span {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
.modal-submit {
  margin-top: 20px;
  width: 100%;
}
</style>
