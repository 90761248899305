import { render, staticRenderFns } from "./_modal-view.vue?vue&type=template&id=08a7975c&scoped=true"
import script from "./_modal-view.vue?vue&type=script&lang=ts"
export * from "./_modal-view.vue?vue&type=script&lang=ts"
import style0 from "./_modal-view.vue?vue&type=style&index=0&id=08a7975c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a7975c",
  null
  
)

export default component.exports