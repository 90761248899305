<template>
  <div>
    <app-nav-bar class="vendor-nav">
      <li>
        <router-link :to="`/finances/orders`" tag="a">Orders</router-link>
      </li>
      <li>
        <router-link :to="`/finances/vendor-fees`" tag="a">Vendor fees</router-link>
      </li>
      <li>
        <router-link v-if="false" :to="`/finances/delivery-fees`" tag="a">Delivery fees</router-link>
      </li>
    </app-nav-bar>

    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Finances extends Vue {}
</script>
