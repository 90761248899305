<template>
  <label class="app-switcher">
    <div v-if="prefix" class="switch-side" v-html="prefix"></div>
    <input
      ref="select"
      :class="flash && 'flash'"
      class="apple-switch"
      type="checkbox"
      :checked="!!value"
      @change="changeVal"
    />
    <div v-if="postfix" class="switch-side" v-html="postfix"></div>
    <div v-if="!flash" class="app-switcher-label">
      <slot></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppSwitcher extends Vue {
  @Prop() value: any;
  @Prop() flash: any;
  @Prop() prefix?: string;
  @Prop() postfix?: string;

  public changeVal(e: any) {
    this.$emit('input', e.target.checked);
  }
}
</script>

<style lang="scss" scoped>
.app-switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  &.is-marginless {
    margin: 0;
  }
  &:hover {
    .app-switcher-label {
      color: $pink;
    }
  }
}
.app-switcher-label {
  transition: 0.5s all;
  font-size: 14px;
  margin-left: 10px;
}
$gray: #818184;

input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px;
  height: 24px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 40px;
  transition: 0.4s all;
  cursor: pointer;
  margin: 0;
}
input.apple-switch:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: $gray;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 0 1px 3px $dark;
  transition: 0.3s all;
}

input.apple-switch:checked {
  background-color: $pink;
  box-shadow: inset 20px 0 0 0 $pink;
  border-color: $pink;
}

input.apple-switch:checked:after {
  left: 16px;
  background-color: $white;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
input.flash:checked:after {
  @include bp-768 {
    left: 26px;
  }
}
.switch-side {
  @include bp-1120 {
    font-size: 14px;
  }
  @include bp-830 {
    font-size: 12px;
  }
  @include bp-768 {
    font-size: 18px;
  }
}
input.flash {
  @include bp-1120 {
    width: 20px;
    height: 12px;
    @include bp-768 {
      width: 60px;
      height: 33px;
    }
  }
}
input.flash:after {
  @include bp-1120 {
    width: 12px;
    height: 12px;
  }
  @include bp-768 {
    width: 34px;
    height: 34px;
  }
}
</style>
