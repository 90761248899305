<template>
  <div>
    <div v-if="stats">
      <div class="container">
        <div class="header">
          <p>Financial information</p>
          <div class="stats-filters">
            <app-select v-model="restaurant" :options="restaurants" select-title="All restaurants" @input="getOrders" />
            <app-datepicker v-model="dateRange" opens="left" @input="getOrders" />
          </div>
        </div>
        <hr />
        <div class="columns statistics">
          <div class="column is-3 stats-block">
            <p class="heading">Total orders received</p>
            <div class="stats">
              <p class="stats-count">{{ stats.total.count }}</p>
              <p class="stats-money">£{{ stats.total.money.toFixed(2) }}</p>
            </div>
          </div>
          <div class="column is-3 stats-block">
            <p class="heading">Cancelled orders</p>
            <div class="stats stats--red">
              <p class="stats-count">{{ stats.cancelled.count }}</p>
              <p class="stats-money">-£{{ stats.cancelled.money.toFixed(2) }}</p>
            </div>
          </div>
          <div class="column is-3 stats-block">
            <p class="heading">Orders prepared</p>
            <div class="stats stats--blue">
              <p class="stats-count">{{ stats.prepared.count }}</p>
              <p class="stats-money">£{{ stats.prepared.money.toFixed(2) }}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <div class="stats-result">
              <p class="heading">Total money earned</p>
              <div class="stats">
                <p class="stats-title">{{ stats.prepared.count }} Prepared orders</p>
                <p class="stats-text text-blue">+£{{ stats.prepared.money.toFixed(2) }}</p>
                <hr />
                <p class="stats-title">{{ stats.cancelled.count }} Cancelled</p>
                <p class="stats-text text-red">-£{{ stats.cancelled.money.toFixed(2) }}</p>
                <hr />
                <p class="stats-title">You will receive</p>
                <p class="stats-text text-green">£{{ receivedMoney.toFixed(2) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span>Empty here</span>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import { getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

@Component({})
export default class Dashboard extends Vue {
  public dateRange = getCurrentMonthRange();
  public restaurant?: IRestaurant;
  public restaurants: IRestaurant[] = [];
  public orders: any = [];
  public loading = false;

  public stats: IStats = {
    total: {
      count: 0,
      money: 0,
    },
    cancelled: {
      count: 0,
      money: 0,
    },
    prepared: {
      count: 0,
      money: 0,
    },
  };

  get vendorSlug() {
    return this.$route.params['slug'];
  }

  get receivedMoney() {
    return Math.round((this.stats.prepared.money - this.stats.cancelled.money) * 100) / 100;
  }
  mounted() {
    this.getRestaurants();
    this.getOrders();
  }

  public getRestaurants() {
    http.get(`/admin/vendors/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      this.restaurants = res.restaurants;
    });
  }

  public getOrders() {
    let filters = '';
    const startDate = new Date(this.dateRange.startDate);
    const endDate = new Date(this.dateRange.endDate);
    startDate.setHours(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    const tzoffset = startDate.getTimezoneOffset() * 60000;

    filters += `&startDate=${new Date(startDate.getTime() - tzoffset).toISOString()}`;
    filters += `&endDate=${new Date(endDate.getTime() - tzoffset).toISOString()}`;
    filters += this.restaurant ? `&restaurantSlug=${this.restaurant.slug}` : '';

    this.loading = true;
    http
      .get(`/admin/orders/finances/all?vendorSlug=${this.vendorSlug}${filters}`)
      .then((res: any) => {
        this.orders = res;
        this.calculateStatistic();
        this.loading = false;
      })
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      });
  }

  private totalPriceWithoutDiscount(order: any) {
    return Math.round(order!.cartPrice * 100) / 100;
  }

  private calculateStatistic() {
    this.clearStats();
    for (let order of this.orders) {
      const totalPrice = this.totalPriceWithoutDiscount(order);
      this.stats.total.count += 1;
      this.stats.total.money += totalPrice;

      if (order.status !== 'new' && order.status !== 'preparing') {
        this.stats.prepared.count += 1;
        this.stats.prepared.money += totalPrice;
      }

      if (order.status === 'declined') {
        this.stats.cancelled.count += 1;
        this.stats.cancelled.money += totalPrice;
      }
    }
  }

  private clearStats() {
    this.stats.total.count = 0;
    this.stats.total.money = 0;
    this.stats.prepared.count = 0;
    this.stats.prepared.money = 0;
    this.stats.cancelled.count = 0;
    this.stats.cancelled.money = 0;
  }
}

export interface IRestaurant {
  id: number;
  name: string;
  slug: string;
}

interface IStats {
  total: IStatBlock;
  cancelled: IStatBlock;
  prepared: IStatBlock;
}

interface IStatBlock {
  count: number;
  money: number;
}

export enum OrderStatus {
  NEW = 'new',
  PREPARING = 'preparing',
  READY_FOR_DELIVERY = 'ready_for_delivery',
  DELIVERING = 'delivering',
  DELIVERED = 'delivered',
  DECLINED = 'declined',
}

export interface IOrder {
  id: string;
  friendlyId: number;
  status: OrderStatus;
  statusDetails: {};
  deliverToTime: string; // Date ISO string
  cartPrice: number;
  totalPrice: number;
  deliveryPrice: number;
  createdAt: string; // Date ISO string
  restaurant: {
    id: number;
    name: string;
    street: string;
    vendor: {
      id: number;
      logo: string;
      slug: string;
    };
  };
  coupon: {
    id: number;
    discount: number;
    type: 'percent' | 'special_amount';
  };
}
</script>

<style lang="scss" scoped>
.btn {
  height: 60px;
}
.container {
  background-color: $white;
  margin-top: 20px;
  padding: 0 20px 20px;
}
.header {
  height: 100px;
  margin-bottom: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include bp-768 {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }
  p {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
}
.statistics {
  flex-wrap: wrap;
  .stats-block {
    font-family: $Montserrat;
    font-size: 14px;
    margin-bottom: 20px;
    .heading {
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 20px;
    }
    .stats {
      height: 110px;
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $dark;
      &-count {
        font-weight: 600;
        font-size: 40px;
        line-height: 100%;
        padding: 20px 0 10px;
      }
      &-money {
        font-weight: 400;
        line-height: 150%;
      }
      &--red {
        color: $red;
      }
      &--blue {
        color: $blue;
      }
    }
  }
}
.stats-result {
  .heading {
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 20px;
  }
  .stats {
    padding: 20px;
    background-color: #f8f8f8;
    &-title {
      font-family: $Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
    &-text {
      font-weight: 600;
      margin-top: 5px;
    }
    .text-green {
      font-size: 40px;
    }
  }
}
.stats-filters {
  display: flex;
  @include bp-768 {
    flex-direction: column;
    justify-content: space-around;
    div,
    .btn {
      margin-top: 20px;
    }
  }
  div,
  .btn {
    margin-left: 20px;
  }
}
</style>
