import Vue from 'vue';
import Router from 'vue-router';

import SignIn from '@/auth/sign-in.vue';
import SignUp from '@/auth/sign-up.vue';

import CouponsList from '@/coupons/coupons-list.vue';

import Finances from '@/finances/finances.vue';
import FinancesOrders from '@/finances/orders.vue';
import VendorFees from '@/finances/vendor-fees.vue';

import Logistics from '@/logistics/logistics.vue';

import AddDish from '@/menu/add-dish.vue';
import Dishes from '@/menu/dishes.vue';
import Menu from '@/menu/menu.vue';
import Modificators from '@/menu/modificators.vue';
import RestaurantsMenu from '@/menu/restaurants-menu.vue';

import Payouts from '@/payouts/payouts.vue';

import AddVendor from '@/vendor/add-vendor.vue';
import Dashboard from '@/vendor/dashboard.vue';
import OrdersActive from '@/vendor/orders/orders-active.vue';
import OrdersHistory from '@/vendor/orders/orders-history.vue';
import OrdersScheduled from '@/vendor/orders/orders-scheduled.vue';
import Orders from '@/vendor/orders/orders.vue';
import Settings from '@/vendor/settings.vue';
import Vendor from '@/vendor/vendor.vue';

import VendorsList from '@/views/vendors-list.vue';
import Vendors from '@/views/vendors.vue';

import store from '@/store/store';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/sign-in',
      component: SignIn,
      beforeEnter(_, __, next) {
        if (store.state.user) next('/vendors');
        else next();
      },
    },
    {
      path: '/sign-up',
      component: SignUp,
    },
    {
      path: '/vendors',
      component: Vendors,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else next();
      },
      children: [
        {
          path: '',
          component: VendorsList,
        },
        {
          path: 'add',
          component: AddVendor,
        },
      ],
    },
    {
      path: '/vendors/:slug',
      component: Vendor,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else next();
      },
      children: [
        {
          path: '',
          redirect: 'menu',
        },
        {
          path: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'menu',
          component: Menu,
          children: [
            {
              path: '',
              redirect: 'restaurants',
            },
            {
              path: 'restaurants',
              component: RestaurantsMenu,
            },
            {
              path: 'dishes',
              component: Dishes,
            },
            {
              path: 'dishes/:categoryId/add',
              component: AddDish,
            },
            {
              path: 'dishes/:categoryId/edit/:dishSlug',
              component: AddDish,
            },
            {
              path: 'modificators',
              component: Modificators,
            },
          ],
        },
        {
          path: 'orders',
          component: Orders,
          children: [
            {
              path: '',
              redirect: 'active',
            },
            {
              path: 'active',
              component: OrdersActive,
              beforeEnter(_, __, next) {
                const { isSuperAdmin, vendorSlug } = store.state;
                if (!isSuperAdmin) next(`/vendors/${vendorSlug}/orders/history`);
                else next();
              },
            },
            {
              path: 'history',
              component: OrdersHistory,
            },
            {
              path: 'scheduled',
              component: OrdersScheduled,
            },
          ],
        },
        {
          path: 'settings',
          component: Settings,
        },
      ],
    },
    {
      path: '/finances',
      component: Finances,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else if (!store.state.isSuperAdmin) next('/vendors');
        else next();
      },
      children: [
        {
          path: '',
          redirect: 'orders',
        },
        {
          path: 'orders',
          name: 'finances-orders',
          component: FinancesOrders,
        },
        {
          path: 'vendor-fees',
          name: 'vendor-fees',
          component: VendorFees,
        },
      ],
    },
    {
      path: '/coupons',
      component: CouponsList,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else if (!store.state.isSuperAdmin) next('/vendors');
        else next();
      },
    },
    {
      path: '/logistics',
      component: Logistics,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else if (!store.state.isSuperAdmin) next('/vendors');
        else next();
      },
    },
    {
      path: '/payouts',
      component: Payouts,
      beforeEnter(_, __, next) {
        if (!store.state.user) next('/sign-in');
        else if (!store.state.isSuperAdmin) next('/vendors');
        else next();
      },
    },
    {
      path: '**',
      redirect: 'vendors',
    },
  ],
  scrollBehavior(_, __, ___) {
    return { x: 0, y: 0 };
  },
});
