<template>
  <div id="app">
    <app-header></app-header>
    <modal-view></modal-view>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { initializeApp } from 'firebase/app';
import { Component, Vue } from 'vue-property-decorator';

import { AuthService } from './auth/auth-service';

@Component({})
export default class App extends Vue {
  created() {
    initializeApp({
      apiKey: 'AIzaSyBP1Y__K_aHQrox2R3Pn6ipC7pS2crTcQc',
      authDomain: 'foodstufff-dcd23.firebaseapp.com',
      databaseURL: 'https://foodstufff-dcd23.firebaseio.com',
      projectId: 'foodstufff-dcd23',
      storageBucket: 'foodstufff-dcd23.appspot.com',
      messagingSenderId: '993816528272',
      appId: '1:993816528272:web:a05c14b202188ae3751f15',
      measurementId: 'G-5B16RCPKLJ',
    });

    AuthService.isSuperAdmin().then((v) => this.$store.commit('isSuperAdmin', v));
  }
}
</script>

<style lang="scss">
@import '_scss/app';
#app {
  min-height: 100vh;
  background-color: #f8f8f8;
  position: relative;
}
</style>
