<template>
  <div class="orders-history">
    <div>
      <div class="container">
        <div class="orders-filters">
          <app-select
            v-model="restaurantSlug"
            :options="restaurants"
            option-value="slug"
            select-title="All restaurants"
            @input="getOrdersHistory"
          />
          <app-datepicker v-model="dateRange" class="daterange" opens="left" @input="getOrdersHistory" />
          <app-input
            v-model="searchOrderId"
            class="search is-marginless"
            placeholder="Search by order id..."
            @input="getOrdersHistory"
          />
        </div>
      </div>

      <div v-if="!loading" class="orders-block">
        <div class="container"></div>
        <div class="container">
          <download-csv
            :data="datatoPrint"
            :name="`orders-${dateRange.startDate.toISOString().split('T')[0]}-${
              dateRange.endDate.toISOString().split('T')[0]
            }.csv`"
            :labels="keysToDisplay"
            class="download-excel"
          >
            <app-icon name="excel"></app-icon>
          </download-csv>
          <order-row
            v-for="order of orders"
            :key="order.id"
            :order="order"
            class="order delivered"
            @onClick="openOrderInfo"
          >
            <div class="order-price">
              <p>Order Id</p>
              <div class="price">{{ order.friendlyId }}</div>
            </div>

            <div class="order-status">
              <p>Order status</p>
              <p
                class="status"
                :class="{
                  green: order.status === 'delivered',
                  red: order.status === 'declined',
                }"
              >
                {{ order.status }}
              </p>
            </div>
          </order-row>
        </div>
      </div>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import downloadCsv from 'vue-json-csv';
import { Component, Vue } from 'vue-property-decorator';

import { getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

import { IRestaurant } from '@/vendor/dashboard.vue';

import ModalHub from '../../_shared/modals/modal-hub';

@Component({
  components: {
    downloadCsv,
  },
})
export default class OrdersHistory extends Vue {
  public dateRange = getCurrentMonthRange();
  public restaurants: IRestaurant[] = [];
  public orders: IOrderHistory[] = [];
  public searchOrderId = '';
  public restaurantSlug = '';
  public loading = false;
  public csv = null;

  private keysToDisplay = {
    orderId: 'Order ID',
    item: 'Item',
    cost: 'Cost',
    orderDate: 'Date of order',
    orderStatus: 'Status',
  };
  get datatoPrint() {
    const result: any = [];
    this.orders.forEach((order: any) => {
      order.cart.dishes.forEach((dish: any) => {
        for (let i = 0; i < dish.amount; i++) {
          result.push({
            orderId: order.friendlyId,
            orderDate: moment(order.createdAt).format('YYYY-MM-DD'),
            orderStatus: order.status,
            item: dish.name,
            cost: `£ ${dish.price}`,
          });
        }
        dish.modificators.forEach((modificator: any) => {
          for (let i = 0; i < modificator.amount * dish.amount; i++) {
            result.push({
              orderId: order.friendlyId,
              orderDate: moment(order.createdAt).format('YYYY-MM-DD'),
              orderStatus: order.status,
              item: modificator.name,
              cost: `£ ${modificator.price}`,
            });
          }
        });
      });
    });
    return result;
  }

  mounted() {
    this.getRestaurants();
    this.getOrdersHistory();
  }

  public getOrdersHistory() {
    this.loading = true;

    const startDate = new Date(this.dateRange.startDate);
    const endDate = new Date(this.dateRange.endDate);

    startDate.setHours(0);
    endDate.setHours(23);
    endDate.setMinutes(59);

    const tzoffset = startDate.getTimezoneOffset() * 60000;

    let filters = '?statuses=delivered,declined';
    filters += `&startFoodDueTime=${new Date(startDate.getTime() - tzoffset).toISOString()}`;
    filters += `&endFoodDueTime=${new Date(endDate.getTime() - tzoffset).toISOString()}`;
    if (this.searchOrderId) filters += `&friendlyId=${this.searchOrderId}`;
    if (this.restaurantSlug) filters += `&restaurantSlug=${this.restaurantSlug}`;

    http
      .get(`/admin/${this.vendorSlug}/orders/${filters}`)
      .then((res: any) => {
        this.orders = res.orders.sort((a: any, b: any) => b.friendlyId - a.friendlyId);
        this.loading = false;
      })
      .catch((err: any) => {
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
        this.loading = false;
      });
  }

  public getRestaurants() {
    http.get(`/admin/vendors/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      this.restaurants = res.restaurants;
    });
  }

  public openOrderInfo(data: { orderId: number; vendorSlug: string }) {
    ModalHub.$emit('open', 'modal-history-info', { data });
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}
interface IOrderHistory {
  id: string;
  friendlyId: string;
  status: string;
  statusDetails: string;
  deliverToTime: string;
  totalPrice: number;
  createdAt: string;
  restaurant: string;
  name: string;
  street: string;
}
</script>

<style lang="scss" scoped>
.orders-history {
  padding-bottom: 20px;
}
.orders-filters {
  padding: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .app-input {
    width: 260px;
  }
  .search {
    .label {
      display: none;
    }
  }
  .daterange {
    margin-left: auto;
    margin-right: 20px;
  }
  @include bp-480 {
    flex-direction: column;
    .daterange {
      margin: 10px 0;
    }
  }
}
.orders-block {
  margin-bottom: 40px;
}
.order {
  font-size: 14px;
  margin: 0 -20px 20px;
  .order-status {
    margin-left: auto;
    margin-right: 60px;
    .status {
      font-weight: bold;
      margin-top: 10px;
      &.red {
        color: $red;
      }
      &.green {
        color: $green;
      }
    }
  }
  .order-price {
    margin-left: 60px;
    .price {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.download-excel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
