<template>
  <div class="onboarding-billing">
    <header>
      <h1 class="fontfamily-recoleta fontsize-30">Set up your subscription</h1>
      <p class="color-coal60 fontsize-20">
        You will be charged every 30 days beginning on your subscription start date.
      </p>
    </header>
    <ValidationObserver ref="form" tag="form" class="billing-container" @submit.prevent="submitForm">
      <div class="contact-details">
        <h2 class="container-title">Contact information</h2>
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column">
            <app-input
              id="first-name"
              v-model="account.first_name"
              class="is-marginless"
              rules="required"
              name="First Name"
            >
              First Name
            </app-input>
          </div>
          <div class="column">
            <app-input
              id="last_name"
              v-model="account.last_name"
              class="is-marginless"
              rules="required"
              name="Last Name"
            >
              Last Name
            </app-input>
          </div>
        </div>
        <app-input id="email" v-model="account.email" rules="email|required" name="Email"> Email address </app-input>
        <div class="columns is-variable is-mobile is-2-mobile">
          <h2 class="container-title payment-title column is-half">Payment information</h2>
          <div class="column is-half icons">
            <app-icon name="visa" class="logo" />
            <app-icon name="master" class="logo" />
            <app-icon name="american" class="logo" />
            <app-icon name="discover" class="logo" />
          </div>
        </div>
        <stripe-card :class="`stripe-class`" />
        <h2 class="container-title">Address</h2>
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column">
            <app-input id="address1" v-model="account.address1" class="is-marginless" rules="required" name="Address">
              Street Address
            </app-input>
          </div>
          <div class="column">
            <app-input id="address2" v-model="account.address2" class="is-marginless" name="Address 2">
              Address 2
            </app-input>
          </div>
        </div>
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column is-half">
            <app-input id="city" v-model="account.city" class="is-marginless" rules="required" name="City">
              City
            </app-input>
          </div>
          <div class="column is-one-third">
            <app-input
              id="postal_code"
              v-model="account.postal_code"
              class="is-marginless"
              rules="required"
              name="Post Code"
            >
              Post code
            </app-input>
          </div>
        </div>
        <div class="text-center notMobile">
          <button class="btn-ham confirm-sub">
            Set up subscription
            <Icon icon="arrow" direction="right" class="m-l-10" />
          </button>
        </div>
      </div>
      <div class="space"></div>
      <template v-if="isFetching">
        <app-modal-loader />
      </template>
      <div class="order-summary">
        <h2 class="container-title">Order Summary</h2>
        <div class="divider ham" />
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column is-three-quarters sub-title">{{ subscriptionName }}</div>
          <div class="column is-one-quarter price-amount">£{{ subscriptionCostInGBP }}</div>
        </div>
        <h2 class="order-title">Totals</h2>
        <div class="divider ham" />
        <div class="columns is-variable is-mobile is-2-mobile">
          <div class="column is-three-quarters">Total</div>
          <div class="column is-one-quarter price-amount">£{{ totalAmount + subscriptionCostInGBP }}</div>
        </div>
      </div>
      <div class="text-center isMobile">
        <button class="btn-ham confirm-sub">
          Set up subscription
          <Icon icon="arrow" direction="right" class="m-l-10" />
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import Icon from '@/_shared/icons/icon.vue';
import stripeInstance from '@/_shared/lib/stripe-custom-elements';
import ModalHub from '@/_shared/modals/modal-hub';
import http from '@/_shared/utils/http';

import StripeCard from '../../payments/stripe-card.vue';

@Component({
  components: {
    StripeCard,
    Icon,
  },
})
export default class OnboardingBilling extends Vue {
  @Prop() vendor: any;
  public $refs!: {
    form: HTMLFormElement;
  };

  private options = [];

  private isFetching: boolean = false;

  private subscriptionName = 'Foodstuff £50 Subscription';
  private subscriptionCostInGBP = 50;

  private account = {
    first_name: '',
    last_name: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    postal_code: '',
    country: 'GB',
  };

  private checkedOptions: any = [];

  private totalAmount: number = 0;

  private async loadStripe() {
    await stripeInstance.create();
  }

  public async submitForm() {
    let isValid = await this.$refs.form.validate();
    if (isValid) {
      await this.subscribe();
    }
  }

  public async subscribe() {
    if (!stripeInstance.stripe) return;

    const { stripe, elements } = stripeInstance;

    if (!stripe || !elements) return;

    this.isFetching = true;

    const cardElement = elements.getElement('card');
    if (!cardElement) return;

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: this.account.email,
        name: `${this.account.first_name} ${this.account.last_name}`,
        address: {
          country: this.account.country,
          city: this.account.city,
          line1: this.account.address1,
          line2: this.account.address2,
          postal_code: this.account.postal_code,
        },
      },
    });

    if (paymentMethodResult.error || !paymentMethodResult) {
      this.isFetching = false;
      return;
    }

    const { paymentMethod } = paymentMethodResult;
    if (!paymentMethod) return;

    let result: any;
    try {
      result = await http.post('accounts/vendors/set-stripe-subscription', {
        vendorSlug: this.vendor.slug ? this.vendor.slug : this.$store.state.vendorSlug,
        billing_details: paymentMethod.billing_details,
        payment_method: paymentMethod.id,
        email: this.account.email,
      });
    } catch (error) {
      if ((error as any).type === 'StripeCardError') {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: (error as any).raw.message,
          },
        });
      } else {
        ModalHub.$emit('open', 'modal-error', {
          data: {
            message: error,
          },
        });
      }
      this.isFetching = false;

      return;
    }

    const { clientSecret, status } = result;

    if (status === 'requires_action') {
      stripe.confirmCardPayment(clientSecret).then((result) => {
        if (result.error) {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-error', {
            data: {
              message: result.error.message,
            },
          });
        } else {
          this.isFetching = false;
          ModalHub.$emit('open', 'modal-subscription-created', { animation: 'fade-enter' });
          this.$router.push(`/vendors/${this.$store.state.vendorSlug}/menu/restaurants}`);
        }
      });
    } else {
      this.isFetching = false;
      ModalHub.$emit('open', 'modal-subscription-created', { animation: 'fade-enter' });
      this.$router.push(`/vendors/${this.$store.state.vendorSlug}/menu/restaurants}`);
    }
  }

  mounted() {
    this.loadStripe();
  }
}
</script>

<style lang="scss" scoped>
.billing-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  @include bp-768 {
    flex-direction: column;
  }
  .input {
    border-radius: 3px;
    height: 2em;
  }
}
.contact-details {
  width: 550px;
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 15px;
  padding: 15px;
  @include bp-768 {
    width: 100%;
  }
}
.container-title {
  font-family: Recoleta;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28pxx;
  color: #212121;
  margin-bottom: 10px;
}
.order-title {
  font-family: Recoleta;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 10px;
}
.space {
  display: block;
  width: 50px;
  height: 100%;
}
.order-summary {
  width: 300px;
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 15px;
  padding: 15px;
  @include bp-768 {
    width: 100%;
    margin-top: 20px;
  }
}
.divider {
  width: 100%;
  margin: 0 auto 15px auto;
}
.field-name {
  display: flex;
  justify-content: space-between;
}
.input {
  width: 95%;
}
.label-text {
  display: block;
  margin-bottom: 8px;
}
.notMobile {
  @include bp-768 {
    display: none;
  }
}
.isMobile {
  display: none;
  @include bp-768 {
    display: block;
    margin-top: 20px;
  }
}
.icons {
  width: 40% !important;
}
.logo {
  height: 45px;
  width: 45px;
  padding: 2px;
  @include bp-768 {
    height: 35px;
    width: 35px;
  }
}
.confirm-sub {
  font-size: 16px;
  font-weight: 400;
}
.payment-title {
  line-height: 50px;
  margin: 0;
  padding-bottom: 0;
  width: 60% !important;
}
.price-amount {
  color: #7a7a7a;
  @include bp-980 {
    font-size: 12px;
  }
  @include bp-768 {
    font-size: 15px;
  }
}
.column {
  @include bp-1120 {
    padding: 5px !important;
  }
}
.checkbox {
  span .square {
    display: inline-block !important;
    border-radius: 7px !important;
    height: 19px !important;
    width: 19px !important;
  }
}
.no-options {
  padding-bottom: 1em;
}
.stripe-card-input {
  margin-bottom: 20px;
}
</style>
