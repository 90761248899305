import { PaymentMethod, Stripe, StripeCardElement, StripeElements, StripeError, loadStripe } from '@stripe/stripe-js';

import Environment from '@/environment';

class StripeCustomElements {
  private card: StripeCardElement | null = null;

  public stripe: Stripe | null = null;

  public elements: StripeElements | null = null;

  public async create(): Promise<true> {
    return new Promise((resolve) => {
      loadStripe(Environment.stripePublicKey).then((res: Stripe | null) => {
        if (!res) return;

        this.stripe = res;
        const style = { base: { textAlign: 'left', fontSize: '14px' } };

        this.elements = this.stripe.elements() as StripeElements;

        this.card = this.elements.create('card', { hidePostalCode: true, style });
        this.card.mount('#stripe-card-element');

        resolve(true);
      });
    });
  }
}

export interface PaymentMethodThen {
  paymentMethod?: PaymentMethod | undefined;
  error?: StripeError | undefined;
}

export default new StripeCustomElements();
