<template>
  <div>
    <date-range-picker
      ref="picker"
      :autoApply="autoApply"
      :opens="opens"
      :dateRange="value"
      :linkedCalendars="false"
      @update="updateValues"
    />
  </div>
</template>

<script lang="ts">
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    DateRangePicker,
  },
})
export default class AppDatePicker extends Vue {
  @Prop({
    default: 'center',
  })
  opens!: string;

  @Prop()
  value!: IDateRange;

  @Prop()
  autoApply!: string;

  public updateValues(dateRange: IDateRange) {
    this.$emit('input', dateRange);
  }
}

interface IDateRange {
  startDate: Date;
  endDate: Date;
}
</script>

<style lang="scss">
.daterangepicker td.in-range {
  background-color: lighten($pink, 30%);
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $pink;
}
.form-control {
  @extend .input;
  min-width: 256px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  border: $controlBorder;
  height: $controlHeight;
  span {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
.daterangepicker {
  margin-top: 31px;
  user-select: none;
}
</style>
