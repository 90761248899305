<template>
  <label class="c-radio">
    <input :value="option" :name="name" type="radio" :checked="isChecked" @change="update" />
    <span>
      <span class="circle">
        <icon-radio></icon-radio>
      </span>
      <slot></slot>
    </span>
  </label>
</template>

<!--checked-->

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import IconRadio from '../../_assets/icons/icon-radio.svg';

@Component({
  components: {
    IconRadio,
  },
})
export default class AppRadio extends Vue {
  // Note we need to paste to option value that we want to get, if user mark this radio as checked!
  @Prop() option!: string;
  @Prop() value!: string;
  @Prop() name?: string;

  public update(e: any) {
    this.$emit('input', e.target.value);
  }

  get isChecked() {
    return this.option === this.value;
  }
}
</script>

<style lang="scss" scoped>
.c-radio {
  margin-bottom: 20px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 300;
  user-select: none;
  span {
    display: inline-flex;
    align-items: center;
    .circle {
      margin-right: 10px;
      height: 24px;
      width: 24px;
      border: 1px solid #7f7e81;
      border-radius: 50%;
      justify-content: center;
      transition: 0.5s all;
      svg {
        opacity: 0;
      }
    }
  }
  input[type='radio'] {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
  input:checked + span {
    .circle {
      background-color: $pink;
      border-color: $pink;
      svg {
        transition: 0.5s all;
        opacity: 1;
      }
    }
  }
}
</style>
