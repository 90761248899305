<script lang="ts">
import { CreateElement, VNode } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import IconActions from '../../_assets/icons/icon-actions.svg';
import IconAmerican from '../../_assets/icons/icon-american.svg';
import IconBack from '../../_assets/icons/icon-back.svg';
import IconCheck from '../../_assets/icons/icon-check.svg';
import IconCheckbox from '../../_assets/icons/icon-checkbox.svg';
import IconDash from '../../_assets/icons/icon-dash.svg';
import IconDelete from '../../_assets/icons/icon-delete.svg';
import IconDiscount2 from '../../_assets/icons/icon-discount-2.svg';
import IconDiscount from '../../_assets/icons/icon-discount.svg';
import IconDiscover from '../../_assets/icons/icon-discover.svg';
import IconDropdown from '../../_assets/icons/icon-dropdown.svg';
import IconEmpty from '../../_assets/icons/icon-empty.svg';
import IconExcel from '../../_assets/icons/icon-excel.svg';
import IconLogout from '../../_assets/icons/icon-logout.svg';
import IconMaster from '../../_assets/icons/icon-master.svg';
import IconClose from '../../_assets/icons/icon-modal-close.svg';
import IconOk from '../../_assets/icons/icon-ok.svg';
import IconPencil from '../../_assets/icons/icon-pencil.svg';
import IconAddSmall from '../../_assets/icons/icon-plus-small.svg';
import IconAdd from '../../_assets/icons/icon-plus.svg';
import IconRemoveRedCircle from '../../_assets/icons/icon-remove-red-circle.svg';
import IconSearch from '../../_assets/icons/icon-search.svg';
import IconVisa from '../../_assets/icons/icon-visa.svg';
import IconX from '../../_assets/icons/icon-x.svg';

@Component({
  components: {
    'icon-logout': IconLogout,
    'icon-add': IconAdd,
    'icon-delete': IconDelete,
    'icon-add-small': IconAddSmall,
    'icon-close': IconClose,
    'icon-empty': IconEmpty,
    'icon-back': IconBack,
    'icon-dropdown': IconDropdown,
    'icon-actions': IconActions,
    'icon-pencil': IconPencil,
    'icon-remove-red-circle': IconRemoveRedCircle,
    'icon-discount': IconDiscount,
    'icon-discount-2': IconDiscount2,
    'icon-search': IconSearch,
    'icon-excel': IconExcel,
    'icon-check': IconCheck,
    'icon-dash': IconDash,
    'icon-visa': IconVisa,
    'icon-master': IconMaster,
    'icon-american': IconAmerican,
    'icon-discover': IconDiscover,
    'icon-ok': IconOk,
    'icon-checkbox': IconCheckbox,
    'icon-x': IconX,
  },
})
export default class AppIcon extends Vue {
  @Prop() name!: string;

  /**
   * List of components to get text name for comparing
   */
  public componentsObj: any = this.$options.components;

  /**
   * Create span and <icon-***> component in span
   * params: elementName, options(class, etc), childrenElements
   * @param create - default callback for `render` function in vue.
   */
  public render(create: CreateElement): VNode {
    const iconName = this.generateIconName();
    return create('span', { class: `icon ${iconName}` }, [create(iconName)]);
  }

  public generateIconName(): any {
    let componentKeys = Object.keys(this.componentsObj);
    const componentName = `icon-${this.name}`;
    return componentKeys.includes(componentName) ? componentName : '';
  }
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  > svg {
    height: 100%;
  }
}
</style>
