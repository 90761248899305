<template>
  <div class="select" :class="capitalize && 'full-width'">
    <select v-model="selectedValue" :class="capitalize && 'capitalize'" @change="selectUpdate()">
      <option value="" selected>{{ selectTitle }}</option>
      <option v-for="(o, index) of options" :key="`option-${index}`" :value="o[optionValue] || o">
        {{ o[optionName] || o }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

/*
 * This component implements a select, so you can pass an array of objects which will be rendered as options
 * Since you want the select to be user-friendly with name and you probably want to get the chosen option identifier or so.
 * For that purpose you can use the "option-value" and "option-name" properties. By default it will use "id" and "name".
 * <app-select :options="restaurants" option-value="id" option-name="name" select-title="All restaurants" v-model="restaurantId" />
 */
@Component({})
export default class AppSelect extends Vue {
  @Prop() options: any;
  @Prop({
    default: 'id',
  })
  optionValue!: string;
  @Prop({
    default: 'name',
  })
  optionName!: string;
  @Prop() capitalize!: Boolean;
  @Prop({
    default: 'Please select',
  })
  selectTitle!: string;

  @Prop() value!: any;

  public selectedValue = '';

  public selectUpdate() {
    this.$emit('input', this.selectedValue);
  }

  mounted() {
    this.setValue();
  }

  @Watch('value')
  public setValue() {
    this.selectedValue = this.value || '';
  }
}
</script>

<style lang="scss" scoped>
.select {
  padding: 0;
  margin: 0;
  border: $controlBorder;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  background-size: 25%;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  max-width: 260px;
  height: $controlHeight;
  line-height: 40px;
  border-radius: $radius;
  outline: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: $grey transparent transparent transparent;
  }
  select {
    padding: 0 40px 0 20px;
    cursor: pointer;
    width: 256px;
    height: 50px;
    outline: none;
    font-family: $Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #030208;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.full-width {
  max-width: 100%;
}
</style>
