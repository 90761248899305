import { render, staticRenderFns } from "./modal-order-new.vue?vue&type=template&id=ea58244a&scoped=true"
import script from "./modal-order-new.vue?vue&type=script&lang=ts"
export * from "./modal-order-new.vue?vue&type=script&lang=ts"
import style0 from "./modal-order-new.vue?vue&type=style&index=0&id=ea58244a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea58244a",
  null
  
)

export default component.exports