<template>
  <label class="c-checkbox">
    <input ref="field" type="checkbox" :name="name" :checked="!!value" @change="update" />
    <span>
      <slot></slot>
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppPill extends Vue {
  @Prop() value: any;
  @Prop() name: any;

  public update(e: any) {
    this.$emit('input', e.target.checked);
  }
}
</script>

<style lang="scss" scoped>
.c-checkbox {
  margin-bottom: 20px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 300;
  &.is-marginless {
    margin: 0;
  }
  input {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  span {
    display: inline-flex;
    white-space: pre;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-radius: 6px;
    background-color: $white;
    color: #030208;
    user-select: none;
    font-family: $Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    transition: 0.3s all;
  }
  input:checked + span {
    background-color: $pink;
    color: $white;
  }
}
</style>
