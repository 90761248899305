<template>
  <div>
    <div v-if="stats">
      <div class="container header">
        <div class="orders-filters">
          <app-select
            v-model="vendorSlug"
            :options="vendors"
            select-title="All vendors"
            :optionValue="'slug'"
            @input="getOrders"
          />
          <app-datepicker v-model="dateRange" opens="left" @input="getRange($event)" />
        </div>
      </div>

      <div class="container columns statistics">
        <div class="column stats-block">
          <p class="heading">Total orders received</p>
          <div class="stats">
            <p class="stats-count">{{ stats.total.count }}</p>
            <p class="stats-money">£{{ stats.total.money.toFixed(2) }}</p>
          </div>
        </div>
        <div class="column stats-block">
          <p class="heading">Total orders prepared</p>
          <div class="stats stats--blue">
            <p class="stats-count">{{ stats.prepared.count }}</p>
            <p class="stats-money">£{{ stats.prepared.money.toFixed(2) }}</p>
          </div>
        </div>
        <div class="column stats-block">
          <p class="heading">Total cancelled orders</p>
          <div class="stats stats--red">
            <p class="stats-count">{{ stats.cancelled.count }}</p>
            <p class="stats-money">-£{{ stats.cancelled.money.toFixed(2) }}</p>
          </div>
        </div>
        <div class="column stats-block">
          <p class="heading">Total vendor fees</p>
          <div class="stats">
            <p class="stats-count-single">£{{ (Math.round(vendorIncome * 100) / 100).toFixed(2) }}</p>
          </div>
        </div>
      </div>
      <h3 class="container details-headline">Details</h3>
      <hr />
      <div class="container details">
        <div v-for="vendor of vendors" :key="vendor.id" class="details-vendors">
          <div class="details-info">
            <img :src="vendor.logo" alt="logo" class="details-img" />
            <p class="details-name">{{ vendor.name }}</p>
          </div>
          <div class="details-stats">
            <h4>Orders received</h4>
            <p class="value">{{ vendor.stats.total.count }}</p>
          </div>
          <div class="details-stats">
            <h4>Orders prepared</h4>
            <p class="value value--blue">{{ vendor.stats.prepared.count }}</p>
          </div>
          <div class="details-stats">
            <h4>Orders cancelled</h4>
            <p class="value value--red">{{ vendor.stats.cancelled.count }}</p>
          </div>
          <div class="details-stats">
            <h4>Vendor fees</h4>
            <p class="value value--green">£{{ vendor.stats.total.money.toFixed(2) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span>Empty here</span>
    </div>
    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import { getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

import { IOrder } from '@/vendor/dashboard.vue';

@Component({})
export default class VendorFees extends Vue {
  public dateRange = getCurrentMonthRange();
  public vendorSlug = '';
  public vendors: IVendor[] = [];
  public orders: IOrder[] = [];
  public vendorIncome = 0;
  public loading = false;
  public isError = false;
  public stats: IStats = {
    total: {
      count: 0,
      money: 0,
    },
    cancelled: {
      count: 0,
      money: 0,
    },
    prepared: {
      count: 0,
      money: 0,
    },
  };
  public range = {
    startDate: '',
    endDate: '',
  };

  async mounted() {
    await this.getVendors();
    this.getOrders();
  }

  public getRange(e: any) {
    this.range = e;
    this.getOrders();
  }

  public getVendors() {
    return http.get(`/admin/vendors?name=`).then((res: { vendors: any }) => {
      this.vendors = res.vendors;
      for (let vendor of this.vendors) {
        vendor.stats = {
          total: {
            count: 0,
            money: 0,
          },
          cancelled: {
            count: 0,
            money: 0,
          },
          prepared: {
            count: 0,
            money: 0,
          },
        };
      }
    });
  }

  public getOrders() {
    let filters = '';
    filters += `?startDate=${new Date(this.dateRange.startDate).toISOString()}`;
    filters += `&endDate=${new Date(this.dateRange.endDate).toISOString()}`;
    filters += this.vendorSlug ? `&vendorSlug=${this.vendorSlug}` : '';
    this.loading = true;
    return http
      .get(`/admin/orders/finances/all${filters}`)
      .then((res: any) => {
        this.orders = res;
        this.calculateByVendor();
        this.loading = false;
        this.vendorIncome = 0;
        this.orders
          .filter((o) => ['scheduled', 'delivered', 'ready_for_delivery', 'ready_for_collect'].includes(o.status))
          .forEach((o) => (this.vendorIncome += o.cartPrice));
      })
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      });
  }

  public calculateByVendor() {
    this.clearStats();

    for (let order of this.orders) {
      this.stats.total.count += 1;
      this.stats.total.money += order.cartPrice;

      const orderVendor: any = this.vendors.find((v) => v.slug === order.restaurant.vendor.slug);
      orderVendor.stats.total.count += 1;
      orderVendor.stats.total.money += order.cartPrice;

      if (order.status === 'declined') {
        this.stats.cancelled.count += 1;
        this.stats.cancelled.money += order.cartPrice;
        orderVendor.stats.cancelled.count += 1;
        orderVendor.stats.cancelled.money += order.cartPrice;
        orderVendor.stats.total.money -= order.cartPrice;
        continue;
      }

      if (!['scheduled', 'new', 'preparing'].includes(order.status)) {
        this.stats.prepared.count += 1;
        this.stats.prepared.money += order.cartPrice;
        orderVendor.stats.prepared.count += 1;
        orderVendor.stats.prepared.money += order.cartPrice;
        continue;
      }
    }
  }

  private clearStats() {
    this.stats.total.count = 0;
    this.stats.total.money = 0;
    this.stats.prepared.count = 0;
    this.stats.prepared.money = 0;
    this.stats.cancelled.count = 0;
    this.stats.cancelled.money = 0;
    for (let vendor of this.vendors) {
      if (vendor.stats) {
        vendor.stats.total.count = 0;
        vendor.stats.total.money = 0;
        vendor.stats.prepared.count = 0;
        vendor.stats.prepared.money = 0;
        vendor.stats.cancelled.count = 0;
        vendor.stats.cancelled.money = 0;
      }
    }
  }

  public partnerIncome(sum: number) {
    if (sum > 0) {
      return (sum - this.percentage(sum, 10)) / 2;
    } else {
      return 0;
    }
  }

  private percentage(value: number, percent: number) {
    return (percent / value) * 100;
  }
}

interface IVendor {
  id: number;
  name: string;
  logo: string;
  isPublished: boolean;
  stats?: IStats;
  slug: string;
}

interface IStats {
  total: IStatBlock;
  cancelled: IStatBlock;
  prepared: IStatBlock;
}

interface IStatBlock {
  count: number;
  money: number;
}
</script>

<style lang="scss" scoped>
.btn {
  height: 60px;
}
.orders-filters {
  padding: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .app-input {
    width: 260px;
  }
  .search {
    .label {
      display: none;
    }
  }
  @include bp-480 {
    flex-direction: column;
    align-items: center;
    & > * {
      margin: 10px 0;
    }
  }
}
.container {
  margin-top: 20px;
  padding: 0 20px 20px;
}
.header {
  height: 100px;
  margin-bottom: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include bp-768 {
    margin-top: 70px;
    margin-bottom: 50px;
  }
  p {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
}
.statistics {
  flex-wrap: wrap;
  .stats-block {
    font-family: $Montserrat;
    font-size: 14px;
    margin-bottom: 20px;
    .heading {
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 20px;
    }
    .stats {
      height: 110px;
      background-color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $dark;
      &-count {
        font-weight: 600;
        font-size: 40px;
        line-height: 100%;
        padding: 20px 0 10px;
        &-single {
          font-weight: 600;
          font-size: 40px;
          line-height: 100%;
          padding-top: 30px;
        }
      }
      &-money {
        font-weight: 400;
        line-height: 150%;
      }
      &--red {
        color: $red;
      }
      &--blue {
        color: $blue;
      }
      &--green {
        color: $green;
      }
    }
  }
}
.details-headline {
  font-family: $Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
}
.details-vendors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: $white;
  margin-bottom: 10px;
  padding: 20px 18px;
  .details-info {
    display: flex;
    align-items: center;
    margin-right: auto;
    @include bp-768 {
      flex-basis: 100%;
      margin-bottom: 10px;
    }
    .details-img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }
    .details-name {
      margin-left: 10px;
      font-family: $Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
    }
  }
  .details-stats {
    text-align: right;
    margin-right: 3%;
    &:last-child {
      @include bp-768 {
        flex-basis: 100%;
        margin-top: 10px;
        text-align: left;
      }
    }
    h4 {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      margin-bottom: 10px;
    }
    .value {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      &--red {
        color: $red;
      }
      &--blue {
        color: $blue;
      }
      &--green {
        color: $green;
        font-size: 16px;
      }
    }
  }
}
</style>
