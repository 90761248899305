import Vue from 'vue';

import AppCheckbox from '@/_shared/controls/app-checkbox.vue';
import AppDatePicker from '@/_shared/controls/app-datepicker.vue';
import AppFile from '@/_shared/controls/app-file.vue';
import AppIcon from '@/_shared/controls/app-icon.vue';
import AppInput from '@/_shared/controls/app-input.vue';
import AppPill from '@/_shared/controls/app-pill.vue';
import AppRadio from '@/_shared/controls/app-radio.vue';
import AppSelect from '@/_shared/controls/app-select.vue';
import AppSwitcher from '@/_shared/controls/app-switcher.vue';
import AppTextarea from '@/_shared/controls/app-textarea.vue';

Vue.component('app-icon', AppIcon);
Vue.component('app-radio', AppRadio);
Vue.component('app-checkbox', AppCheckbox);
Vue.component('app-pill', AppPill);
Vue.component('app-switcher', AppSwitcher);
Vue.component('app-input', AppInput);
Vue.component('app-textarea', AppTextarea);
Vue.component('app-file', AppFile);
Vue.component('app-datepicker', AppDatePicker);
Vue.component('app-select', AppSelect);
