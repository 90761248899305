<template>
  <div>
    <div class="container">
      <div class="orders-filters">
        <app-select
          v-model="restaurantSlug"
          :options="restaurants"
          option-value="slug"
          select-title="All restaurants"
          @input="getOrders"
        />
        <app-input
          v-model="searchOrderId"
          class="search is-marginless"
          placeholder="Search by order id..."
          @input="getOrders"
        />
      </div>
    </div>
    <audio ref="audio" src="/done-for-you.mp3" class="orderAudio" />
    <div v-if="ordersLength" class="orders-all">
      <div class="container new-orders-title">
        <div class="subtitle">New Orders ({{ orders.new.length }})</div>
      </div>
      <hr />
      <div class="container">
        <transition-group class="orders-wrap" name="slide-left">
          <order-card v-for="o of orders.new" :key="o.id" :order="o" />
        </transition-group>
      </div>

      <div class="container">
        <div class="subtitle">Preparing ({{ orders.preparing.length }})</div>
      </div>
      <hr />
      <div class="container">
        <transition-group class="orders-wrap" name="slide-left">
          <order-card v-for="o of orders.preparing" :key="o.id" :order="o" />
        </transition-group>
      </div>

      <div class="container">
        <div class="subtitle">Ready for delivery ({{ orders.ready.length }})</div>
      </div>
      <hr />
      <div class="container">
        <transition-group class="orders-wrap" name="slide-left">
          <order-card v-for="o of orders.ready" :key="o.id" :order="o" />
        </transition-group>
      </div>

      <div class="container">
        <div class="subtitle">Ready to collect ({{ orders.collect.length }})</div>
      </div>
      <hr />
      <div class="container">
        <transition-group class="orders-wrap" name="slide-left">
          <order-card v-for="o of orders.collect" :key="o.id" :order="o" />
        </transition-group>
      </div>

      <div class="container">
        <div class="subtitle">Delivering ({{ orders.delivering.length }})</div>
      </div>
      <hr />
      <div class="container">
        <transition-group class="orders-wrap" name="slide-left">
          <order-card v-for="o of orders.delivering" :key="o.id" :order="o" />
        </transition-group>
      </div>
    </div>

    <div v-else class="empty-holder">
      <app-icon name="empty" />
      <span> There are no active orders or search is empty </span>
    </div>

    <app-modal-loader v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import ModalHub from '@/_shared/modals/modal-hub';
import { getCurrentMonthRange } from '@/_shared/utils/date';
import http from '@/_shared/utils/http';

import { IRestaurant } from '@/vendor/dashboard.vue';
import { IOrderCard } from '@/vendor/orders/order-card.vue';

import OrderCard from './order-card.vue';

@Component({ components: { OrderCard } })
export default class OrdersActive extends Vue {
  public modalHub = ModalHub;
  public restaurants: IRestaurant[] = [];
  public restaurantSlug = '';
  public orders: IOrders = {
    new: [],
    preparing: [],
    ready: [],
    collect: [],
    delivering: [],
  };
  public ordersLength: number = 0;
  public loading = true;
  public socket: any;
  public searchOrderId: string = '';
  public dateRange = getCurrentMonthRange();

  public abstractSocket: number = 0;
  public abstractSocketTimer: any;

  mounted() {
    this.getRestaurants();
    this.getOrders();
    this.abstractSocketTimer = setInterval(this.getOrders, 25000);
  }

  public getRestaurants() {
    http.get(`/admin/vendors/${this.vendorSlug}/restaurants`).then((res: { restaurants: IRestaurant[] }) => {
      this.restaurants = res.restaurants;
    });
  }

  public getOrders() {
    this.loading = true;

    let filters = '?statuses=new,preparing,ready_for_delivery,ready_for_collect,delivering';
    // filters += `&startFoodDueTime=${new Date(new Date().setHours(1, 0, 0, 0)).toISOString()}`;
    // filters += `&endFoodDueTime=${new Date(new Date().setHours(25, 0, 0, 0)).toISOString()}`;
    if (this.searchOrderId) filters += `&friendlyId=${this.searchOrderId}`;
    if (this.restaurantSlug) filters += `&restaurantSlug=${this.restaurantSlug}`;

    http
      .get(`/admin/${this.vendorSlug}/orders/${filters}`)
      .then((res: any) => {
        const ordersResponse = [...res.orders, ...res.scheduledOrders];
        this.ordersLength = ordersResponse.length;

        if (
          (this.abstractSocket > 0 &&
            (this.orders.new && this.orders.new.length) < this.sortOrders(res.orders).new.length) ||
          this.orders.new.length
        ) {
          (this.$refs.audio as Vue & { play: () => any }).play();
          res.isFlash && ModalHub.$emit('open', 'modal-flash', { animation: 'fade-enter' });
        }
        this.abstractSocket = ++this.abstractSocket;

        this.orders = this.sortOrders(ordersResponse);
        this.loading = false;
      })
      .catch((err: any) => {
        this.loading = false;
        ModalHub.$emit('open', 'modal-error', { data: { message: err } });
      });
  }

  public sortOrders(orders: any) {
    let newOrders: any = [];
    let preparing: any = [];
    let ready: any = [];
    let delivering: any = [];
    let collect: any = [];

    orders
      .sort((a: IOrderCard, b: IOrderCard) => (a.orderTime > b.orderTime ? 1 : -1))
      .forEach((order: any) => {
        const orderForView = Object.assign({}, order);
        delete orderForView.deliveryNotes;
        orderForView.status === 'new'
          ? newOrders.push(orderForView)
          : orderForView.status === 'preparing'
            ? preparing.push(orderForView)
            : orderForView.status === 'ready_for_delivery'
              ? ready.push(orderForView)
              : orderForView.status === 'ready_for_collect'
                ? collect.push(orderForView)
                : orderForView.status === 'delivering'
                  ? delivering.push(orderForView)
                  : null;
      });

    return { preparing, ready, delivering, new: newOrders, collect };
  }

  public created() {
    ModalHub.$on('updateOrders', this.getOrders);
  }

  public beforeDestroy() {
    ModalHub.$off('updateOrders');
    clearInterval(this.abstractSocketTimer);
  }

  public onOrderStatusChange(event: any) {
    for (const [key, orders] of Object.entries(this.orders)) {
      for (let order of orders) {
        if (order.id === event.orderId) {
          this.moveOrder(order, event.newStatus);
          break;
        }
      }
    }
  }

  private moveOrder(order: IOrderCard, newStatus: string) {
    console.info(newStatus);
    let oldCategory = (this.orders as any)[order.status];
    let oldOrder = order;
    order.status = newStatus;
    (this.orders as any)[newStatus].push(order);
    oldCategory.splice(oldCategory.indexOf(oldOrder), 1);
  }

  get vendorSlug() {
    return this.$route.params['slug'];
  }
}

interface IOrders {
  new: IOrderCard[];
  preparing: IOrderCard[];
  ready: IOrderCard[];
  collect: IOrderCard[];
  delivering: IOrderCard[];
}
</script>

<style lang="scss" scoped>
.orders-filters {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .app-input {
    width: 260px;
  }
  .search {
    .label {
      display: none;
    }
  }
  .daterange {
    margin-left: auto;
    margin-right: 20px;
  }
  @include bp-768 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .daterange {
      margin: 10px 0;
    }
  }
}
.orderAudio {
  display: none;
}
.orders-wrap {
  margin: -10px -10px 30px;
  display: flex;
  flex-wrap: wrap;
}
.new-orders-title {
  margin-top: 20px;
}
</style>
