<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <router-link to="/">
          <img src="../../_assets/logo.png" alt />
        </router-link>
      </div>

      <ul v-if="user && isSuperAdmin" class="nav">
        <li>
          <router-link to="/vendors" tag="a">Vendors</router-link>
        </li>
        <li>
          <router-link to="/finances" tag="a">Finances</router-link>
        </li>
        <li>
          <router-link to="/coupons" tag="a">Coupons</router-link>
        </li>
        <li>
          <router-link to="/logistics" tag="a">Logistics</router-link>
        </li>
        <li>
          <router-link to="/payouts" tag="a">Payouts</router-link>
        </li>
      </ul>

      <div v-if="user" class="user-block">
        <span class="user-hello"> Hi, {{ user.firstName }} {{ user.lastName }} </span>
        <span class="logout" @click="logout">
          <app-icon name="logout" />
        </span>
      </div>

      <div v-else class="user-block">
        <router-link to="sign-in" tag="a">Log in please</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import AppLogo from '@/_assets/logo.svg';

import { User } from '@/_types/shared.types';

import { AuthService } from '@/auth/auth-service';

@Component({
  components: { AppLogo },
})
export default class AppHeader extends Vue {
  public user: User | null = null;
  public isSuperAdmin: boolean = false;

  public logout() {
    this.$store.commit('reset');
    localStorage.clear();

    window.fcWidget?.user.clear();
    this.$router.push('/sign-in');
  }

  @Watch('$route', { immediate: true, deep: true })
  async onUrlChange() {
    this.user = this.$store.state.user;
    if (this.user) this.isSuperAdmin = await AuthService.isSuperAdmin();
    else this.isSuperAdmin = false;
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $white;
  height: 100px;
  border-bottom: 1px solid #f3f4f8;
  overflow-x: auto;
  .container {
    background-color: $white;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.logo {
  height: 40px;
  img {
    height: 100%;
    width: auto;
  }
}
.nav {
  display: flex;
  align-items: center;
  li {
    font-size: 16px;
    font-weight: 400;
    margin: 0 15px;
    a {
      height: 100px;
      line-height: 100px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: 0.5s all;
    }
    a:hover {
      color: $pink;
    }
    a.router-link-active {
      color: $pink;
      border-bottom: 2px solid $pink;
    }
  }
}
.user-block {
  display: flex;
  align-items: center;
  font-size: 14px;
  .icon {
    margin-left: 20px;
  }
  @include bp-500 {
    .user-hello {
      display: none;
    }
  }
}
.logout {
  cursor: pointer;
  user-select: none;
}
</style>
